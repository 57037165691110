import { ENV, HOST_API, PORT_API } from '../../config';
import axios from '../../utils/axios';

const tenantUrl = localStorage.getItem('tenant_url');

class ProjectsServices {
  getUrl() {
    return HOST_API;
  }

  getTenantUrl() {
    const protocol = ENV === 'local' ? 'http' : 'https';
    const url = `${protocol}://${tenantUrl}${PORT_API}`;
    return url;
  }

  getProjects = (queryParams) => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/t-projects`, {
        params: queryParams,
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  getProject = (id) => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/t-projects/${id}`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  createProject = (data) => {
    try {
      return axios.post(`${this.getTenantUrl()}/api/t-projects`, data);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  editProject(id, data) {
    try {
      return axios.put(`${this.getTenantUrl()}/api/t-projects/${id}`, data);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  }

  toggleProjectAchieve = async (id) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/t-projects/${id}/archive`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  setCover = (id, data) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/t-projects/${id}/cover`, data);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };
}

export { ProjectsServices };
export default new ProjectsServices();
