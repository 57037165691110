import { Avatar } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useLocales from '../hooks/useLocales';
import * as userActions from '../models/user/actions';
import { selectors as userSelectors } from '../models/user/reducers';

const options = JSON.parse(localStorage.getItem('workspaces')) || [];
const tenantIndex = localStorage.getItem('tenant_index') || options.findIndex((workspace) => workspace.is_default);

export default function WorkspaceSwitcher({ isCollapse = false }) {
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(tenantIndex);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    localStorage.setItem('tenant_id', options[index].id);
    localStorage.setItem('tenant_index', index);
    localStorage.setItem('tenant_url', options[index].domain);
    localStorage.setItem('tenant_role', options[index].company_role.role_id);

    const { id } = options[index];
    dispatch(
      userActions.setDefaultWorkspaceRequest({
        id,
        callback: (type) => {
          if (type === 'success') {
            navigate('/');
            window.location.reload(true);
          }
        },
      })
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const workspace = useSelector(userSelectors.workspace);

  return (
    (options && (
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Avatar
          src={workspace?.logo_url || null}
          alt={languageCode === 'en' ? workspace?.name_en : workspace?.name_ar}
          sx={{
            borderRadius: 1,
            width: isCollapse ? 67 : 120,
            height: isCollapse ? 67 : 120,
          }}
        >
          {!workspace?.logo_url && languageCode === 'en' ? workspace?.name_en[0] : workspace?.name_ar[0]}
        </Avatar>
        {!isCollapse && (
          <>
            <List
              component="nav"
              aria-label="Device settings"
              style={{
                marginTop: 12,
                borderStyle: 'dotted',
                borderWidth: '2px',
                borderColor: 'rgba(145, 158, 171, 0.17)',
                borderRadius: 12,
                width: '100%',
                paddingTop: '0px',
                paddingBottom: '0px',
              }}
            >
              <ListItem
                button
                id="lock-button"
                aria-haspopup="listbox"
                aria-controls="lock-menu"
                aria-label="when device is locked"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickListItem}
                style={{
                  borderRadius: 12,
                  backgroundColor: 'rgba(247, 247, 247,0.1)',
                }}
              >
                <ListItemText
                  secondaryTypographyProps={{ fontSize: '14px' }}
                  primaryTypographyProps={{ fontSize: '10px' }}
                  secondary={languageCode === 'en' ? options[selectedIndex].name_en : options[selectedIndex].name_ar}
                  primary={translate('Workspaces')}
                />
              </ListItem>
            </List>
            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'lock-button',
                role: 'listbox',
              }}
            >
              {options?.map((option, index) => (
                <MenuItem
                  key={index}
                  selected={index === selectedIndex}
                  onClick={(event) => handleMenuItemClick(event, index)}
                >
                  <div style={{ marginRight: 10, marginLeft: 20 }}>
                    {languageCode === 'en' ? option?.name_en : option?.name_ar}
                  </div>
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </div>
    )) || <></>
  );
}
