/**
 * @typedef {Object} Notification
 * @property {string | null} read_at - The timestamp when the notification was read, or `null` if it is unread.
 * @property {string} created_at - The timestamp when the notification was created.
 * @property {string} updated_at - The timestamp when the notification was last updated.
 * @property {string} type - The type of the notification, which determines the category or purpose.
 * @property {Object} data - The additional data associated with the notification, containing identifiers and other relevant details.
 * @property {string} data.property_request_id - The identifier for a property request, relevant for notifications of type 'new_property_request'.
 * @property {string} data.deal_id - The identifier for a deal, relevant in notifications of type 'deal_assigned' or 'new_client_deal'.
 */

/**
 * @param {Notification} notification
 * @returns {boolean}
 */
export function isNotificationUnseen(notification) {
  return notification.read_at === null;
}

/**
 * A notification is considered manually controlled if it was updated after it was created.
 * @param {Notification} notification
 * @returns {boolean}
 */
export function isNotificationManuallyControlled(notification) {
  return notification.created_at !== notification.updated_at;
}

/**
 * @param {string} id
 * @param {Notification[]} notifications
 * @returns {boolean}
 */
export function hasPropertyRequestNotification(id, notifications = []) {
  return findPropertyRequestNotification(id, notifications) !== undefined;
}

/**
 * @param {Notification[]} notifications
 * @returns {Notification[]}
 */
export function filterPropertyRequestNotifications(notifications = []) {
  return notifications.filter(
    (notification) => isNotificationUnseen(notification) && notification.type === 'new_property_request'
  );
}

/**
 * @param {string} id
 * @param {Notification[]} notifications
 * @returns {Notification | undefined}
 */
export function findPropertyRequestNotification(id, notifications = []) {
  return filterPropertyRequestNotifications(notifications).find(
    (notification) => notification.data.property_request_id.toString() === id.toString()
  );
}

/**
 * @param {string} id
 * @param {Notification[]} notifications
 * @returns {boolean}
 */
export function hasDealNotification(id, notifications = []) {
  return findDealNotification(id, notifications) !== undefined;
}

/**
 * @param {Notification[]} notifications
 * @returns {Notification[]}
 */
export function filterDealNotifications(notifications = []) {
  return notifications.filter(
    (notification) =>
      isNotificationUnseen(notification) &&
      (['deal_assigned', 'new_client_deal'].includes(notification.type) ||
        (notification.type === 'note' && notification.data.note_model_type === 'deal'))
  );
}

/**
 * @param {string} id
 * @param {Notification[]} notifications
 * @returns {Notification | undefined}
 */
export function findDealNotification(id, notifications = []) {
  return filterDealNotifications(notifications).find(
    (notification) =>
      notification.data.deal_id?.toString() === id.toString() ||
      (notification.data.note_model_type === 'deal' && notification.data.note_model_id?.toString() === id.toString())
  );
}

/**
 * @param {Notification[]} notifications
 * @returns {Notification[]}
 */
export function filterPropertyReservationsNotifications(notifications = []) {
  return notifications.filter(
    (notification) => isNotificationUnseen(notification) && notification.type === 'new_client_reservation'
  );
}

/**
 * @param {string} id
 * @param {Notification[]} notifications
 * @returns {boolean}
 */
export function hasPropertyReservationNotification(id, notifications = []) {
  return findPropertyReservationNotification(id, notifications) !== undefined;
}

/**
 * @param {string} id
 * @param {Notification[]} notifications
 * @returns {Notification | undefined}
 */
export function findPropertyReservationNotification(id, notifications = []) {
  return filterPropertyReservationsNotifications(notifications).find(
    (notification) => notification.data.reservation_id.toString() === id.toString()
  );
}
