import { format } from 'date-fns';

export const downloadExcel = (blob, filename) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;

  link.setAttribute('download', `nuzul-${filename}-${format(new Date(), 'yyyy-MM-dd_hh-mm-a')}.xlsx`);

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export const downloadPdf = (blob, filename) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;

  link.setAttribute('download', `nuzul-${filename}-${format(new Date(), 'yyyy-MM-dd_hh-mm-a')}.pdf`);

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};
