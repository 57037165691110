// @mui
import { Button, Card, CardContent, Container, Grid, TextField, Typography } from '@mui/material';
// hooks
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// components
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import { selectors as subscriptionSelectors } from '../../../models/subscription/reducers';
import * as userActions from '../../../models/user/actions';
import * as workspaceActions from '../../../models/workspace/actions';
import { selectors as workspaceSelectors } from '../../../models/workspace/reducers';

// ----------------------------------------------------------------------

export default function Account() {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');

  const selectedProduct = localStorage.getItem('selected_product');

  const workspaceInformationDataLoading = useSelector(workspaceSelectors.workspaceInformationDataLoading);
  const workspaceInformationData = useSelector(workspaceSelectors.workspaceInformationData);

  useEffect(() => {
    dispatch(workspaceActions.getWorkspaceInformationRequest());
    dispatch(userActions.getUserInformationRequest());
  }, []);

  const callback = (type) => {
    if (type === 'success') {
      toast('Success!', { type: 'success' });
      dispatch(workspaceActions.getWorkspaceInformationRequest());
      dispatch(userActions.getUserWorkspacesRequest());
      dispatch(userActions.getUserInformationRequest());
    } else {
      toast('Error!', { type: 'error' });
    }
  };

  const formik = useFormik({
    onSubmit: (values) => {
      dispatch(workspaceActions.editWorkspaceInformationRequest({ ...values, callback }));
    },
    initialValues: {
      name_en: workspaceInformationData?.name_en || '',
      name_ar: workspaceInformationData?.name_ar || '',
      mot_iwaa_number: workspaceInformationData?.mot_iwaa_number || '',
      rega_fal_number: workspaceInformationData?.rega_fal_number || '',
      mc_cr_number: workspaceInformationData?.mc_cr_number || '',
      hrsd_freelance_number: workspaceInformationData?.hrsd_freelance_number || '',
      zatca_certificate_number: workspaceInformationData?.zatca_certificate_number || '',
    },
    enableReinitialize: true,
  });

  return (
    <Page title={translate('workspaceTitle')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h3" component="h1" paragraph>
          {translate('workspaceTitle')}
        </Typography>

        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={12}>
            <Card>
              <CardContent>
                <form onSubmit={formik.handleSubmit}>
                  <Typography variant="h4" paragraph>
                    {translate('workspace.generalInformation')}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        name="name_en"
                        label={translate('workspace.nameEn')}
                        value={formik.values.name_en}
                        onChange={formik.handleChange}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="name_ar"
                        label={translate('workspace.nameAr')}
                        value={formik.values.name_ar}
                        onChange={formik.handleChange}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button disabled={!formik.dirty} type="submit" variant="contained" color="primary">
                        {translate('workspace.save')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container justifyContent={'center'} mt={5}>
          <Grid item xs={12} md={12}>
            <Card>
              <CardContent>
                <form onSubmit={formik.handleSubmit}>
                  <Typography variant="h4" paragraph>
                    {translate('web.licenses.title')}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      sx={{ display: selectedProduct === 'siyaha' ? 'none' : '' }}
                    >
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        <TextField
                          label={translate('web.licenses.regaFal')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={formik.values.rega_fal_number}
                          onChange={formik.handleChange}
                          name="rega_fal_number"
                        />
                      </Grid>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        {translate('web.licenses.regaFalDescription')}
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      sx={{ display: selectedProduct === 'maktb' ? 'none' : '' }}
                    >
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        <TextField
                          label={translate('web.licenses.motIwaaNumber')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={formik.values.mot_iwaa_number}
                          onChange={formik.handleChange}
                          name="mot_iwaa_number"
                        />
                      </Grid>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        {translate('web.licenses.motIwaaNumberDescription')}
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        <TextField
                          label={translate('web.licenses.mcCrNumber')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={formik.values.mc_cr_number}
                          onChange={formik.handleChange}
                          name="mc_cr_number"
                        />
                      </Grid>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        {translate('web.licenses.mcCrNumberDescription')}
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        <TextField
                          label={translate('web.licenses.zatcaCertificateNumber')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={formik.values.zatca_certificate_number}
                          onChange={formik.handleChange}
                          name="zatca_certificate_number"
                        />
                      </Grid>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        {translate('web.licenses.zatcaCertificateNumberDescription')}
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        <TextField
                          label={translate('web.licenses.hrsdFreelanceNumber')}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={formik.values.hrsd_freelance_number}
                          onChange={formik.handleChange}
                          name="hrsd_freelance_number"
                        />
                      </Grid>
                      <Grid item xs={6} dir="ltr" textAlign={'left'}>
                        {translate('web.licenses.hrsdFreelanceNumberDescription')}
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Button disabled={!formik.dirty} type="submit" variant="contained" color="primary">
                        {translate('workspace.save')}
                      </Button>

                      <Typography variant="caption" sx={{ color: 'grey.500', mt: 2 }} paragraph>
                        {translate('workspace.certDescription')}
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
