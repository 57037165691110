import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  debounce,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// @mui
import SearchIcon from '../../components/Icons/SearchIcon';
// components
import Page from '../../components/Page';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import * as projectsActions from '../../models/projects/actions';
import { selectors as projectsSelectors } from '../../models/projects/reducers';
import AddProjectModal from './AddProjectModal';

// ----------------------------------------------------------------------

const localStorageKey = `projectsQueryParams.${localStorage.getItem('tenant_id')}`;

export default function ProjectsView() {
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //   const [listView, setListView] = useState(true);

  const [showAddProjectModal, setShowAddProjectModal] = useState(false);

  const tenantRole = localStorage.getItem('tenant_role');
  const isDisabled = tenantRole === '6';

  const queryParamsFromStorage = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
  const [queryParams, setQueryParams] = useState({
    per_page: queryParamsFromStorage.per_page || 100,
    page: queryParamsFromStorage.page || 1,
    is_office: queryParamsFromStorage.is_office || true,
    include_archived: queryParamsFromStorage.include_archived || 0,
    search: queryParamsFromStorage.search || null,
  });

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(queryParams));
  }, [queryParams]);

  //   const onViewChange = () => {
  //     setListView((value) => !value);
  //   };

  const onSearch = (e) => {
    const { value } = e.target;
    setQueryParams({ ...queryParams, search: value });
  };

  useEffect(() => {
    dispatch(projectsActions.getProjectsRequest(queryParams));
  }, [queryParams]);

  const totalProjects = useSelector(projectsSelectors.totalProjects);
  const projects = useSelector(projectsSelectors.projects);
  const getProjectsLoading = useSelector(projectsSelectors.getProjectsLoading);

  const handleRow = (params) => {
    navigate(`/maktb/projects/${params.row.id}`);
  };

  const handlePageChange = (newPage) => {
    setQueryParams({ ...queryParams, page: newPage + 1 });
  };

  const columns = [
    {
      field: 'project_number',
      headerName: '#',
      maxWidth: 100,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.api.getRowIndex(params.row.id) + 1,
    },
    {
      field: 'name_ar',
      headerName: translate('project.name'),
      maxWidth: 280,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => (languageCode === 'ar' ? row.name_ar : row.name_en || '-'),
    },
    {
      field: 'properties_count',
      headerName: translate('project.numberOfUnites'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => row.properties_count || '-',
    },
    {
      field: 'total_views',
      headerName: translate('project.views'),
      maxWidth: 280,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => row.total_views,
    },
    {
      field: 'area',
      headerName: translate('project.area'),
      headerClassName: 'datagrid-header',
      flex: true,
      align: 'center',
      headerAlign: 'center',
      valueGetter: ({ row }) => row.area || '-',
    },
    {
      field: 'district',
      headerName: translate('project.district'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => (languageCode === 'ar' ? row.district?.name_ar : row.district?.name_en || '-'),
    },
    {
      field: 'city',
      headerName: translate('project.city'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => (languageCode === 'ar' ? row.city?.name_ar : row.city?.name_en || '-'),
    },
    {
      field: 'published_on_website',
      headerName: translate('project.publishedOnWebsite'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => (row.published_on_website ? translate('yes') : translate('no')),
    },
  ];

  const handleCheckboxChange = () => {
    setQueryParams({ ...queryParams, include_archived: !queryParams.include_archived === true ? 1 : 0 });
  };

  return (
    <Page title={translate('projects')}>
      <AddProjectModal open={showAddProjectModal} onClose={() => setShowAddProjectModal(false)} />
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('projects')}
            </Typography>

            {/* <IconButton
              sx={{ p: 1, border: '1px solid rgba(145, 158, 171, 0.32)', borderRadius: '8px', ml: 2 }}
              onClick={onViewChange}
            >
              {listView ? <Iconify icon={'tabler:layout-cards'} /> : <Iconify icon={'tabler:table'} />}
            </IconButton> */}
          </Box>
          {!isDisabled && (
            <Button
              variant="contained"
              sx={{ color: 'white', pt: 1, pb: 1 }}
              onClick={() => setShowAddProjectModal(true)}
            >
              {translate('project.addProject')}
            </Button>
          )}
        </Box>

        <Card>
          <CardContent sx={{ p: 0 }}>
            <Box>
              <Box sx={{}}>
                <FormControlLabel
                  sx={{ ml: 2, mt: 2 }}
                  label={translate('project.includeArchived')}
                  control={<Checkbox name="include_archived" checked={Boolean(queryParams.include_archived)} />}
                  onChange={handleCheckboxChange}
                />
              </Box>
              <Box sx={{ p: 3 }}>
                <TextField
                  fullWidth
                  placeholder={translate('project.searchLabel')}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                  }}
                  defaultValue={queryParams.search}
                  onChange={debounce(onSearch, 200)}
                />
              </Box>

              <DataGrid
                loading={getProjectsLoading}
                onRowClick={handleRow}
                getRowClassName={`clickable`}
                columns={columns}
                rows={projects || []}
                disableColumnFilter
                disableColumnSelector
                hideFooterSelectedRowCount
                disableSelectionOnClick
                rowsPerPageOptions={[10]}
                autoHeight
                pagination
                page={queryParams.page - 1}
                pageSize={queryParams.per_page}
                rowCount={totalProjects || 0}
                paginationMode="server"
                onPageChange={handlePageChange}
                sx={{
                  '& .datagrid-header': {
                    backgroundColor: '#F4F6F8',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderRadius: 0,
                  },
                  border: 'none',
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
