// @mui
import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
// hooks
import { useNavigate } from 'react-router-dom';

// components
import Page from '../../../components/Page';
import QuickSearchToolbar from '../../../components/QuickSearchToolbar';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import TTransactionsService from '../../../models/t-transactions/services';

// ----------------------------------------------------------------------

export const requestsColumns = (translate, languageCode) => [
  {
    field: 'id',
    headerName: '#',
    maxWidth: 100,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerName: translate('pay.transaction.client'),
    maxWidth: 380,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: ({ row }) => row.order?.tenant_contact?.name || '-',
  },
  {
    field: 'order.id',
    headerName: translate('pay.transaction.orderNumber'),
    maxWidth: 180,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: ({ row }) => row.order?.id || '-',
  },
  {
    field: 'total_amount_with_tax',
    headerName: translate('pay.transaction.amount'),
    maxWidth: 180,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'status',
    headerName: translate('pay.transaction.status'),
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'created_at',
    headerName: translate('pay.transaction.date'),
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
  },
];

export default function TransactionsView() {
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const [tTransactions, settTransactions] = useState([]);

  const navigate = useNavigate();
  const columns = requestsColumns(translate, languageCode);

  useEffect(() => {
    TTransactionsService.getTransactions().then((res) => {
      settTransactions(res.data.data);
    });
  }, []);

  return (
    <Page title={translate('transactions')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('transactions')}
            </Typography>
          </Box>
        </Box>

        <Card className="no-animation">
          <CardContent sx={{ p: 0 }}>
            <Box>
              <DataGrid
                loading={false}
                columns={columns}
                rows={tTransactions || []}
                disableColumnFilter
                disableColumnSelector
                components={{
                  Toolbar: QuickSearchToolbar,
                }}
                hideFooterSelectedRowCount
                disableSelectionOnClick
                rowsPerPageOptions={[10]}
                pageSize={25}
                autoHeight
                sx={{
                  '& .datagrid-header': {
                    backgroundColor: '#F4F6F8',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderRadius: 0,
                  },
                  border: 'none',
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
