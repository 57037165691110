import React from 'react';

const SearchIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="#919EAB" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.31 15.9099L20.71 19.2999C20.8993 19.4877 21.0058 19.7432 21.0058 20.0099C21.0058 20.2765 20.8993 20.5321 20.71 20.7199C20.5222 20.9092 20.2666 21.0157 20 21.0157C19.7334 21.0157 19.4778 20.9092 19.29 20.7199L15.9 17.3199C14.5025 18.4169 12.7767 19.0121 11 19.0099C6.58172 19.0099 3 15.4282 3 11.0099C3 6.59161 6.58172 3.00989 11 3.00989C15.4183 3.00989 19 6.59161 19 11.0099C19.0022 12.7865 18.407 14.5123 17.31 15.9099ZM11 5.00989C7.68629 5.00989 5 7.69618 5 11.0099C5 14.3236 7.68629 17.0099 11 17.0099C14.3137 17.0099 17 14.3236 17 11.0099C17 7.69618 14.3137 5.00989 11 5.00989Z"
      fill="#212B36"
    />
  </svg>
);

export default SearchIcon;
