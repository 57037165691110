import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
  tOrders: null,
  getTOrdersLoading: false,
  tOrderDetails: null,
  getTOrderDetailsLoading: false,
};

const reducer = createReducer(initialState, {
  [actions.getTOrdersRequest]: (state) => {
    state.getTOrdersLoading = true;
  },
  [actions.getTOrdersSuccess]: (state, action) => {
    state.getTOrdersLoading = false;
    state.tOrders = action.payload.data;
  },
  [actions.getTOrdersFailure]: (state) => {
    state.getTOrdersLoading = false;
  },

  [actions.getTOrderDetailsRequest]: (state) => {
    state.getTOrdersLoading = true;
  },
  [actions.getTOrderDetailsSuccess]: (state, action) => {
    state.getTOrdersLoading = false;
    state.tOrderDetails = action.payload.data;
  },
  [actions.getTOrderDetailsFailure]: (state) => {
    state.getTOrdersLoading = false;
  },
});

const getTOrdersLoading = (state) => state.tOrders.getTOrdersLoading;
const tOrders = (state) => state.tOrders.tOrders;
const tOrderDetails = (state) => state.tOrders.tOrderDetails;
const getTOrderDetailsLoading = (state) => state.tOrders.getTOrderDetailsLoading;

const selectors = {
  tOrders,
  getTOrdersLoading,
  tOrderDetails,
  getTOrderDetailsLoading,
};

export { initialState, reducer, selectors };
export default reducer;
