import ClearIcon from '@mui/icons-material/Clear';
// @mui
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  debounce,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// hooks
import { useNavigate } from 'react-router-dom';

import SearchIcon from '../../components/Icons/SearchIcon';
// components
import Page from '../../components/Page';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import * as contactActions from '../../models/contacts/actions';
import { selectors as contactSelectors } from '../../models/contacts/reducers';

// ----------------------------------------------------------------------

export default function ContactsList() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getContactsLoading = useSelector(contactSelectors.getContactsLoading);
  const contacts = useSelector(contactSelectors.contacts);
  const totalContacts = useSelector(contactSelectors.totalContacts);

  const [queryParams, setQueryParams] = useState({
    per_page: 99,
    page: 1,
    include_archived: 0,
    is_siyaha: 0,
    is_office: 1,
    searchValue: null,
    filterTypes: [],
    sort_by: null,
    sort_order: null,
  });

  const getContactType = useCallback((contact) => {
    const types = [
      'property_owner',
      'property_buyer',
      'property_renter',
      'property_agent',
      'property_broker',
      'property_representative',
    ];

    const result = types.filter((type) => contact[`is_${type}`]);

    const translatedTypes = result.map((type) => translate(`contact.${type}`)).join(' - ');

    return translatedTypes;
  }, []);

  const columns = [
    {
      field: 'id',
      headerName: '#',
      maxWidth: 100,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'name',
      headerName: translate('contact.name'),
      maxWidth: 380,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'mobile_number',
      headerName: translate('contact.mobileNumber'),
      maxWidth: 200,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'type',
      headerName: translate('contact.type'),
      headerClassName: 'datagrid-header',
      flex: true,
      align: 'center',
      headerAlign: 'center',
      valueGetter: ({ row }) =>
        !row.is_property_owner &&
        !row.is_property_buyer &&
        !row.is_property_renter &&
        !row.is_property_agent &&
        !row.is_property_broker &&
        !row.is_property_representative
          ? '-'
          : getContactType(row),
    },

    {
      field: 'created_by',
      headerName: translate('contact.createdBy'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => row.created_by || '-',
    },
    {
      field: 't_notes_count',
      headerName: translate('notes.notes'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => row.t_notes_count || '-',
    },
  ];

  const handleRow = (params) => {
    navigate(`/maktb/crm/contacts/${params.row.id}`);
  };

  useEffect(() => {
    const normalizedQueryParams = { ...queryParams };

    if (normalizedQueryParams.filterTypes.length > 0) {
      normalizedQueryParams.filterTypes = normalizedQueryParams.filterTypes.join(',');
    } else {
      delete normalizedQueryParams.filterTypes;
    }

    dispatch(contactActions.getContactsRequest(normalizedQueryParams));
  }, [dispatch, queryParams]);

  const handleCheckboxChange = () => {
    setQueryParams({ ...queryParams, include_archived: !queryParams.include_archived === true ? 1 : 0 });
  };

  const handlePageChange = (newPage) => {
    setQueryParams({ ...queryParams, page: newPage + 1 });
  };

  const handleSortModelChange = (sortModel) => {
    setQueryParams({
      ...queryParams,
      ...(sortModel.length > 0 && {
        sort_by: sortModel[0].field,
        sort_order: sortModel[0].sort,
      }),
      ...(sortModel.length === 0 && {
        sort_by: null,
        sort_order: null,
      }),
    });
  };

  const onSearch = (e) => {
    const { value } = e.target;
    setQueryParams({ ...queryParams, searchValue: value });
  };

  const handleSelect = (event, key) => {
    setQueryParams({
      ...queryParams,
      [key]: event.target.value,
    });
  };

  const handleUnselect = (key, value) => {
    setQueryParams({
      ...queryParams,
      [key]: queryParams[key].filter((item) => item !== value),
    });
  };

  const handleClearAll = (key) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      [key]: [],
    }));
  };

  const types = [
    { value: 'property_owner', text: translate('contact.property_owner') },
    { value: 'property_buyer', text: translate('contact.property_buyer') },
    { value: 'property_renter', text: translate('contact.property_renter') },
    { value: 'property_agent', text: translate('contact.property_agent') },
    { value: 'property_broker', text: translate('contact.property_broker') },
    { value: 'property_representative', text: translate('contact.property_representative') },
  ];
  return (
    <Page title={translate('contacts')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('contacts')}
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{ color: 'white', pt: 1, pb: 1 }}
            onClick={() => navigate('/maktb/crm/contacts/add')}
          >
            {translate('contact.addContact')}
          </Button>
        </Box>

        <Card className="no-animation">
          <CardContent sx={{ p: 0 }}>
            <Box sx={{}}>
              <FormControlLabel
                sx={{ ml: 2, mt: 2 }}
                label={translate('contact.includeArchived')}
                control={<Checkbox name="include_archived" checked={Boolean(queryParams.include_archived)} />}
                onChange={handleCheckboxChange}
              />
            </Box>
            <Grid container spacing={2} px={3}>
              <Grid item xs={12} md={8}>
                <Box sx={{ p: 3, px: 1 }}>
                  <TextField
                    fullWidth
                    placeholder={translate('contact.searchByNumLabel')}
                    InputProps={{
                      startAdornment: <SearchIcon />,
                    }}
                    onChange={debounce(onSearch, 200)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ p: 3, px: 1 }}>
                  <FormControl fullWidth>
                    <InputLabel id="select-contact-type">{translate('contact.type')}</InputLabel>
                    <Select
                      sx={{ height: '60px' }}
                      label={translate('contact.type')}
                      labelId="select-contact-type"
                      multiple
                      value={queryParams.filterTypes}
                      onChange={(e) => handleSelect(e, 'filterTypes')}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.2, height: '32px', overflowY: 'auto' }}>
                          {selected.map((value) => (
                            <Chip
                              sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                              key={value}
                              label={translate(`contact.${value}`)}
                              onDelete={() => handleUnselect('filterTypes', value)}
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }}
                            />
                          ))}
                          {selected.length > 0 && (
                            <Tooltip title={translate('unit.clearAll')}>
                              <IconButton
                                sx={{ ml: 'auto' }}
                                size="small"
                                onClick={() => handleClearAll('filterTypes')}
                                onMouseDown={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                <ClearIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      )}
                    >
                      {types.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          <Checkbox checked={queryParams.filterTypes.indexOf(type.value) > -1} />
                          <ListItemText primary={type.text} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <DataGrid
                onRowClick={handleRow}
                loading={getContactsLoading}
                columns={columns}
                rows={contacts || []}
                getRowClassName={(params) => `clickable ${params.row.is_archived ? 'archived' : ''}`}
                disableColumnFilter
                disableColumnSelector
                // components={{
                //   Toolbar: QuickSearchToolbar,
                // }}
                hideFooterSelectedRowCount
                disableSelectionOnClick
                rowsPerPageOptions={[10]}
                autoHeight
                pagination
                page={queryParams.page - 1}
                pageSize={queryParams.per_page}
                rowCount={totalContacts || 0}
                paginationMode="server"
                onPageChange={handlePageChange}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                sortModel={
                  queryParams.sort_by && queryParams.sort_order
                    ? [{ field: queryParams.sort_by, sort: queryParams.sort_order }]
                    : []
                }
                sx={{
                  '& .datagrid-header': {
                    backgroundColor: '#F4F6F8',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderRadius: 0,
                  },
                  border: 'none',
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
