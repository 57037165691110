import { createAction } from '@reduxjs/toolkit';

export const getMembersRequest = createAction('GET_MEMBERS_REQUEST');
export const getMembersSuccess = createAction('GET_MEMBERS_SUCCESS');
export const getMembersFailure = createAction('GET_MEMBERS_FAILURE');

export const getMemberByIdRequest = createAction('GET_MEMBER_BY_ID_REQUEST');
export const getMemberByIdSuccess = createAction('GET_MEMBER_BY_ID_SUCCESS');
export const getMemberByIdFailure = createAction('GET_MEMBER_BY_ID_FAILURE');

export const getInvitationsRequest = createAction('GET_INVITATIONS_REQUEST');
export const getInvitationsSuccess = createAction('GET_INVITATIONS_SUCCESS');
export const getInvitationsFailure = createAction('GET_INVITATIONS_FAILURE');

export const cancelInvitationRequest = createAction('CANCEL_INVITATION_REQUEST');
export const cancelInvitationSuccess = createAction('CANCEL_INVITATION_SUCCESS');
export const cancelInvitationFailure = createAction('CANCEL_INVITATION_FAILURE');

export const removeMemberRequest = createAction('REMOVE_MEMBER_REQUEST');
export const removeMemberSuccess = createAction('REMOVE_MEMBER_SUCCESS');
export const removeMemberFailure = createAction('REMOVE_MEMBER_FAILURE');

export const changeRoleRequest = createAction('CHANGE_ROLE_REQUEST');
export const changeRoleSuccess = createAction('CHANGE_ROLE_SUCCESS');
export const changeRoleFailure = createAction('CHANGE_ROLE_FAILURE');

export const editShortLinkRequest = createAction('EDIT_SHORT_LINK_REQUEST');
export const editShortLinkSuccess = createAction('EDIT_SHORT_LINK_SUCCESS');
export const editShortLinkFailure = createAction('EDIT_SHORT_LINK_FAILURE');

export const inviteMemberRequest = createAction('INVITE_MEMBER_REQUEST');
export const inviteMemberSuccess = createAction('INVITE_MEMBER_SUCCESS');
export const inviteMemberFailure = createAction('INVITE_MEMBER_FAILURE');

export const searchTeamByNameRequest = createAction('SEARCH_TEAM_BY_NAME_REQUEST');
export const searchTeamByNameSuccess = createAction('SEARCH_TEAM_BY_NAME_SUCCESS');
export const searchTeamByNameFailure = createAction('SEARCH_TEAM_BY_NAME_FAILURE');
