import * as Yup from 'yup';

export const loginSchema = Yup.object({
  phone: Yup.string().length(9).required('Required'),
  code: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

export const completeAccountSchema = Yup.object({
  name: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

export const registerSchema = Yup.object({
  phone: Yup.string().required('Required'),
  code: Yup.string().required('Required'),
});

export const verifyCodeSchema = Yup.object({
  code: Yup.string().required('Required'),
});
