import { Box, Container, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Loader } from '../../../components/Loader';
import useLocales from '../../../hooks/useLocales';
import * as SubscriptionActions from '../../../models/subscription/actions';
import * as userActions from '../../../models/user/actions';
import { HowToPayOuterBoxStyled, PayNowRadioButtonStyled } from '../howToPay/HowToPay.elements';

export default function PaymentDetails() {
  const { translate } = useLocales();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [response, setResponse] = useState({});

  const { id } = useParams();

  useEffect(() => {
    //
    const handleResponse = (type, res) => {
      if (type === 'success') {
        setResponse(res);
        setLoading(false);
      }
    };
    dispatch(SubscriptionActions.getOrderDetailsRequest({ id, callback: handleResponse }));
    dispatch(userActions.getUserInformationRequest());
  }, []);

  const proceed = () => {
    window.location.href = '/maktb/dashboard';
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <HowToPayOuterBoxStyled>
          <Container maxWidth="lg">
            <Grid container spacing={2} style={{ justifyContent: 'center' }}>
              <Grid item>
                <Typography variant="h4">{translate('Payment status')}</Typography>
                <Typography variant="h5">
                  {translate('Your order #')}
                  {response.id} {translate('is')} {translate(response.status)}
                </Typography>

                <Box sx={{ marginTop: '29px' }}>
                  <Box sx={{ marginTop: '1rem' }}>
                    <Box>
                      <PayNowRadioButtonStyled onClick={proceed}>
                        {translate('Go to dashboard')}
                      </PayNowRadioButtonStyled>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </HowToPayOuterBoxStyled>
      )}
    </>
  );
}
