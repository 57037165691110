// @mui
import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { HOST_API, HOST_SPA, MOYASAR_KEY } from '../../../config';
import * as SubscriptionActions from '../../../models/subscription/actions';

// ----------------------------------------------------------------------

export default function PaymentForm() {
  const dispatch = useDispatch();

  const { id } = useParams();

  window.addEventListener('load', (event) => {
    const handleInit = (type, res) => {
      if (type === 'success') {
        if (document.readyState === 'complete') {
          const initData = {
            orderId: res.id,
            totalAmount: res.total_amount_with_tax,
            callbackUrl: `${HOST_API}/api/payment`,
            redirectUrl: `${HOST_SPA}/orders/${res.id}/payment-summery`,
            lang: 'ar',
            description: 'Subscription Payment',
            transactionId: res.transactions[0].id,
          };

          showInit(initData);
        }
      }
    };

    dispatch(SubscriptionActions.getOrderDetailsRequest({ id, callback: handleInit }));

    const showInit = async (initData) => {
      // eslint-disable-next-line no-undef
      Moyasar.init({
        element: '.mysr-form',
        language: initData.lang,
        amount: initData.totalAmount * 100,
        currency: 'SAR',
        description: initData.description,

        // Required
        publishable_api_key: MOYASAR_KEY,
        callback_url: initData.callbackUrl,
        on_completed: `${HOST_API}/api/save`,

        metadata: {
          order_id: `${initData.orderId}`,
          transaction_id: `${initData.transactionId}`,
          redirect_url: initData.redirectUrl,
        },

        methods: ['creditcard', 'applepay'],
        apple_pay: {
          country: 'SA',
          label: 'Nuzul',
          validate_merchant_url: 'https://api.moyasar.com/v1/applepay/initiate',
        },
      });
    };
  });

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://cdn.moyasar.com/mpf/1.14.0/moyasar.css" />
        <script src="https://polyfill.io/v3/polyfill.min.js?features=fetch" />
        <script src="https://cdn.moyasar.com/mpf/1.14.0/moyasar.js" />

        <script async="true">{`
        window.onload = function(e) {
          console.log("window loaded");
        };
        `}</script>
      </Helmet>
      <Container>
        <div className="mysr-form" />
      </Container>
    </>
  );
}
