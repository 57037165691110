// @mui
import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
// hooks
import { useNavigate } from 'react-router-dom';

// components
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import OrderServices from '../../../models/t-orders/services';

// ----------------------------------------------------------------------

export default function SalesView() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const navigate = useNavigate();

  const [tOrders, setTOrders] = useState([]);

  const columns = [
    {
      field: 'id',
      headerName: '#',
      maxWidth: 100,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'amount',
      headerName: translate('sales.amount'),
      headerClassName: 'datagrid-header',
      flex: true,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  useEffect(() => {
    const queryParams = {
      include_archived: false,
      is_office: true,
      statues: ['completed', 'pending'],
    };

    OrderServices.getTOrders(queryParams).then((response) => {
      setTOrders(response.data.data);
    });
  }, []);

  return (
    <Page title={translate('services')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('sales')}
            </Typography>
          </Box>
        </Box>

        <Card>
          <CardContent sx={{ p: 0 }}>
            <Box>
              <DataGrid
                loading={false}
                columns={columns}
                rows={tOrders || []}
                disableColumnFilter
                disableColumnSelector
                hideFooterSelectedRowCount
                disableSelectionOnClick
                rowsPerPageOptions={[10]}
                pageSize={10}
                autoHeight
                sx={{
                  '& .datagrid-header': {
                    backgroundColor: '#F4F6F8',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderRadius: 0,
                  },
                  border: 'none',
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
