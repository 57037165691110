import { Box, Button, Dialog, Divider, Typography } from '@mui/material';
import React from 'react';

import useLocales from '../../hooks/useLocales';

const PropertyRemovalModal = ({ open, onSubmit, onClose }) => {
  const { translate } = useLocales();

  return (
    <Dialog open={open}>
      <Box sx={{ minHeight: 100, minWidth: 400, p: 2 }}>
        <Typography variant="h6" sx={{ p: 2, color: '#212B36' }}>
          {translate('removalConfirmation')}
        </Typography>

        <Divider />

        <Box sx={{ display: 'flex', mt: 4, justifyContent: 'flex-end' }}>
          <Button variant="outlined" sx={{ color: '#212B36', borderColor: '#919eab52' }} onClick={onClose}>
            {translate('cancel')}
          </Button>
          <Button variant="contained" sx={{ color: 'white', ml: 2 }} onClick={onSubmit}>
            {translate('remove')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default PropertyRemovalModal;
