import { createAction } from '@reduxjs/toolkit';

export const getDealsRequest = createAction('GET_DEAL_REQUEST');
export const getDealsSuccess = createAction('GET_DEAL_SUCCESS');
export const getDealsFailure = createAction('GET_DEAL_FAILURE');

export const editDealRequest = createAction('EDIT_DEAL_REQUEST');
export const editDealSuccess = createAction('EDIT_DEAL_SUCCESS');
export const editDealFailure = createAction('EDIT_DEAL_FAILURE');

export const getDealByIdRequest = createAction('GET_DEAL_BY_ID_REQUEST');
export const getDealByIdSuccess = createAction('GET_DEAL_BY_ID_SUCCESS');
export const getDealByIdFailure = createAction('GET_DEAL_BY_ID_FAILURE');

export const createDealRequest = createAction('CREATE_DEAL_REQUEST');
export const createDealSuccess = createAction('CREATE_DEAL_SUCCESS');
export const createDealFailure = createAction('CREATE_DEAL_FAILURE');

export const toggleArchiveDealRequest = createAction('TOGGLE_ARCHIVE_DEAL_REQUEST');
export const toggleArchiveDealSuccess = createAction('TOGGLE_ARCHIVE_DEAL_SUCCESS');
export const toggleArchiveDealFailure = createAction('TOGGLE_ARCHIVE_DEAL_FAILURE');

export const loadMoreDealsRequest = createAction('LOAD_MORE_DEALS_REQUEST');
export const loadMoreDealsSuccess = createAction('LOAD_MORE_DEALS_SUCCESS');
export const loadMoreDealsFailure = createAction('LOAD_MORE_DEALS_FAILURE');

export const exportDealsAsSheetRequest = createAction('EXPORT_DEALS_AS_SHEET_REQUEST');
export const exportDealsAsSheetSuccess = createAction('EXPORT_DEALS_AS_SHEET_SUCCESS');
export const exportDealsAsSheetFailure = createAction('EXPORT_DEALS_AS_SHEET_FAILURE');
