import dayGridPlugin from '@fullcalendar/daygrid'; // Month view
import interactionPlugin from '@fullcalendar/interaction'; // For date click and event drag
import listPlugin from '@fullcalendar/list'; // List view
import FullCalendar from '@fullcalendar/react'; // Core FullCalendar component
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'; // Resource timeline view
import timelinePlugin from '@fullcalendar/timeline'; // Timeline view
// @mui
import { Box, Card, Container, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Iconify from '../../../components/Iconify';
// components
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import * as reservationsActions from '../../../models/t-reservations/actions';
import { selectors as reservationsSelectors } from '../../../models/t-reservations/reducers';

// ----------------------------------------------------------------------

export default function CalendarView() {
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useState({ include_archived: 0 });

  const [events, setEvents] = useState([]);

  const [resources, setResources] = useState([]);

  useEffect(() => {
    dispatch(reservationsActions.getReservationsRequest(queryParams));
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Custom message for older browsers
      const message = 'Are you sure you want to leave? Your changes might not be saved.';

      // Standard approach for modern browsers
      event.preventDefault();
      event.returnValue = message; // Required for some browsers

      // Optional: return the message for older browsers (not recommended in new specs)
      return message;
    };

    // Attach event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const reservations = useSelector(reservationsSelectors.reservations);
  const getReservationsLoading = useSelector(reservationsSelectors.getReservationsLoading);

  // loop through reservations and add get properties and set it as resources property id, type, and unit_number.

  useEffect(() => {
    const properties = reservations?.map((reservation) => ({
      id: reservation.property.unit_number,
      title: reservation.property.type,
    }));

    setResources(properties);
  }, [reservations]);

  useEffect(() => {
    const confirmedBookings = reservations?.filter((reservation) => reservation.status === 'confirmed');

    // Map the filtered reservations to events
    const bookings = confirmedBookings?.map((reservation) => ({
      id: reservation.id,
      title: reservation.tenant_contact.name,
      start: reservation.check_in_date,
      end: reservation.check_out_date,
      resourceId: reservation.property.unit_number,
    }));

    // Set the events
    setEvents(bookings);
  }, [reservations]);

  //   {
  //     "data": [
  //         {
  //             "id": 1,
  //             "property": {
  //                 "id": 3,
  //                 "type": "tower_apartment",
  //                 "purpose": "rent",
  //                 "unit_number": "3",
  //                 "bedrooms": 0,
  //                 "bathrooms": 0,
  //                 "cover_image_url": null,
  //                 "country": null,
  //                 "city": null,
  //                 "district": null
  //             },
  //             "tenant_contact": {
  //                 "name": "abdulmajeed",
  //                 "mobile_number": "9665454",
  //                 "email": "abdulmajeed@nuzul.app"
  //             },
  //             "check_in_date": "2024-08-05",
  //             "check_out_date": "2024-08-07",
  //             "status": "on_hold",
  //             "created_at": "2024-08-05T03:07:32.000000Z",
  //             "updated_at": "2024-08-05T03:07:32.000000Z"
  //         }
  //     ]
  // }

  const handleRow = (id) => {
    navigate(`/siyaha/reservations/${id}`);
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .fc-list-event-time, .fc-license-message {
        display: none !important;
      }
    `;
    document.head.appendChild(style);

    // Cleanup style element on component unmount
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <Page title={translate('siyaha.reservations')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('siyaha.reservation.calendar')}
            </Typography>
            <IconButton
              sx={{ p: 1, border: '1px solid rgba(145, 158, 171, 0.32)', borderRadius: '8px', ml: 2 }}
              onClick={() => {
                navigate('/siyaha/reservations');
              }}
            >
              <Iconify icon={'tabler:table'} />
            </IconButton>
          </Box>
        </Box>

        <Card sx={{ padding: 5 }}>
          <div className="calendar-container">
            <FullCalendar
              plugins={[dayGridPlugin, listPlugin, timelinePlugin, resourceTimelinePlugin, interactionPlugin]}
              direction={languageCode === 'ar' ? 'rtl' : 'ltr'}
              initialView="dayGridMonth" // Default view
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: '',
                // right: 'resourceTimelineDay,dayGridMonth', // Include views
              }}
              views={{
                resourceTimelineDay: {
                  // Configure the resource timeline view for a single day
                  buttonText: 'Resource Timeline Day',
                  resourceAreaWidth: '20%', // Width of the resource area
                  slotDuration: '05:00:00', // Set slot duration to 4 hours
                  slotLabelInterval: '05:00:00', // Interval of slot labels
                  slotLabelFormat: { hourCycle: 'h24', hour: '2-digit', minute: '2-digit', omitZeroMinute: false },
                  duration: { days: 7 }, // Duration of the view is 7 days
                },
              }}
              events={events}
              resources={resources}
              eventClick={(info) => {
                handleRow(info.event.id);
              }}
            />
          </div>
        </Card>
      </Container>
    </Page>
  );
}
