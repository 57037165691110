import { ENV, HOST_API, PORT_API } from '../../config';
import axios from '../../utils/axios';

const tenantUrl = localStorage.getItem('tenant_url');

class TPropertyRequestsOptsServices {
  getUrl() {
    return HOST_API;
  }

  getTenantUrl() {
    const protocol = ENV === 'local' ? 'http' : 'https';
    const url = `${protocol}://${tenantUrl}${PORT_API}`;
    return url;
  }

  getHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  setOptions = async (payload) => {
    try {
      return axios.post(`${this.getTenantUrl()}/api/t-property-requests-opts/set-options`, {
        ...payload,
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };
}

export { TPropertyRequestsOptsServices };
export default new TPropertyRequestsOptsServices();
