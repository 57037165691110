import { Avatar, Box, Button, Link, Tooltip, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { format } from 'date-fns';
import { ar, enUS } from 'date-fns/locale';

import Iconify from '../../components/Iconify';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';

const Note = ({ note, setNoteToEdit, setShowEditNoteModal, setNoteToDelete, setShowDeleteNoteModal }) => {
  const { translate } = useLocales();
  const { themeDirection } = useSettings();
  const isArabic = themeDirection === 'rtl';

  return (
    <Box
      sx={{
        mb: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #D9D9D9',
        backgroundColor: 'hsl(56deg 100% 96% / 50%)',
        p: 1,
        borderRadius: 1,
        ml: '-1rem',
      }}
      key={note.id}
    >
      <Avatar sx={{ mr: 2 }}>{note.user.name[0] + (note.user.name.split(' ')[1] || ' ')[0]}</Avatar>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography variant="subtitle2" sx={{ color: '#919EAB' }}>
            {note.user.name}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: '#919EAB' }}>
            -
          </Typography>
          <Typography variant="subtitle2" sx={{ color: '#919EAB', fontWeight: 'normal' }}>
            <span style={{ color: '#919EAB' }}>{format(new Date(note.created_at), 'h:mm a')}</span>
          </Typography>
        </Box>
        <Typography
          sx={{
            marginTop: 1,
            minHeight: '30px',
            bgcolor: (theme) => alpha(theme.palette.grey[300], 0.3),
            p: 1,
            borderRadius: 1,
            whiteSpace: 'pre-wrap',
          }}
          variant="body1"
        >
          {note.content}
        </Typography>

        {note.uploads?.length > 0 && (
          <Box
            sx={{
              display: 'grid',
              gap: 1,
              mt: 1,
              mb: 2,
              borderRadius: 1,
              p: 1,
              gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
              bgcolor: (theme) => alpha(theme.palette.grey[300], 0.3),
            }}
          >
            {note.uploads.map(({ url }, index) => (
              <Link
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  bgcolor: (theme) => alpha(theme.palette.grey[100], 0.5),
                  '&:hover': {
                    bgcolor: (theme) => theme.palette.action.hover,
                  },
                  p: 1,
                  textDecoration: 'underline',
                }}
                key={url}
              >
                <Iconify icon="mdi:paperclip" style={{ color: '#919EAB', fontSize: '20px', marginRight: '5px' }} />
                <img src={url} alt="attachment" style={{ maxWidth: '70px', maxHeight: '70px' }} />
              </Link>
            ))}
          </Box>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            {note.edited && (
              <Tooltip
                title={format(new Date(note.updated_at), 'd MMMM yyyy h:mm a', {
                  locale: isArabic ? ar : enUS,
                })}
              >
                <Typography variant="caption" sx={{ color: '#919EAB' }}>
                  {translate('notes.noteEdited')}
                </Typography>
              </Tooltip>
            )}
          </Box>
          {note.can_delete && (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                size="small"
                sx={{
                  color: '#808080',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                  textTransform: 'none',
                }}
                onClick={() => {
                  setNoteToEdit(note);
                  setShowEditNoteModal(true);
                }}
              >
                {translate('notes.editNote')}
              </Button>
              <Button
                size="small"
                sx={{
                  color: '#808080',
                  textDecoration: 'underline',
                  fontWeight: 'normal',
                  textTransform: 'none',
                }}
                onClick={() => {
                  setNoteToDelete(note);
                  setShowDeleteNoteModal(true);
                }}
              >
                {translate('notes.deleteNote')}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Note;
