import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useLocales from '../../hooks/useLocales';
import { MobileNumber } from '../auth/components';

const ContactForm = ({ formik, phoneNumberDisabled = false }) => {
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Grid
      item
      xs={12}
      lg={8}
      sx={{
        [theme.breakpoints.down('lg')]: {
          margin: 'auto',
        },
      }}
    >
      <Card>
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <button type="submit" style={{ display: 'none' }}>
              Submit
            </button>

            <Box sx={{ display: 'flex' }}>
              <TextField
                name="name"
                value={formik.values.name}
                label={translate('contact.name')}
                fullWidth
                sx={{ mr: 2 }}
                onChange={formik.handleChange}
              />
              <FormControl error={formik.touched.gender && formik.errors.gender} sx={{ minWidth: 150 }}>
                <InputLabel id="gender-select">{translate('contact.gender')}</InputLabel>
                <Select
                  value={formik.values.gender}
                  name="gender"
                  labelId="gender-select"
                  label={translate('contact.gender')}
                  onChange={formik.handleChange}
                >
                  <MenuItem value="male">{translate('contact.male')}</MenuItem>
                  <MenuItem value="female">{translate('contact.female')}</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <MobileNumber formik={formik} disabled={phoneNumberDisabled} />
            <TextField
              name="email"
              type="email"
              label={translate('contact.email')}
              error={formik.touched.email && formik.errors.email}
              value={formik.values.email}
              onChange={formik.handleChange}
              sx={{ mb: 2 }}
              fullWidth
            />

            <TextField
              name="note"
              type="text"
              multiline
              rows={4}
              label={translate('contact.note')}
              error={formik.touched.note && formik.errors.note}
              value={formik.values.note}
              onChange={formik.handleChange}
              sx={{ mb: 2 }}
              fullWidth
            />
          </form>
        </CardContent>
      </Card>

      <Card sx={{ marginTop: 2 }}>
        <CardContent>
          <Typography variant="h6">{translate('contact.type')}</Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
              label={translate('contact.property_owner')}
              control={<Checkbox name="is_property_owner" checked={formik.values.is_property_owner} />}
              onChange={formik.handleChange}
            />

            <FormControlLabel
              label={translate('contact.property_buyer')}
              control={<Checkbox name="is_property_buyer" checked={formik.values.is_property_buyer} />}
              onChange={formik.handleChange}
            />

            <FormControlLabel
              label={translate('contact.property_renter')}
              control={<Checkbox name="is_property_renter" checked={formik.values.is_property_renter} />}
              onChange={formik.handleChange}
            />

            <FormControlLabel
              label={translate('contact.property_agent')}
              control={<Checkbox name="is_property_agent" checked={formik.values.is_property_agent} />}
              onChange={formik.handleChange}
            />

            <FormControlLabel
              label={translate('contact.property_broker')}
              control={<Checkbox name="is_property_broker" checked={formik.values.is_property_broker} />}
              onChange={formik.handleChange}
            />

            <FormControlLabel
              label={translate('contact.property_representative')}
              control={
                <Checkbox name="is_property_representative" checked={formik.values.is_property_representative} />
              }
              onChange={formik.handleChange}
            />
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default ContactForm;
