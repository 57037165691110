import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';

import useLocales from '../../../hooks/useLocales';

export default function ConfirmationDialog({ onConfirm, onClose, workspace, actionType }) {
  const { translate } = useLocales();
  const [open, setOpen] = React.useState(true);

  const accept = actionType === 'accept';

  const acceptDescription = translate('invitation.dialog.descriptionAccept', { workspace });
  const declineDescription = translate('invitation.dialog.descriptionDecline', { workspace });

  const acceptButton = translate('invitation.accept');
  const declineButton = translate('invitation.decline');

  const description = accept ? acceptDescription : declineDescription;

  const confirmButtonText = accept ? acceptButton : declineButton;

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translate('invitation.dialog.heading')}</DialogTitle>
        <DialogContent sx={{ marginTop: '8%' }}>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {translate('invitation.dialog.cancel')}
          </Button>
          <Button onClick={() => onConfirm()} variant="contained" autoFocus>
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
