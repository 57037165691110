import { keyframes } from '@mui/system';

// Define the keyframes for the scale animation
const scaleIn = keyframes`
  from {
    transform: translateY(30%) scale(0.8) skew(10deg);
    opacity: 0.3;

  }
  to {
    transform: translateY(0) scale(1) skew(0deg);
    opacity: 1;
  }
`;

// ----------------------------------------------------------------------

export default function Card(theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
          boxShadow: theme.customShadows.card,
          borderRadius: Number(theme.shape.borderRadius) * 2,
          zIndex: 0, // Fix Safari overflow: hidden with border radius
          animation: `${scaleIn} 0.2s ease-in-out`,
          '&.no-animation': {
            animation: 'none',
          },
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2', marginTop: theme.spacing(0.5) },
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0),
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
  };
}
