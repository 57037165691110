import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import CustomDatePicker from '../../../components/CustomDatePicker';
import Iconify from '../../../components/Iconify';
import useLocales from '../../../hooks/useLocales';
import { cleanAndFormatNumber, fNumber } from '../../../utils/formatNumber';

const TableRow = ({ children, inactive = false, ...props }) => (
  <Box
    {...props}
    sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(3, minmax(150px, 1fr)) 100px',
      gap: 4,
      alignItems: 'center',
      justifyItems: 'center',
      m: 1,
      borderBottom: '1px dotted',
      borderColor: 'divider',
      ...(props.sx || {}),
      ...(inactive && {
        opacity: 0.9,
      }),
    }}
  >
    {children}
  </Box>
);

export default function PricingRulesForm({ formik }) {
  const { translate } = useLocales();
  const [pricingRulesDirty, setPricingRulesDirty] = useState(false);

  const handleAddRule = () => {
    const maxDate =
      (formik.values.pricing_rules?.length > 0 &&
        formik.values.pricing_rules.reduce((max, rule) => {
          const endDate = dayjs(rule.end_date);
          return endDate.isAfter(max) ? endDate : max;
        }, dayjs())) ||
      null;

    const startDate = maxDate || dayjs();

    const newRule = {
      price: '',
      start_date: startDate.add(1, 'day'),
      end_date: startDate.add(1, 'day'),
    };
    formik.setFieldValue('pricing_rules', [...(formik.values.pricing_rules || []), newRule]);
  };

  const handleRemoveRule = (index) => {
    const updatedRules = [...formik.values.pricing_rules];
    updatedRules.splice(index, 1);
    formik.setFieldValue('pricing_rules', updatedRules);
  };

  const handleFieldChange = (index, field, value) => {
    if (field === 'price') {
      value = cleanAndFormatNumber(value, true);
    }

    const updatedRules = [...formik.values.pricing_rules];

    if (field === 'start_date' && updatedRules[index].end_date && value.isAfter(updatedRules[index].end_date)) {
      formik.setFieldValue(`pricing_rules.${index}.end_date`, value.add(1, 'day'));
    } else if (
      field === 'end_date' &&
      updatedRules[index].start_date &&
      value.isBefore(updatedRules[index].start_date)
    ) {
      formik.setFieldValue(`pricing_rules.${index}.start_date`, value.subtract(1, 'day'));
    }

    formik.setFieldValue(`pricing_rules.${index}.${field}`, value);
  };
  /**
   * @type {Dayjs}
   *
   * @param {Dayjs} day
   */
  const shouldDisableDate = (day, index) => {
    const otherRules = formik.values.pricing_rules.filter((_, i) => i !== index);

    return otherRules.some(
      (rule) =>
        day.isSame(rule.start_date, 'day') ||
        (day.isAfter(rule.start_date, 'day') && day.isBefore(rule.end_date, 'day'))
    );
  };

  useEffect(() => {
    const initialRules = formik.initialValues.pricing_rules.map((rule) => ({
      start: dayjs(rule.start_date).format('YYYY-MM-DD'),
      end: dayjs(rule.end_date).format('YYYY-MM-DD'),
      price: rule.price,
    }));

    const currentRules = formik.values.pricing_rules.map((rule) => ({
      start: dayjs(rule.start_date).format('YYYY-MM-DD'),
      end: dayjs(rule.end_date).format('YYYY-MM-DD'),
      price: rule.price,
    }));

    const days = [
      'saturday_price',
      'sunday_price',
      'monday_price',
      'tuesday_price',
      'wednesday_price',
      'thursday_price',
      'friday_price',
    ];

    const isDirty =
      JSON.stringify(initialRules) !== JSON.stringify(currentRules) ||
      days.map((d) => formik.values[d]).join(',') !== days.map((d) => formik.initialValues[d]).join(',');

    setPricingRulesDirty(isDirty);
  }, [formik.values]);

  const getMaxEndDate = (index) => {
    const rule = formik.values.pricing_rules[index];

    const otherRules = formik.values.pricing_rules.filter((_, i) => i !== index);

    const defaultMaxDate = dayjs().add(1, 'year');

    const maxEndDate = otherRules.reduce((max, otherRule) => {
      const startDate = dayjs(otherRule.start_date);
      if (startDate.isAfter(rule.start_date) && startDate.isBefore(max)) {
        return startDate;
      }
      return max;
    }, defaultMaxDate);

    return maxEndDate;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Tooltip
        placement="top"
        title={<Typography variant="subtitle1">{translate('unit.showPricingRulesTooltip')}</Typography>}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: 'fit-content', mb: 1 }}>
          <InfoIcon />
          <Typography variant="subtitle1" sx={{}}>
            {translate('unit.showPricingRules')}
          </Typography>
        </Box>
      </Tooltip>
      <Box sx={{ width: '100%' }}>
        {formik.values.pricing_rules?.length >= 0 && (
          <TableRow>
            <Typography variant="subtitle2">{translate('unit.price')}</Typography>
            <Typography variant="subtitle2">{translate('from')}</Typography>
            <Typography variant="subtitle2">
              <span>{translate('to')}</span>
              <small
                style={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  display: 'inline-block',
                  fontSize: '0.75rem',
                  padding: '0.5rem',
                }}
              >
                ({translate('dateIncluded')})
              </small>
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
              <IconButton
                color="secondary"
                sx={{ boxShadow: 2, border: 1, borderColor: 'secondary.main' }}
                size="small"
                onClick={handleAddRule}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </TableRow>
        )}
        {formik.values.pricing_rules?.map((rule, index) => (
          <TableRow key={`pricing_rule_${index}`} inactive={rule.price === '' && rule.id === undefined}>
            <TextField
              fullWidth
              type="text"
              value={fNumber(rule.price)}
              onChange={(e) => handleFieldChange(index, 'price', e.target.value)}
              InputProps={{
                inputProps: {
                  min: 0,
                  style: {
                    textAlign: 'center',
                    textDecoration: ['', '0', 0].includes(rule.price) ? 'line-through' : 'none',
                  },
                },
              }}
            />

            <CustomDatePicker
              value={rule.start_date}
              shouldDisableDate={(day) => shouldDisableDate(day, index)}
              minDate={dayjs()}
              onChange={(value) => handleFieldChange(index, 'start_date', value)}
            />
            <CustomDatePicker
              value={rule.end_date}
              shouldDisableDate={(day) => shouldDisableDate(day, index)}
              minDate={rule.start_date ? rule.start_date : dayjs()}
              maxDate={getMaxEndDate(index)}
              onChange={(value) => handleFieldChange(index, 'end_date', value)}
            />
            <Box>
              <IconButton size="small" color="error" onClick={() => handleRemoveRule(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </TableRow>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={formik.handleSubmit}
            sx={{
              boxShadow: 2,
              visibility: pricingRulesDirty ? 'visible' : 'hidden',
              gap: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Iconify icon="mdi:content-save" />
            <span>{translate('unit.saveButton')}</span>
          </Button>
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
