// theme
import palette from '../theme/palette';

// ----------------------------------------------------------------------

export const colorPresets = [
  // DEFAULT
  {
    name: 'default',
    ...palette.light.primary,
  },
  // nuzul
  {
    name: 'gold',
    lighter: '#FFF16E',
    light: '#FFE046',
    main: '#FFC72C',
    dark: '#FFB000',
    darker: '#FF9B00',
    contrastText: palette.light.grey[900],
  },
];

export const defaultPreset = colorPresets[0];
export const nuzulPreset = colorPresets[1];

export default function getColorPresets(presetsKey) {
  return {
    gold: nuzulPreset,
    default: defaultPreset,
  }[presetsKey];
}
