import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { format, parse } from 'date-fns';
import { ar, enUS } from 'date-fns/locale';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Iconify from '../../components/Iconify';
import ImageSelector from '../../components/ImageSelector';
import { Loader } from '../../components/Loader';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import * as notesActions from '../../models/notes/actions';
import { selectors as notesSelectors } from '../../models/notes/reducers';
import { selectors as subscriptionSelectors } from '../../models/subscription/reducers';
import * as teamActions from '../../models/team/actions';
// eslint-disable-next-line import/no-named-as-default
import UtilServices from '../../models/utils/services';
import { getSelectedProduct } from '../../utils/location';
import ActivityItem from './ActivityItem';
import DeleteNoteModal from './DeleteNoteModal';
import EditNoteModal from './EditNoteModal';
import Note from './Note';

const viewPreferenceLocalStorageKey = 'notes-view-preference';

export const TabLabel = ({ icon = null, labelText, badgeContent }) => (
  <Box
    sx={{
      gap: 2,
      py: 1.5,
      pl: 0.5,
      pr: 2,
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      {icon && <Iconify icon={icon} />}
      <Typography variant="body2">{labelText}</Typography>
    </Box>
    {badgeContent > 0 && (
      <Badge
        variant="caption"
        sx={{
          color: '#000',
          display: 'inline',
          fontWeight: 'normal',
          '& .MuiBadge-badge': {
            background: alpha('#919EAB', 0.2),
          },
        }}
        badgeContent={badgeContent}
      />
    )}
  </Box>
);

const Notes = ({ id, type, product = 'office' }) => {
  const { translate } = useLocales();
  const createLoading = useSelector(notesSelectors.createLoading);
  const dispatch = useDispatch();
  const notes = useSelector(notesSelectors.notes);
  const getLoading = useSelector(notesSelectors.getLoading);

  const rawActivities = useSelector(notesSelectors.activities) || [];
  const [activities, setActivities] = useState([]);

  const [showDeleteNoteModal, setShowDeleteNoteModal] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [isAttachingFiles, setIsAttachingFiles] = useState(false);
  /* eslint-disable no-restricted-globals */
  const selectedProduct = getSelectedProduct();

  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const [noteToEdit, setNoteToEdit] = useState(null);

  /**
   * @typedef {'activities-and-notes' | 'activities' | 'notes'} ViewPreference
   * @type {[ViewPreference, React.Dispatch<React.SetStateAction<ViewPreference>>]}
   */
  const [viewPreference, setViewPreference] = useState(
    /** @type {ViewPreference} */ (localStorage.getItem(viewPreferenceLocalStorageKey) || 'activities-and-notes')
  );

  const { themeDirection } = useSettings();
  const isArabic = themeDirection === 'rtl';

  const [initialAttachments, setInitialAttachments] = useState([]);
  const [initialNote, setInitialNote] = useState('');
  const pendingAttachmentsLocalStorageKey = `attachments-${id}-${type}-${product}`;

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);

  const hasActiveSubscription = activeSubscriptions?.find((sub) => sub.is_trial === false);

  useEffect(() => {
    if (rawActivities.length > 0) {
      setActivities(rawActivities);
    }
  }, [rawActivities]);

  useEffect(() => {
    dispatch(teamActions.getMembersRequest());
  }, []);

  useEffect(() => {
    localStorage.setItem(viewPreferenceLocalStorageKey, viewPreference);
  }, [viewPreference]);

  useEffect(() => {
    const savedAttachments = JSON.parse(localStorage.getItem(pendingAttachmentsLocalStorageKey)) || [];
    setInitialAttachments(savedAttachments);
  }, [pendingAttachmentsLocalStorageKey]);

  useEffect(() => {
    localStorage.setItem(pendingAttachmentsLocalStorageKey, JSON.stringify(initialAttachments));
  }, [initialAttachments, pendingAttachmentsLocalStorageKey]);

  const callback = (resultType, error) => {
    if (resultType === 'success') {
      toast(translate('notes.noteSent'), {
        type: 'success',
        autoClose: 700,
      });
      setInitialAttachments([]);
      setInitialNote('');
      formik.resetForm();
      dispatch(notesActions.getNotesRequest({ type, id, product }));
    } else {
      toast('Failed to send note', { type: 'error' });
    }
  };

  const editCallback = (resultType, error) => {
    if (resultType === 'success') {
      toast(translate('notes.noteUpdated'), {
        type: 'success',
        autoClose: 700,
      });
      formik.resetForm();
      dispatch(notesActions.getNotesRequest({ type, id, product }));
    } else {
      toast('Failed to update note', { type: 'error' });
    }
  };

  const deleteCallback = (resultType, error) => {
    if (resultType === 'success') {
      toast(translate('notes.noteDeleted'), {
        type: 'success',
        autoClose: 700,
      });
      formik.resetForm();
      dispatch(notesActions.getNotesRequest({ type, id, product }));
    } else {
      toast('Failed to delete note', { type: 'error' });
    }
  };

  const formik = useFormik({
    initialValues: {
      note: initialNote,
      attachment: initialAttachments,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      note: Yup.string().required(),
    }),
    onSubmit: (values) => {
      dispatch(
        notesActions.createNoteRequest({
          id,
          type,
          product: selectedProduct === 'maktb' ? 'office' : selectedProduct,
          ...values,
          callback,
        })
      );
    },
  });

  useEffect(() => {
    dispatch(notesActions.getNotesRequest({ type, id, product }));
  }, [id]);

  useEffect(() => {
    setInitialNote(formik.values.note);
  }, [formik.values.note]);

  const handleUpdateNote = (content) => {
    if (!(content.note === noteToEdit.content))
      dispatch(notesActions.editNoteRequest({ id: noteToEdit.id, content, editCallback }));

    setShowEditNoteModal(false);
  };

  const handleDeleteNote = () => {
    dispatch(notesActions.deleteNoteRequest({ id: noteToDelete.id, deleteCallback }));
    setShowDeleteNoteModal(false);
  };

  const handleAttachmentUpload = (files) => {
    setIsAttachingFiles(true);

    UtilServices.uploadFiles(files)
      .then((response) => {
        setIsAttachingFiles(false);
        const newAttachments = [...formik.values.attachment, ...response.data.map((x) => x.url)];
        setInitialAttachments(newAttachments);
        formik.setFieldValue('attachment', newAttachments);
      })
      .catch(() => {
        setIsAttachingFiles(false);
        toast('Failed to upload attachment', { type: 'error' });
      });
  };

  const handleDeleteAttachment = (url) => {
    const updatedAttachments = formik.values.attachment.filter((x) => x !== url);
    setInitialAttachments(updatedAttachments);
    formik.setFieldValue('attachment', updatedAttachments);
  };

  const groupByDate = (items) =>
    items.reduce((acc, item) => {
      const date = format(new Date(item.created_at), 'M/d/yyyy');
      if (!acc[date]) acc[date] = [];
      acc[date].push(item);
      return acc;
    }, {});

  const combinedItems = [
    ...(['activities-and-notes', 'notes'].includes(viewPreference) ? notes || [] : []),
    ...(['activities-and-notes', 'activities'].includes(viewPreference) ? activities || [] : []),
  ];
  const groupedItems = groupByDate(combinedItems);

  return (
    <Grid item xs={12}>
      <DeleteNoteModal
        open={showDeleteNoteModal}
        onClose={() => {
          setShowDeleteNoteModal(false);
          setNoteToDelete(null);
        }}
        onSubmit={handleDeleteNote}
      />
      <EditNoteModal
        open={showEditNoteModal}
        onClose={() => setShowEditNoteModal(false)}
        note={noteToEdit}
        onSubmit={handleUpdateNote}
      />
      <Card sx={{ backgroundColor: '#EEEEEE', border: '1px solid #D9D9D9' }}>
        <CardContent sx={{ p: 2 }}>
          <Typography variant="h6">{translate('notes.notes')}</Typography>

          <Typography variant="caption" paragraph sx={{ color: '#919EAB' }}>
            {translate('notes.notesDescription')}
          </Typography>

          <TextField
            name="note"
            type="text"
            multiline
            rows={4}
            sx={{ mb: 2 }}
            fullWidth
            value={formik.values.note}
            onChange={formik.handleChange}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Box>
              <ImageSelector
                name="note_attachment"
                error={formik.touched.attachment && formik.errors.attachment}
                value={[]}
                onFiles={handleAttachmentUpload}
                multiple
              >
                {(isDraggingOver, handleBrowse) => (
                  <Button
                    variant="outlined"
                    sx={{
                      color: '#212B36',
                      borderColor: '#919eab52',
                      fontWeight: 'normal',
                      display: !hasActiveSubscription ? 'none' : 'flex',
                    }}
                    onClick={handleBrowse}
                  >
                    <Iconify icon="mdi:paperclip" style={{ color: '#919EAB', fontSize: '20px', marginRight: '5px' }} />
                    {translate('attachFile')}
                  </Button>
                )}
              </ImageSelector>
            </Box>
            <Button
              variant="contained"
              sx={{ color: 'white', marginLeft: 2 }}
              onClick={formik.submitForm}
              disabled={!formik.isValid || createLoading}
            >
              {!createLoading && translate('notes.logNoteButton')}
              {createLoading && <Loader />}
            </Button>
          </Box>
          {formik.values.attachment.length > 0 && (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(60px,1fr))',
                gap: 1,
                mt: 1,
              }}
            >
              {isAttachingFiles && <Loader />}
              {formik.values.attachment.map((url, i) => (
                <Box
                  sx={{
                    position: 'relative',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 1,
                    p: 1,
                    boxShadow: (theme) => theme.shadows[1],
                  }}
                  key={i}
                >
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      fontColor: 'white',
                      color: '#fff',
                      background: '#161C24',
                      opacity: 0.72,
                      height: 30,
                      width: 30,
                    }}
                    onClick={() => handleDeleteAttachment(url)}
                  >
                    <CloseRoundedIcon sx={{ height: 16, width: 16 }} />
                  </IconButton>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
                    <a
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#919EAB', textDecoration: 'underline' }}
                    >
                      <img src={url} alt="attachment" style={{ maxWidth: '50px', maxHeight: '50px' }} />
                    </a>
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          <Box sx={{ mt: 2 }}>
            {(notes?.length > 0 || activities?.length > 0) && (
              <Box
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid #D9D9D9',
                  p: 0,
                }}
              >
                <Tabs
                  value={viewPreference}
                  onChange={(event, newValue) => setViewPreference(newValue)}
                  textColor="primary"
                  indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="View Preference Tabs"
                  sx={{
                    m: 'auto',
                    '& .MuiTab-root': {
                      fontSize: '0.9rem',
                      marginLeft: '0.5rem !important',
                      marginRight: '0.5rem !important',
                    },
                  }}
                >
                  <Tab
                    value="activities-and-notes"
                    label={
                      <TabLabel
                        icon="mdi:comment-plus-outline"
                        labelText={translate('notes.activitiesAndComments')}
                        badgeContent={(notes?.length || 0) + (activities?.length || 0)}
                      />
                    }
                  />
                  <Tab
                    value="notes"
                    label={
                      <TabLabel
                        icon="mdi:comment-text"
                        labelText={translate('notes.notes')}
                        badgeContent={notes?.length || 0}
                      />
                    }
                  />
                  <Tab
                    value="activities"
                    label={
                      <TabLabel
                        icon="mdi:format-list-bulleted"
                        labelText={translate('notes.activities')}
                        badgeContent={activities?.length || 0}
                      />
                    }
                  />
                </Tabs>
              </Box>
            )}
            {!getLoading && (notes?.length > 0 || activities?.length > 0) && (
              <Box sx={{ backgroundColor: '#EEEEEE', mt: 2, maxHeight: '90vh', overflowY: 'auto' }}>
                <Box
                  sx={{
                    p: 2,
                    position: 'relative',
                    '& .MuiAvatar-root': {
                      zIndex: 2,
                      mr: 1,
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '3.1rem',
                      bottom: '2.1rem',
                      left: '27px',
                      width: '2px',
                      bgcolor: (theme) => alpha(theme.palette.grey[300], 0.5),
                      zIndex: 1,
                    }}
                  />
                  {Object.keys(groupedItems)
                    .sort((a, b) => new Date(b) - new Date(a))
                    .map((date) => (
                      <Box key={date} sx={{ mb: 2, position: 'relative' }}>
                        <Chip
                          variant="subtitle2"
                          sx={{
                            fontWeight: 'normal',
                            color: (theme) => theme.palette.grey[700],
                            mb: 1,
                            textAlign: 'start',
                            borderBottom: '1px solid #D9D9D9',
                            borderRadius: 1,
                            ml: '-1rem',
                          }}
                          label={format(parse(date, 'M/d/yyyy', new Date()), 'd MMMM yyyy', {
                            locale: isArabic ? ar : enUS,
                          })}
                        />
                        {groupedItems[date]
                          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                          .map((item) => (
                            <React.Fragment key={`note-or-activity-${item.id}`}>
                              {!item.content && (
                                <ActivityItem type={type} activity={item} setActivities={setActivities} />
                              )}
                              {item.content && (
                                <Note
                                  note={item}
                                  setNoteToEdit={setNoteToEdit}
                                  setShowEditNoteModal={setShowEditNoteModal}
                                  setNoteToDelete={setNoteToDelete}
                                  setShowDeleteNoteModal={setShowDeleteNoteModal}
                                />
                              )}
                            </React.Fragment>
                          ))}
                      </Box>
                    ))}
                </Box>
              </Box>
            )}
            {getLoading && <Loader />}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Notes;
