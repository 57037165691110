// ProtectedRoute.js
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAuth } from '../contexts/AuthContext';
import * as subscriptionActions from '../models/subscription/actions';
import * as userActions from '../models/user/actions';

export const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();

  if (!isAuthenticated) {
    window.location.href = '/login';
  }

  useEffect(() => {
    const tenantIndex = localStorage.getItem('tenant_id');
    dispatch(userActions.getWorkspaceByIdRequest(tenantIndex));
    dispatch(userActions.getUserInformationRequest());
    dispatch(subscriptionActions.getActiveSubscriptionRequest());
  }, []);

  return children;
};

export default ProtectedRoute;
