import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import contactServices from './services';

export function* getContactsSaga({ payload }) {
  try {
    const response = yield call(contactServices.getContacts, payload);
    yield put(actions.getContactsSuccess(response.data));
  } catch (err) {
    yield put(actions.getContactsFailure(err));
  }
}

export function* getContactByIdSaga({ payload }) {
  try {
    const response = yield call(contactServices.getContact, payload);
    yield put(actions.getContactByIdSuccess(response.data));
  } catch (err) {
    yield put(actions.getContactByIdFailure(err));
  }
}

export function* createContactSaga({ payload }) {
  try {
    const response = yield call(contactServices.createContact, payload);
    yield put(actions.createContactSuccess(response.data));
    payload?.callback?.('success', response.data);
  } catch (err) {
    yield put(actions.createContactFailure(err));
    payload?.callback?.('error', err.response);
  }
}

export function* editContactSaga({ payload }) {
  try {
    const response = yield call(contactServices.editContact, payload);
    yield put(actions.editContactSuccess(response.data));
    payload?.callback?.('success');
  } catch (err) {
    yield put(actions.editContactFailure(err));
    payload?.callback?.('error', err.response);
  }
}

export function* toggleArchiveContactSaga({ payload }) {
  const { id, callback } = payload;
  try {
    const response = yield call(contactServices.toggleContactArchive, id);
    yield put(actions.toggleArchiveContactSuccess(response.data));
    callback?.('success');
  } catch (err) {
    yield put(actions.toggleArchiveContactFailure(err));
    callback?.('error', err);
  }
}

export function* searchSaga({ payload }) {
  const { input, filters } = payload;

  try {
    const response = yield call(() => contactServices.search(input, filters));
    yield put(actions.searchContactByNameSuccess(response.data));
  } catch (err) {
    yield put(actions.searchContactByNameFailure(err));
  }
}

export function* contactsSaga() {
  yield all([
    takeLatest(actions.getContactsRequest, getContactsSaga),
    takeLatest(actions.getContactByIdRequest, getContactByIdSaga),
    takeLatest(actions.createContactRequest, createContactSaga),
    takeLatest(actions.editContactRequest, editContactSaga),
    takeLatest(actions.toggleArchiveContactRequest, toggleArchiveContactSaga),
    takeLatest(actions.searchContactByNameRequest, searchSaga),
  ]);
}
