import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
// eslint-disable-next-line import/no-named-as-default
import NotesServices from './services';

export function* createNoteSaga({ payload }) {
  try {
    const response = yield call(NotesServices.createNote, payload);
    yield put(actions.createNoteSuccess(response.data));
    payload?.callback?.('success', response.data);
  } catch (err) {
    yield put(actions.createNoteFailure(err));
    payload?.callback?.('error', err.response.data);
  }
}

export function* getNoteSaga({ payload }) {
  try {
    const response = yield call(NotesServices.getNotes, payload);
    yield put(actions.getNotesSuccess(response.data));
    payload?.callback?.('success', response.data);
  } catch (err) {
    yield put(actions.getNotesFailure(err));
    payload?.callback?.('error', err.response.data);
  }
}

export function* editNoteSaga({ payload }) {
  try {
    const response = yield call(NotesServices.editNote, payload);
    yield put(actions.editNoteSuccess(response.data));
    payload?.editCallback?.('success', response.data);
  } catch (err) {
    yield put(actions.editNoteFailure(err));
    payload?.editCallback?.('error', err.response.data);
  }
}

export function* deleteNoteSaga({ payload }) {
  try {
    const response = yield call(NotesServices.deleteNote, payload);
    yield put(actions.deleteNoteSuccess(response.data));
    payload?.deleteCallback?.('success', response.data);
  } catch (err) {
    yield put(actions.deleteNoteFailure(err));
    payload?.deleteCallback?.('error', err.response.data);
  }
}

export function* notesSaga() {
  yield all([
    takeLatest(actions.createNoteRequest, createNoteSaga),
    takeLatest(actions.getNotesRequest, getNoteSaga),
    takeLatest(actions.editNoteRequest, editNoteSaga),
    takeLatest(actions.deleteNoteRequest, deleteNoteSaga),
  ]);
}
