import { useEffect, useMemo } from 'react';

import useAsyncAction from './useAsyncAction';

const useDeferredState = (fn, deps, defaultValue) => {
  const [start, isLoading, data, error] = useAsyncAction(fn, deps);

  useEffect(() => {
    start();
  }, [start]);

  return useMemo(() => [data || defaultValue, isLoading, error], [isLoading, data, error]);
};

export default useDeferredState;
