import { createAction } from '@reduxjs/toolkit';

export const getProjectsRequest = createAction('GET_PROJECTS_REQUEST');
export const getProjectsSuccess = createAction('GET_PROJECTS_SUCCESS');
export const getProjectsFailure = createAction('GET_PROJECTS_FAILURE');

export const editProjectRequest = createAction('EDIT_PROJECT_REQUEST');
export const editProjectSuccess = createAction('EDIT_PROJECT_SUCCESS');
export const editProjectFailure = createAction('EDIT_PROJECT_FAILURE');

export const searchProjectsRequest = createAction('SEARCH_PROJECTS_REQUEST');
export const searchProjectsSuccess = createAction('SEARCH_PROJECTS_SUCCESS');
export const searchProjectsFailure = createAction('SEARCH_PROJECTS_FAILURE');

export const filterProjectsRequest = createAction('FILTER_PROJECTS_REQUEST');
export const filterProjectsSuccess = createAction('FILTER_PROJECTS_SUCCESS');
export const filterProjectsFailure = createAction('FILTER_PROJECTS_FAILURE');

export const createProjectRequest = createAction('CREATE_PROJECT_REQUEST');
export const createProjectSuccess = createAction('CREATE_PROJECT_SUCCESS');
export const createProjectFailure = createAction('CREATE_PROJECT_FAILURE');

export const getProjectRequest = createAction('GET_PROJECT_REQUEST');
export const getProjectSuccess = createAction('GET_PROJECT_SUCCESS');
export const getProjectFailure = createAction('GET_PROJECT_FAILURE');

export const toggleArchiveProjectRequest = createAction('TOGGLE_ARCHIVE_PROJECT_REQUEST');
export const toggleArchiveProjectSuccess = createAction('TOGGLE_ARCHIVE_PROJECT_SUCCESS');
export const toggleArchiveProjectFailure = createAction('TOGGLE_ARCHIVE_PROJECT_FAILURE');
