// @mui
import {
  Avatar,
  Badge,
  Box,
  Button,
  debounce,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { camelCase } from 'change-case';
import { ar, enUS } from 'date-fns/locale';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IconButtonAnimate } from '../../../components/animate';
// _mock_
// components
import Iconify from '../../../components/Iconify';
import { Loader } from '../../../components/Loader';
import MenuPopover from '../../../components/MenuPopover';
import Scrollbar from '../../../components/Scrollbar';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import * as notificationsActions from '../../../models/notifications/actions';
import { selectors as notificationsSelectors } from '../../../models/notifications/reducers';
import { isNotificationManuallyControlled, isNotificationUnseen } from '../../../models/notifications/utils';
// utils
import { fToNow } from '../../../utils/formatTime';
import { ICONS } from '../navbar/NavConfig';

// ----------------------------------------------------------------------

export const EmptyNotifications = () => {
  const { translate } = useLocales();

  return (
    <ListSubheader disableSticky sx={{ py: 2, px: 2.5 }}>
      {translate('notifications.empty')}
    </ListSubheader>
  );
};

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const notifications = useSelector(notificationsSelectors.notifications);
  const getLoading = useSelector(notificationsSelectors.getLoading);

  const { translate } = useLocales();

  const totalUnread = (notifications || []).filter((notification) => isNotificationUnseen(notification)).length;

  const [open, setOpen] = useState(null);
  const [windowLostFocus, setWindowLostFocus] = useState(false);
  const [focusLostTime, setFocusLostTime] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const notificationsInterval = 1000 * 3;

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleViewAll = () => {
    navigate('/notifications');
    handleClose();
  };

  const handleMarkAllAsRead = () => {
    dispatch(notificationsActions.markAllAsReadRequest());
    handleClose();
  };

  const getNotifications = () => {
    dispatch(notificationsActions.getNotificationsRequest());
  };

  useEffect(() => {
    let timeoutId;

    if (getLoading) {
      setIsLoading(true);
    } else {
      timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, 600);
    }

    return () => clearTimeout(timeoutId);
  }, [getLoading]);

  useEffect(() => {
    const handleBlur = () => {
      setWindowLostFocus(true);
      setFocusLostTime(Date.now());
    };

    const handleFocus = () => {
      if (windowLostFocus && Date.now() - focusLostTime > notificationsInterval) {
        getNotifications();
      }
      setWindowLostFocus(false);
    };

    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
    };
  }, [windowLostFocus, focusLostTime]);

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        document.visibilityState === 'visible' &&
        !windowLostFocus &&
        !getLoading &&
        location.pathname !== '/notifications'
      ) {
        getNotifications();
      }
    }, notificationsInterval);

    return () => clearInterval(interval);
  }, [windowLostFocus, getLoading]);

  useEffect(() => {
    handleClose();
  }, [location]);

  const maxHeight = 460;

  return (
    <>
      <IconButtonAnimate color={open ? 'secondary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Tooltip title={translate('Notifications')}>
          <Badge badgeContent={totalUnread} color="secondary" sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                inset: 0,
                justifyContent: 'center',
                alignItems: 'center',
                opacity: 0.15,
              }}
            >
              {isLoading && <Loader />}
            </Box>
            <Iconify icon="eva:bell-fill" width={20} height={20} />
          </Badge>
        </Tooltip>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          width: (notifications || []).length === 0 ? 320 : 460,
          p: 0,
          maxHeight,
          mt: 1.5,
          ml: 0.75,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{translate('Notifications')}</Typography>
            <Typography
              variant="body2"
              sx={{
                color: 'text.secondary',
                opacity: totalUnread > 0 ? 1 : 0.2,
              }}
            >
              {translate('notifications.unread', { count: totalUnread })}
            </Typography>
          </Box>

          {totalUnread > 0 && (
            <Tooltip title={translate('notifications.markAllAsRead')}>
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: maxHeight - 140 }}>
          <List disablePadding>
            {(notifications || []).map((notification) => (
              <NotificationItem mode="simple" key={notification.id} notification={notification} onClose={handleClose} />
            ))}
            {(notifications || []).length === 0 && <EmptyNotifications />}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple onClick={handleViewAll}>
            {translate('View All')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
  mode: PropTypes.oneOf(['full', 'simple']),
  onClose: PropTypes.func,
};

export function NotificationItem({ notification, onClose, mode }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { translate } = useLocales();
  const { themeDirection } = useSettings();

  const { avatar, title } = RenderContent(notification);

  const isArabic = themeDirection === 'rtl';
  const canDelete = mode === 'full';

  const markAsRead = () => {
    dispatch(notificationsActions.markAsReadRequest({ id: notification.id }));
  };

  const markAsUnread = () => {
    dispatch(notificationsActions.markAsUnreadRequest({ id: notification.id }));
  };

  const toggleReadState = (event) => {
    event.stopPropagation();

    if (isNotificationUnseen(notification)) {
      markAsRead();
    } else {
      markAsUnread(event);
    }
  };

  const onHover = debounce(() => {
    if (isNotificationUnseen(notification) && !isNotificationManuallyControlled(notification)) {
      markAsRead();
    }
  }, 100);

  const handleClick = () => {
    markAsRead();
    onClose();

    if (notification.type === 'note' && notification.data.note_model_type === 'deal') {
      navigate(`/maktb/crm/deals/${notification.data.note_model_id}`);
    }

    if (['deal_assigned', 'new_client_deal'].includes(notification.type)) {
      navigate(`/maktb/crm/deals/${notification.data.deal_id}`);
    }

    if (notification.type === 'new_client_reservation') {
      navigate(`/siyaha/reservations/${notification.data.reservation_id}`);
    }

    if (notification.type === 'tenant_invitation') {
      navigate(`/user/invitations`);
    }
    if (notification.type === 'invitation_accepted') {
      navigate(`/team`);
    }
    if (notification.type === 'new_property_request') {
      navigate(`/maktb/crm/requests/${notification.data.property_request_id}`);
    }
  };

  const onDelete = () => {
    toast(translate('notifications.deleteSuccess'), {
      type: 'success',
      autoClose: 50,
    });
    onClose();
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    dispatch(notificationsActions.deleteNotificationRequest({ id: notification.id, callback: onDelete }));
  };

  return (
    <ListItemButton
      sx={{
        py: 0,
        pr: 2.5,
        pl: 0.5,
        mt: '1px',
        ...(isNotificationUnseen(notification) && {
          bgcolor: alpha(theme.palette.primary.main, 0.05),
        }),
        '&:hover': {
          ...(isNotificationUnseen(notification) && {
            bgcolor: alpha(theme.palette.primary.main, 0.15),
          }),
        },
      }}
      onClick={handleClick}
      onMouseEnter={onHover}
      onFocus={onHover}
      onTouchStart={onHover}
    >
      <ListItemAvatar
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: 70,
          height: 70,
          marginRight: 0.5,
        }}
      >
        <Tooltip
          title={
            isNotificationUnseen(notification)
              ? translate('notifications.markAsRead')
              : translate('notifications.markAsUnread')
          }
        >
          <Box sx={{ padding: 0, position: 'relative' }}>
            <Avatar sx={{ bgcolor: 'background.neutral', borderRadius: 1, opacity: 0.1, width: 70, height: 70 }}>
              {avatar}
            </Avatar>
            <Box
              sx={{
                padding: 1,
                position: 'absolute',
                inset: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 1,
              }}
              onClick={toggleReadState}
            >
              <IconButton
                sx={{
                  boxShadow: 2,
                  bgcolor: alpha(theme.palette.background.paper, 0.6),
                  opacity: 0.8,
                  '&:hover': {
                    bgcolor: alpha(theme.palette.background.paper, 0.8),
                  },
                }}
              >
                {isNotificationUnseen(notification) && (
                  <Iconify icon="eva:radio-button-on-fill" width={15} height={15} />
                )}
                {!isNotificationUnseen(notification) && (
                  <Iconify icon="eva:radio-button-off-outline" width={15} height={15} />
                )}
              </IconButton>
            </Box>
          </Box>
        </Tooltip>
      </ListItemAvatar>
      <ListItemText
        sx={{
          py: 1.5,
        }}
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.created_at, { includeSeconds: true, locale: isArabic ? ar : enUS })}
          </Typography>
        }
      />
      {canDelete && (
        <Tooltip title={translate('notifications.delete')}>
          <IconButton
            sx={{
              color: 'error.main',
              ml: 3,
              '&:hover': {
                bgcolor: alpha(theme.palette.error.main, 0.08),
              },
            }}
            onClick={handleDelete}
          >
            <Iconify icon="eva:trash-2-outline" width={20} height={20} />
          </IconButton>
        </Tooltip>
      )}
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

export function RenderContent(notification) {
  const { translate } = useLocales();
  const { themeDirection } = useSettings();
  const isArabic = themeDirection === 'rtl';

  const titleMessage = translate(`notifications.${camelCase(notification.type)}Title`);

  const descriptionMessage = translate(`notifications.${camelCase(notification.type)}`, {
    ...(notification.type === 'new_client_deal' && {
      clientName: notification.data.client_name,
      propertyType: translate(`deal.properties.${notification.data.property_type}`),
    }),
    ...(notification.type === 'new_client_reservation' && {
      clientName: notification.data.client_name,
      propertyType: translate(`deal.properties.${notification.data.property_type}`),
    }),
    ...(notification.type === 'note' && {
      content: `${notification.data.note_content.substring(0, 60)}${
        notification.data.note_content.length > 60 ? '...' : ''
      }`,
      userName: notification.data.note_user_name,
    }),
    ...(notification.type === 'deal_assigned' && {
      clientName: notification.data.client_name,
      propertyType: notification.data.property_type
        ? translate(`deal.properties.${notification.data.property_type}`)
        : translate('unspecified'),
      assignedByName: notification.data.assigned_by_name,
    }),
    ...(notification.type === 'new_property_request' && {
      clientName: notification.data.client_name,
      propertyType: translate(`deal.properties.${notification.data.property_type}`),
    }),
    ...(notification.type === 'tenant_invitation' && {
      workspaceName: isArabic ? notification.data.workspace_name_ar : notification.data.workspace_name_en,
    }),
    ...(notification.type === 'invitation_accepted' && {
      workspaceName: isArabic ? notification.data.workspace_name_ar : notification.data.workspace_name_en,
      userName: notification.data.user_name,
    }),
  });

  const HighlightedText = ({ children }) => (
    <Typography component="span" variant="caption" color="black">
      {children}
    </Typography>
  );

  const processDescriptionMessage = (message) => {
    const parts = message.split(/(\[.*?\])/);
    return parts.map((part, index) => {
      if (part.startsWith('[') && part.endsWith(']')) {
        return <HighlightedText key={index}>{part.substring(1, part.length - 1)}</HighlightedText>;
      }
      return part;
    });
  };

  const processedDescriptionMessage = processDescriptionMessage(descriptionMessage);

  const title = (
    <Typography variant="subtitle2">
      <span
        style={{
          filter: 'sepia(0.5) saturate(.6)',
        }}
      >
        {titleMessage}
      </span>
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {processedDescriptionMessage}
      </Typography>
    </Typography>
  );

  if (['deal_assigned', 'new_client_deal'].includes(notification.type)) {
    return {
      avatar: <Box style={{ width: 70, height: 70, padding: 10 }}>{ICONS.deals}</Box>,
      title,
    };
  }

  if (notification.type === 'new_property_request') {
    return {
      avatar: <Box style={{ width: 70, height: 70, padding: 10 }}>{ICONS.requests}</Box>,
      title,
    };
  }

  if (notification.type === 'new_client_reservation') {
    return {
      avatar: <Box style={{ width: 70, height: 70, padding: 10 }}>{ICONS.realEstate}</Box>,
      title,
    };
  }

  if (notification.type === 'tenant_invitation') {
    return {
      avatar: <Box style={{ width: 70, height: 70, padding: 10 }}>{ICONS.team}</Box>,
      title,
    };
  }

  if (notification.type === 'invitation_accepted') {
    return {
      avatar: (
        <Box style={{ width: 80, height: 80, padding: 10 }}>
          <Iconify icon="eva:person-done-fill" />
        </Box>
      ),
      title,
    };
  }

  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
