import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import * as React from 'react';

import useLocales from '../../hooks/useLocales';

function createData(feature, basic, pro, enterprise) {
  return { feature, basic, pro, enterprise };
}

export default function PlansTable({ solution }) {
  const { translate } = useLocales();
  const color = solution === 'siyaha' ? 'siyaha.main' : 'primary.main';
  const variant = 'subtitle3';
  const platformRows =
    solution === 'siyaha'
      ? [
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.platform.users')}</Typography>,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.platform.roles')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.platform.properties')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.platform.bookings')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.platform.requests')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.platform.typesOfProperties')}</Typography>,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.platform.language')}</Typography>,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.platform.payments')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.platform.whatsapp')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.platform.exportData')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.platform.propertyRequests')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
        ]
      : [
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.platform.users')}</Typography>,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.platform.roles')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.platform.properties')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.platform.deals')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.platform.requests')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.platform.typesOfProperties')}</Typography>,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.platform.language')}</Typography>,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.platform.payments')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.platform.whatsapp')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.platform.exportData')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.platform.propertyRequests')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
        ];
  const websiteRows =
    solution === 'siyaha'
      ? [
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.website.customDomain')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.website.nuzulDomain')}</Typography>,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.website.specialWebsiteUI')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
        ]
      : [
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.website.customDomain')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.website.nuzulDomain')}</Typography>,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.website.specialWebsiteUI')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
        ];
  const APIRows =
    solution === 'siyaha'
      ? [
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.API.chatApps')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.API.almosaferAPI')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <>
              <AccessTimeIcon sx={{ color: 'siyaha.main' }} />
              <Box>{translate('soon')}</Box>
            </>,
            <>
              <AccessTimeIcon sx={{ color: 'siyaha.main' }} />
              <Box>{translate('soon')}</Box>
            </>
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.API.bookingAPI')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <>
              <AccessTimeIcon sx={{ color: 'siyaha.main' }} />
              <Box>{translate('soon')}</Box>
            </>,
            <>
              <AccessTimeIcon sx={{ color: 'siyaha.main' }} />
              <Box>{translate('soon')}</Box>
            </>
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.API.airbnbAPI')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <>
              <AccessTimeIcon sx={{ color: 'siyaha.main' }} />
              <Box>{translate('soon')}</Box>
            </>,
            <>
              <AccessTimeIcon sx={{ color: 'siyaha.main' }} />
              <Box>{translate('soon')}</Box>
            </>
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.API.smsApps')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.API.marketingApps')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.API.accountingApps')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
        ]
      : [
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.API.chatApps')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.API.smsApps')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.API.marketingApps')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.API.accountingApps')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
        ];
  const otherRows =
    solution === 'siyaha'
      ? [
          createData(
            <Typography variant={variant}>{translate('siyahaFeatures.other.buildingApps')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'siyaha.main' }} />
          ),
        ]
      : [
          createData(
            <Typography variant={variant}>{translate('maktbFeatures.other.buildingApps')}</Typography>,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CancelIcon sx={{ color: 'rgba(145, 158, 171, 0.8)' }} />,
            <CheckCircleIcon sx={{ color: 'primary.main' }} />
          ),
        ];
  return (
    <>
      <TableContainer component={Paper} sx={{ marginBottom: 10, marginTop: 3 }}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableBody>
            {/* platform section  */}
            <TableRow sx={{ backgroundColor: '#ebeef3' }}>
              <TableCell component="th" scope="row" sx={{ paddingTop: 3 }}>
                <Typography variant="h6">{translate('platformFeatures')}</Typography>
              </TableCell>
              <TableCell align="center" sx={{ paddingTop: 3 }}>
                <Typography variant="h6">{translate('plans.basic')}</Typography>
              </TableCell>
              <Box
                align="center"
                sx={{
                  borderLeft: 1,
                  borderRight: 1,
                  borderTop: 1,
                  borderColor: color,
                  borderRadius: '20px 20px 0 0',
                }}
              >
                <div style={{ marginTop: 14 }}>
                  <Typography
                    variant="caption"
                    sx={{ color: 'white', backgroundColor: color, padding: 1, borderRadius: 1 }}
                  >
                    {translate('bestSeller')}
                  </Typography>
                </div>
                <TableCell sx={{ paddingBottom: 3 }}>
                  <Typography variant="h6">{translate('plans.pro')}</Typography>
                </TableCell>
              </Box>
              <TableCell align="center" sx={{ paddingTop: 3 }}>
                <Typography variant="h6">{translate('plans.enterprise')}</Typography>
              </TableCell>
            </TableRow>
            {platformRows.map((row, index) => (
              <TableRow
                key={row.feature}
                sx={{
                  borderBottom: index < platformRows.length - 1 ? '1px solid #ddd' : 'none',
                }}
              >
                <TableCell component="th" scope="row">
                  {row.feature}
                </TableCell>
                <TableCell align="center">{row.basic}</TableCell>
                <Box
                  align="center"
                  sx={{
                    borderLeft: 1,
                    borderRight: 1,
                    borderColor: color,
                  }}
                >
                  <TableCell>{row.pro}</TableCell>
                </Box>
                <TableCell align="center">{row.enterprise}</TableCell>
              </TableRow>
            ))}

            {/* website section  */}
            <TableRow sx={{ backgroundColor: '#ebeef3' }}>
              <TableCell component="th" scope="row" sx={{ paddingTop: 4, paddingBottom: 2 }}>
                <Typography variant="h6">{translate('websiteFeatures')}</Typography>
              </TableCell>
              <TableCell align="center">{null}</TableCell>
              <TableCell align="center">{null}</TableCell>
              <TableCell align="center">{null}</TableCell>
            </TableRow>
            {websiteRows.map((row, index) => (
              <TableRow
                key={row.feature}
                sx={{
                  borderBottom: index < websiteRows.length - 1 ? '1px solid #ddd' : 'none',
                }}
              >
                <TableCell component="th" scope="row">
                  {row.feature}
                </TableCell>
                <TableCell align="center">{row.basic}</TableCell>
                <Box
                  align="center"
                  sx={{
                    borderLeft: 1,
                    borderRight: 1,
                    borderColor: color,
                  }}
                >
                  <TableCell>{row.pro}</TableCell>
                </Box>
                <TableCell align="center">{row.enterprise}</TableCell>
              </TableRow>
            ))}

            {/* API section */}
            <TableRow sx={{ backgroundColor: '#ebeef3' }}>
              <TableCell component="th" scope="row" sx={{ paddingTop: 4, paddingBottom: 2 }}>
                <Typography variant="h6">{translate('APIFeatures')}</Typography>
              </TableCell>
              <TableCell align="center">{null}</TableCell>
              <TableCell align="center">{null}</TableCell>
              <TableCell align="center">{null}</TableCell>
            </TableRow>
            {APIRows.map((row, index) => (
              <TableRow
                key={row.feature}
                sx={{
                  borderBottom: index < APIRows.length - 1 ? '1px solid #ddd' : 'none',
                }}
              >
                <TableCell component="th" scope="row">
                  {row.feature}
                </TableCell>
                <TableCell align="center">{row.basic}</TableCell>
                <Box
                  align="center"
                  sx={{
                    borderLeft: 1,
                    borderRight: 1,
                    borderColor: color,
                  }}
                >
                  <TableCell>{row.pro}</TableCell>
                </Box>
                <TableCell align="center">{row.enterprise}</TableCell>
              </TableRow>
            ))}

            {/* others section */}
            <TableRow sx={{ backgroundColor: '#ebeef3' }}>
              <TableCell component="th" scope="row" sx={{ paddingTop: 4, paddingBottom: 2 }}>
                {null}
              </TableCell>
              <TableCell align="center">{null}</TableCell>
              <TableCell align="center">{null}</TableCell>
              <TableCell align="center">{null}</TableCell>
            </TableRow>
            {otherRows.map((row, index) => (
              <TableRow
                key={row.feature}
                sx={{
                  borderBottom: index < otherRows.length - 1 ? '1px solid #ddd' : 'none',
                }}
              >
                <TableCell component="th" scope="row">
                  {row.feature}
                </TableCell>
                <TableCell align="center">{row.basic}</TableCell>
                <Box
                  align="center"
                  sx={{
                    borderLeft: 1,
                    borderRight: 1,
                    borderBottom: 1,
                    borderColor: color,
                    borderRadius: '0 0 20px 20px',
                  }}
                >
                  <TableCell>{row.pro}</TableCell>
                </Box>
                <TableCell align="center">{row.enterprise}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
