import { Button, Tooltip, Typography } from '@mui/material';

import useLocales from '../../hooks/useLocales';
import Iconify from '../Iconify';

export default function ExportAsSheetButton({ count, ...props }) {
  const { translate } = useLocales();

  return (
    <Tooltip title={translate('export_as_sheet_count', { count })}>
      <Button
        data-export-button
        variant="contained"
        sx={{
          zIndex: 1,
          bgcolor: (theme) => theme.palette.background.paper,
          boxShadow: (theme) => theme.shadows[1],
          color: (theme) => theme.palette.grey[600],
          '&:hover': {
            bgcolor: (theme) => theme.palette.grey[200],
            boxShadow: (theme) => theme.shadows[2],
          },
        }}
        {...props}
      >
        <Iconify icon="mdi:table-download" />
        <Typography variant="caption" sx={{ ml: 1, textTransform: 'none' }}>
          {translate('export_as_sheet')}
        </Typography>
      </Button>
    </Tooltip>
  );
}
