import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  debounce,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Iconify from '../../components/Iconify';
import SearchIcon from '../../components/Icons/SearchIcon';
import PropertyCard from '../../components/PropertyCard';
import useLocales from '../../hooks/useLocales';
import { selectors as dealsSelectors } from '../../models/deals/reducers';
import * as propertiesActions from '../../models/properties/actions';
import { selectors as propertiesSelectors } from '../../models/properties/reducers';
import { selectors as subscriptionSelectors } from '../../models/subscription/reducers';
import * as teamActions from '../../models/team/actions';
import { selectors as teamSelectors } from '../../models/team/reducers';
import { selectors as userSelectors } from '../../models/user/reducers';
import * as utilActions from '../../models/utils/actions';
import { selectors as utilSelectors } from '../../models/utils/reducers';
import { TENANT_ROLES } from '../../utils/constants';
import DealPropertyModal from './DealPropertyModal';
import { dealsPerCategory } from './DealsView';
import PropertyRemovalModal from './PropertyRemovalModal';

const commonFieldsProps = {
  InputLabelProps: {
    shrink: true,
  },
};

const DealForm = ({ formik }) => {
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const userInfo = useSelector(userSelectors.me);
  const workspace = useSelector(userSelectors.workspace);
  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');

  const dispatch = useDispatch();

  // gets 4k results in KSA, better opts available: TODO:
  const cities = useSelector(utilSelectors.cities);
  const countries = useSelector(utilSelectors.countries);
  const districts = useSelector(utilSelectors.districts);
  const districtsLoading = useSelector(utilSelectors.loading);
  const deal = useSelector(dealsSelectors.deal);
  const { purpose, type: listingType } = formik.values;

  const search = useSelector(teamSelectors.search);
  const searchLoading = useSelector(teamSelectors.loading);
  const filteredProperties = useSelector(propertiesSelectors.filteredProperties);

  const handleSearch = debounce((e) => {
    const input = e.target.value;
    dispatch(teamActions.searchTeamByNameRequest(input));
  }, 500);

  const serializeOption = (opt) => `${opt.name} - ${opt.mobile_number}`;

  const [showPropertiesModal, setShowPropertiesModal] = useState(false);
  const [showPropertyRemovalModal, setShowPropertyRemovalModal] = useState(false);
  const [areaDistrictFilteredProperties, setAreaDistrictFilteredProperties] = useState([]);

  const stages = dealsPerCategory(translate);

  useEffect(() => {
    if (!deal) {
      return;
    }

    dispatch(
      propertiesActions.filterPropertiesRequest({
        purpose: deal.purpose === 'buy' ? 'sell' : deal.purpose,
        category: deal.category,
        type: deal.type,
      })
    );
  }, [deal]);

  // filter properties by area and districts
  useEffect(() => {
    if (!filteredProperties || !deal) {
      return;
    }
    const { min_area: minArea, max_area: maxArea, districts } = deal;
    let areaAndDistrictProperties = filteredProperties.filter((property) => {
      if (minArea && maxArea) {
        return property.area >= minArea && property.area <= maxArea;
      }
      if (minArea) {
        return property.area >= minArea;
      }
      if (maxArea) {
        return property.area <= maxArea;
      }
      return true;
    });

    if (districts && districts.length > 0) {
      const districtIds = districts.map((district) => district.id);
      areaAndDistrictProperties = areaAndDistrictProperties.filter(
        (property) => property.district && districtIds.includes(property.district.id)
      );
    }

    setAreaDistrictFilteredProperties(areaAndDistrictProperties);
  }, [filteredProperties, deal]);

  useEffect(() => {
    dispatch(utilActions.getCountriesRequest());
  }, []);

  useEffect(() => {
    const countryId = formik.initialValues.country_id;
    if (countryId) {
      dispatch(utilActions.getCitiesByCountryIdRequest(countryId));
    }
  }, [formik.initialValues.country_id]);

  useEffect(() => {
    const cityId = formik.initialValues.city_id;
    console.log('cityId', cityId);

    if (cityId) {
      dispatch(utilActions.getDistrictsByCityIdRequest(cityId));
    }
  }, [formik.initialValues.city_id]);

  useEffect(() => {
    dispatch(utilActions.getDistrictsByCityIdRequest(formik.values.city_id));
  }, [formik.values.city_id]);

  useEffect(() => {
    dispatch(utilActions.getCitiesByCountryIdRequest(formik.values.country_id));
  }, [formik.values.country_id]);

  useEffect(() => {
    handleSearch({ target: { value: '' } });
  }, []);

  useEffect(() => {
    const n = formik.values.districts?.map((d) => d.id) || [];
    formik.setFieldValue('districts_ids', n);
  }, [formik.values.districts]);

  const handleDistrictsChange = (e, newValue) => {
    const n = newValue.map((d) => d.id);
    formik.setFieldValue('districts_ids', n);
    formik.setFieldValue('districts', newValue);
  };

  const handlePropertySelection = (property) => {
    formik.setFieldValue('property', property);
    setShowPropertiesModal(false);
    formik.handleSubmit();
  };

  const handlePropertyRemoval = () => {
    formik.setFieldValue('property', null);
    setShowPropertyRemovalModal(false);
    formik.handleSubmit();
  };

  const handleStageChange = (stage) => {
    formik.setFieldValue('stage', stage);
    formik.handleSubmit();
  };

  const handleKeyPress = (event) => {
    const pattern = /^\d*(\.\d{0,2})?$/; // Adjusted pattern to allow optional leading digits
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar) && !['.', 'Backspace', 'Enter'].includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <Grid
      item
      xs={12}
      lg={8}
      sx={{
        '& .MuiFormHelperText-root': {
          textAlign: 'end',
        },
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <button type="submit" style={{ display: 'none' }}>
          Submit
        </button>

        <DealPropertyModal
          open={showPropertiesModal}
          onClose={() => setShowPropertiesModal(false)}
          onSubmit={handlePropertySelection}
          filteredProperties={areaDistrictFilteredProperties}
        />

        <PropertyRemovalModal
          open={showPropertyRemovalModal}
          onClose={() => setShowPropertyRemovalModal(false)}
          onSubmit={handlePropertyRemoval}
        />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph sx={{ mb: 1 }}>
                  {translate('deal.stage')}
                </Typography>
                <Typography variant="caption" paragraph sx={{ color: '#919EAB' }}>
                  {translate('deal.stageDescription')}
                </Typography>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth error={formik.touched.stage && formik.errors.stage}>
                      <Box display="flex" gap={2}>
                        {Object.entries(stages).map(([key, { title, borderColor, palette }]) => (
                          <Button
                            key={key}
                            variant="contained"
                            color={palette}
                            sx={{
                              opacity: formik.values.stage === key ? 1 : 0.3,
                              borderColor,
                              borderWidth: 2,
                              borderStyle: 'solid',
                              backgroundColor: formik.values.stage === key ? borderColor : 'white',
                              fontWeight: formik.values.stage === key ? 'bold' : 'normal',
                              color: formik.values.stage === key ? 'white' : 'black',
                              '&:hover': {
                                opacity: 1,
                                backgroundColor: 'none',
                                color: 'white',
                              },
                            }}
                            onClick={() => handleStageChange(key)}
                            aria-pressed={formik.values.stage === key}
                            aria-label={title}
                          >
                            {translate(`deal.stages.${key}`)}
                          </Button>
                        ))}
                      </Box>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                  {translate('deal.information')}
                </Typography>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      {...commonFieldsProps}
                      name="amount"
                      label={translate('deal.amount')}
                      value={formik.values.amount}
                      error={formik.touched.amount && formik.errors.amount}
                      onChange={formik.handleChange}
                      helperText={translate('Saudi Riyals')}
                      fullWidth
                      InputProps={{
                        inputProps: {
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                          onKeyPress: handleKeyPress,
                        },
                      }}
                    />
                    {formik.errors.amount ? <p style={{ color: 'red' }}>{translate(formik.errors.amount)}</p> : null}
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      {...commonFieldsProps}
                      name="commission"
                      label={translate('deal.commission')}
                      value={formik.values.commission}
                      error={formik.touched.commission && formik.errors.commission}
                      onChange={formik.handleChange}
                      helperText={translate('Saudi Riyals')}
                      fullWidth
                      InputProps={{
                        inputProps: {
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                          onKeyPress: handleKeyPress,
                        },
                      }}
                    />
                    {formik.errors.commission ? (
                      <p style={{ color: 'red' }}>{translate(formik.errors.commission)}</p>
                    ) : null}
                  </Grid>

                  {formik.values.sales_person && (
                    <Grid item xs={12}>
                      <TextField
                        {...commonFieldsProps}
                        label={translate('deal.salesPerson')}
                        value={formik.values.sales_person}
                        disabled
                        fullWidth
                        helperText={
                          workspace?.role_id !== TENANT_ROLES.companyMember && (
                            <Button
                              onClick={() => {
                                formik.setFieldValue('sales_person_id', '');
                                formik.setFieldValue('sales_person', '');
                              }}
                            >
                              {translate('deal.changeSalesPerson')}
                            </Button>
                          )
                        }
                      />
                    </Grid>
                  )}

                  {!formik.values.sales_person && (
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Box sx={{ display: 'flex', w: 1, mb: 4 }}>
                          <Autocomplete
                            disabled={workspace?.role_id === TENANT_ROLES.companyMember}
                            options={search || []}
                            loading={!!searchLoading}
                            getOptionLabel={serializeOption}
                            filterOptions={(x) => x}
                            fullWidth
                            onInputChange={handleSearch}
                            onChange={(e, v) => formik.setFieldValue('sales_person_id', v.user_id)}
                            isOptionEqualToValue={(opt, value) => serializeOption(opt) === serializeOption(value)}
                            renderInput={(params) => (
                              <TextField
                                name="sales_person_id"
                                error={formik.touched.sales_person_id && formik.errors.sales_person_id}
                                InputProps={{
                                  ...(params.InputProps || {}),
                                  startAdornment: <SearchIcon />,
                                  type: 'search',
                                }}
                                value={formik.values.sales_person_id}
                                placeholder={translate('deal.searchLabel')}
                                label={translate('deal.salesPerson')}
                                {...params}
                              />
                            )}
                          />
                        </Box>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ mr: 1 }}>
                      {translate('contact.information')}
                    </Typography>

                    {!activeMaktbSubscription?.is_trial &&
                      !(
                        workspace?.role_id === TENANT_ROLES.companyMember && userInfo?.id !== deal?.contact.created_by
                      ) && (
                        <Link href={`/maktb/crm/contacts/${formik.values.contact_id}`} underline="hover">
                          <Tooltip title={translate('contact.navigateToContact')}>
                            <IconButton variant="outlined" color="secondary">
                              <Iconify icon="icon-park-outline:share" />
                            </IconButton>
                          </Tooltip>
                        </Link>
                      )}
                  </Box>
                }
              />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      {...commonFieldsProps}
                      name="client"
                      label={translate('deal.clientName')}
                      value={formik.values.client}
                      disabled
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <TextField
                      {...commonFieldsProps}
                      name="mobile_number"
                      label={translate('deal.clientMobileNumber')}
                      value={formik.values.mobile_number}
                      disabled
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} lg={4} />

                  <Grid item xs={12} lg={4}>
                    <TextField
                      {...commonFieldsProps}
                      name="purpose"
                      label={translate('deal.purpose')}
                      value={translate(`deal.${formik.values.purpose}`)}
                      disabled
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <TextField
                      {...commonFieldsProps}
                      name="category"
                      label={translate('deal.category')}
                      value={translate(`deal.${formik.values.category}`)}
                      disabled
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <TextField
                      {...commonFieldsProps}
                      name="type"
                      label={translate('deal.listingType')}
                      value={translate(`deal.${formik.values.type}`)}
                      disabled
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph>
                  {translate('deal.preferredFeatures')}
                </Typography>

                <Box sx={{ pt: 3, pb: 3, mt: 2 }}>
                  <Grid container spacing={6}>
                    {purpose === 'rent' && (
                      <Grid item xs={12} lg={4}>
                        <FormControl fullWidth error={formik.touched.type && formik.errors.rent_period}>
                          <InputLabel id="deal-period" shrink>
                            {translate('deal.period')}
                          </InputLabel>
                          <Select
                            labelId="deal-period"
                            value={formik.values.rent_period || 'daily'}
                            onChange={formik.handleChange}
                            input={<OutlinedInput notched name="rent_period" label={translate('deal.period')} />}
                          >
                            <MenuItem value="daily">{translate('deal.daily')}</MenuItem>
                            <MenuItem value="weekly">{translate('deal.weekly')}</MenuItem>
                            <MenuItem value="monthly">{translate('deal.monthly')}</MenuItem>
                            <MenuItem value="quarterly">{translate('deal.quarterly')}</MenuItem>
                            <MenuItem value="semi_annually">{translate('deal.semiAnnually')}</MenuItem>
                            <MenuItem value="annually">{translate('deal.annually')}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    <Grid item xs={12} lg={4}>
                      <TextField
                        {...commonFieldsProps}
                        name="min_price"
                        label={translate('deal.minPrice')}
                        value={formik.values.min_price}
                        error={formik.touched.min_price && formik.errors.min_price}
                        onChange={formik.handleChange}
                        helperText={translate('Saudi Riyals')}
                        fullWidth
                      />
                      {formik.errors.min_price ? <div>{formik.errors.min_price}</div> : null}
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <TextField
                        {...commonFieldsProps}
                        name="max_price"
                        label={translate('deal.maxPrice')}
                        value={formik.values.max_price}
                        error={formik.touched.max_price && formik.errors.max_price}
                        onChange={formik.handleChange}
                        helperText={translate('Saudi Riyals')}
                        fullWidth
                      />
                      {formik.errors.max_price ? <div>{formik.errors.max_price}</div> : null}
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <FormControl fullWidth>
                        <InputLabel id="deal-facade" shrink>
                          {translate('unit.facade')}
                        </InputLabel>
                        <Select
                          label="Facade"
                          labelId="deal-facade"
                          value={formik.values.facade}
                          error={formik.touched.facade && formik.errors.facade}
                          onChange={formik.handleChange}
                          fullWidth
                          input={<OutlinedInput notched name="facade" label={translate('unit.facade')} />}
                        >
                          <MenuItem value="north">{translate('unit.north')}</MenuItem>
                          <MenuItem value="east">{translate('unit.east')}</MenuItem>
                          <MenuItem value="east_west">{translate('unit.eastWest')}</MenuItem>
                          <MenuItem value="west">{translate('unit.west')}</MenuItem>
                          <MenuItem value="south">{translate('unit.south')}</MenuItem>
                          <MenuItem value="north_east">{translate('unit.northEast')}</MenuItem>
                          <MenuItem value="north_west">{translate('unit.northWest')}</MenuItem>
                          <MenuItem value="north_south">{translate('unit.northSouth')}</MenuItem>
                          <MenuItem value="south_east">{translate('unit.southEast')}</MenuItem>
                          <MenuItem value="south_west">{translate('unit.southWest')}</MenuItem>
                          <MenuItem value="north_east_west">{translate('unit.northEastWest')}</MenuItem>
                          <MenuItem value="north_south_east">{translate('unit.northSouthEast')}</MenuItem>
                          <MenuItem value="north_south_west">{translate('unit.northSouthWest')}</MenuItem>
                          <MenuItem value="south_east_west">{translate('unit.southEastWest')}</MenuItem>
                          <MenuItem value="north_south_east_west">{translate('unit.northSouthEastWest')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <TextField
                        {...commonFieldsProps}
                        name="min_area"
                        label={translate('deal.minArea')}
                        value={formik.values.min_area}
                        error={formik.touched.min_area && formik.errors.min_area}
                        onChange={formik.handleChange}
                        helperText={translate('sqm')}
                        fullWidth
                      />
                      {formik.errors.min_area ? <div>{formik.errors.min_area}</div> : null}
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <TextField
                        {...commonFieldsProps}
                        name="max_area"
                        label={translate('deal.maxArea')}
                        value={formik.values.max_area}
                        error={formik.touched.max_area && formik.errors.max_area}
                        onChange={formik.handleChange}
                        helperText={translate('sqm')}
                        fullWidth
                      />
                      {formik.errors.max_area ? <div>{formik.errors.max_area}</div> : null}
                    </Grid>

                    {listingType !== 'land' && (
                      <>
                        <Grid item xs={12} lg={4}>
                          <TextField
                            {...commonFieldsProps}
                            name="bedrooms"
                            label={translate('unit.bedrooms')}
                            value={formik.values.bedrooms}
                            error={formik.touched.bedrooms && formik.errors.bedrooms}
                            onChange={formik.handleChange}
                            fullWidth
                          />
                          {formik.errors.bedrooms ? <div>{formik.errors.bedrooms}</div> : null}
                        </Grid>

                        <Grid item xs={12} lg={4}>
                          <TextField
                            {...commonFieldsProps}
                            name="bathrooms"
                            label={translate('unit.bathrooms')}
                            value={formik.values.bathrooms}
                            error={formik.touched.bathrooms && formik.errors.bathrooms}
                            onChange={formik.handleChange}
                            fullWidth
                          />
                          {formik.errors.bathrooms ? <div>{formik.errors.bathrooms}</div> : null}
                        </Grid>
                      </>
                    )}

                    {purpose === 'rent' && <></>}
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Card sx={{ minHeight: 350 }}>
              <CardContent>
                <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                  {translate('deal.preferredLocations')}
                </Typography>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth error={formik.touched.country_id && formik.errors.country_id}>
                      <InputLabel id="country-label" shrink>
                        {translate('Country')}
                      </InputLabel>
                      <Select
                        name="country_id"
                        labelId="country-select"
                        label={translate('country')}
                        onChange={formik.handleChange}
                        value={formik.values.country_id}
                        input={<OutlinedInput notched name="country_id" label={translate('Country')} />}
                        disabled={!!formik.values.city_id}
                      >
                        {!!countries &&
                          countries.map((c) => (
                            <MenuItem key={c.id} value={c.id}>
                              {languageCode === 'ar' ? c.name_ar : c.name_en}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      options={cities || []}
                      getOptionLabel={(city) => (languageCode === 'ar' ? city.name_ar : city.name_en)}
                      value={cities?.find((city) => city.id === formik.values.city_id) || null}
                      onChange={(event, newValue) => {
                        formik.setFieldValue('city_id', newValue ? newValue.id : '');
                      }}
                      renderInput={(params) => <TextField {...params} variant="outlined" label={translate('City')} />}
                      disabled={formik.values.districts_ids.length > 0}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      noOptionsText="No options"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      options={districts || []}
                      loading={districtsLoading}
                      groupBy={(district) => (languageCode === 'ar' ? district.city_name_ar : district.city_name_en)}
                      getOptionLabel={(d) => (languageCode === 'ar' ? d.name_ar : d.name_en)}
                      value={formik.values.districts}
                      onChange={handleDistrictsChange}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label={translate('Districts')} name="districts" />
                      )}
                      renderOption={(props, option, state) => (
                        <span {...props} key={option.id}>
                          {languageCode === 'ar' ? option.name_ar : option.name_en}
                        </span>
                      )}
                      placeholder="Districts"
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const name = languageCode === 'ar' ? option.name_ar : option.name_en;

                          return (
                            <Chip
                              key={index}
                              sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                              label={name}
                              {...getTagProps({ index })}
                            />
                          );
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ minHeight: 350 }}>
            <Card sx={{ minHeight: 350 }}>
              <CardContent sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" paragraph sx={{ mb: 0 }}>
                      {translate('deal.matchedProperty')}
                    </Typography>
                    <Tooltip title={translate('deal.matchedPropertiesInfo')}>
                      <InfoOutlinedIcon color="action" sx={{ ml: 1 }} />
                    </Tooltip>
                  </Box>

                  <Button variant="outlined" onClick={() => setShowPropertiesModal(true)}>
                    {translate('deal.selectProperty')}
                  </Button>
                </Box>

                <Box sx={{ minHeight: 200, mt: 2 }}>
                  {formik.values.property ? (
                    <PropertyCard
                      showLink={
                        !activeMaktbSubscription?.is_trial &&
                        !(
                          workspace?.role_id === TENANT_ROLES.companyMember &&
                          userInfo?.id !== deal?.property?.created_by_id
                        )
                      }
                      property={formik.values.property}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box sx={{ mr: 1 }}>
                            <Typography variant="caption" sx={{ mr: 1 }}>
                              {translate('SAR')}
                            </Typography>
                            <Typography variant="button">
                              {formik.values.property.purpose === 'sell'
                                ? formik.values.property.selling_price
                                : formik.values.property.rent_price_annually ||
                                  formik.values.property.rent_price_semi_annually ||
                                  formik.values.property.rent_price_quarterly ||
                                  formik.values.property.rent_price_monthly}
                            </Typography>
                          </Box>
                          <Typography variant="caption">
                            /
                            {formik.values.property.purpose === 'sell'
                              ? translate('unit.sellingPrice')
                              : (formik.values.property.rent_price_annually && translate('deal.rentPriceAnnually')) ||
                                (formik.values.property.rent_price_semi_annually &&
                                  translate('deal.rentPriceSemiAnnually')) ||
                                (formik.values.property.rent_price_quarterly && translate('deal.rentPriceQuarterly')) ||
                                (formik.values.property.rent_price_monthly && translate('deal.rentPriceMonthly'))}
                          </Typography>
                        </Box>
                        <Button
                          variant="contained"
                          sx={{ color: 'white' }}
                          onClick={() => setShowPropertyRemovalModal(true)}
                        >
                          {translate('remove')}
                        </Button>
                      </Box>
                    </PropertyCard>
                  ) : (
                    <Box
                      sx={{
                        height: 200,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#919EAB77',
                      }}
                    >
                      <Typography>{translate('deal.noPropertySelected')}</Typography>
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default DealForm;
