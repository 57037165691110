import { Box, Button, Dialog, Divider, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import useLocales from '../../hooks/useLocales';
import * as projectActions from '../../models/projects/actions';
import { selectors as subscriptionSelectors } from '../../models/subscription/reducers';
import { dispatch } from '../../redux/store';

export const addProjectSchema = Yup.object({
  name_ar: Yup.string().required('Required'),
  name_en: Yup.string().required('Required'),
  description_ar: Yup.string().max(675, 'Description should be up to 675 characters').required('Required'),
  description_en: Yup.string().max(675, 'Description should be up to 675 characters').required('Required'),
});

const AddProjectModal = ({ open, onClose }) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const tenantRole = localStorage.getItem('tenant_role');
  const isDisabled = tenantRole === '6';

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');

  const callback = (type, response) => {
    if (type === 'success') {
      toast.success(translate('project.successCreation'));
      navigate(`/maktb/projects/${response.data.id}`);
    } else {
      toast(translate('Error'), { type: 'error' });
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name_ar: '',
      name_en: '',
      description_ar: '',
      description_en: '',
    },
    validationSchema: addProjectSchema,
    isInitialValid: false,
    onSubmit: (values) => {
      setLoading(true);
      dispatch(projectActions.createProjectRequest({ ...values, callback }));
    },
  });

  const [charCountAr, setCharCountAr] = useState(formik.values.description_ar.length);
  const [charCountEn, setCharCountEn] = useState(formik.values.description_en.length);

  const handleDescriptionChangeAr = (e) => {
    const { value } = e.target;
    setCharCountAr(value.length);
    formik.handleChange(e);
  };

  const handleDescriptionChangeEn = (e) => {
    const { value } = e.target;
    setCharCountEn(value.length);
    formik.handleChange(e);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
        <Typography sx={{ p: 2, mb: 4 }} variant="h5">
          {translate('project.addProject')}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <button type="submit" style={{ display: 'none' }}>
            Submit
          </button>

          <Box sx={{ display: 'flex' }}>
            <TextField
              name="name_ar"
              value={formik.values.name_ar}
              label={translate('project.nameAr')}
              fullWidth
              sx={{ mx: 1 }}
              onChange={formik.handleChange}
            />
            <TextField
              name="name_en"
              value={formik.values.name_en}
              label={translate('project.nameEn')}
              fullWidth
              sx={{ mx: 1 }}
              onChange={formik.handleChange}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 2 }}>
            <Box sx={{ flex: 1, mx: 1 }}>
              <TextField
                name="description_ar"
                type="text"
                multiline
                rows={6}
                label={translate('project.descriptionAr')}
                error={formik.touched.description_ar && formik.errors.description_ar}
                value={formik.values.description_ar}
                onChange={handleDescriptionChangeAr}
                fullWidth
              />
              <Typography variant="caption" color="textSecondary">
                {charCountAr}/675
              </Typography>
            </Box>
            <Box sx={{ flex: 1, mx: 1 }}>
              <TextField
                name="description_en"
                type="text"
                multiline
                rows={6}
                label={translate('project.descriptionEn')}
                error={formik.touched.description_en && formik.errors.description_en}
                value={formik.values.description_en}
                onChange={handleDescriptionChangeEn}
                fullWidth
              />
              <Typography variant="caption" color="textSecondary">
                {charCountEn}/675
              </Typography>
            </Box>
          </Box>
        </form>

        <Divider sx={{ mt: 2 }} />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <Button variant="outlined" sx={{ color: 'black', borderColor: '#919EAB66', mr: 2 }} onClick={onClose}>
            {translate('deal.cancelButton')}
          </Button>

          <Button disabled={!formik.isValid} variant="contained" sx={{ color: 'white' }} onClick={formik.submitForm}>
            {translate('deal.createButton')}
          </Button>
        </Box>
      </Box>

      {activeMaktbSubscription?.is_trial && (
        <Box
          style={{
            backgroundColor: theme.palette.grey[300],
            paddingTop: 15,
            paddingBottom: 15,
            textAlign: 'center',
            color: 'black',
          }}
        >
          <Typography variant="h7" paragraph>
            {translate('project.addUnlimitedProjects')}
          </Typography>
          <Button
            variant="contained"
            sx={{ color: 'white', pt: 1, pb: 1 }}
            onClick={() => {
              navigate(`/cart/maktb`);
            }}
          >
            {translate('navbar.upgradeButton')}
          </Button>
        </Box>
      )}
    </Dialog>
  );
};

export default AddProjectModal;
