import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
// eslint-disable-next-line import/no-named-as-default
import DealsServices from './services';

export function* getDealsSaga({ payload }) {
  try {
    const response = yield call(DealsServices.getDeals, payload);
    yield put(actions.getDealsSuccess(response.data));
  } catch (err) {
    yield put(actions.getDealsFailure(err));
  }
}

export function* loadMoreDealsSaga({ payload }) {
  try {
    const response = yield call(DealsServices.getDeals, {
      stage: payload.stage,
      page: payload.page,
    });
    yield put(actions.loadMoreDealsSuccess({ data: response.data, stage: payload.stage }));
    payload?.callback?.('success');
  } catch (err) {
    yield put(actions.loadMoreDealsFailure({ error: err, stage: payload.stage }));
  }
}

export function* exportDealsAsSheetSaga({ payload }) {
  const payloadCallback = payload.callback;

  delete payload.callback;
  try {
    const response = yield call(DealsServices.getDeals, {
      export: true,
      ...payload,
    });
    yield put(actions.exportDealsAsSheetSuccess(response.data));
    payloadCallback?.('success', response.data);
  } catch (err) {
    yield put(actions.exportDealsAsSheetFailure(err));
    payloadCallback?.('error', err.response.data);
  }
}

export function* createDealSaga({ payload }) {
  try {
    const response = yield call(DealsServices.createDeal, payload);
    yield put(actions.createDealSuccess(response.data));
    payload?.callback?.('success', response.data);
  } catch (err) {
    yield put(actions.createDealFailure(err));
    payload?.callback?.('error', err.response.data);
  }
}

export function* getDealByIdSaga({ payload }) {
  try {
    const response = yield call(DealsServices.getDealById, payload);
    yield put(actions.getDealByIdSuccess(response.data));
  } catch (err) {
    yield put(actions.getDealByIdFailure(err));
  }
}

export function* editDealSaga({ payload }) {
  const { id, callback, ...data } = payload;
  try {
    const response = yield call(DealsServices.editDeal, id, data);
    yield put(actions.editDealSuccess(response.data));
    callback?.('success');
  } catch (err) {
    yield put(actions.editDealFailure(err));
    callback?.('error', err);
  }
}

export function* toggleArchiveDealSaga({ payload }) {
  const { id, callback } = payload;
  try {
    const response = yield call(DealsServices.toggleDealArchive, id);
    yield put(actions.toggleArchiveDealSuccess(response.data));
    callback?.('success');
  } catch (err) {
    yield put(actions.toggleArchiveDealFailure(err));
    callback?.('error', err);
  }
}

export function* dealsSaga() {
  yield all([
    takeEvery(actions.loadMoreDealsRequest, loadMoreDealsSaga),
    takeLatest(actions.getDealsRequest, getDealsSaga),
    takeLatest(actions.exportDealsAsSheetRequest, exportDealsAsSheetSaga),
    takeLatest(actions.createDealRequest, createDealSaga),
    takeLatest(actions.getDealByIdRequest, getDealByIdSaga),
    takeLatest(actions.editDealRequest, editDealSaga),
    takeLatest(actions.toggleArchiveDealRequest, toggleArchiveDealSaga),
  ]);
}
