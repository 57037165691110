// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

import { setSession } from '../utils/token';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const initialize = async () => {
    try {
      const accessToken = localStorage.getItem('token');
      if (accessToken) {
        setSession(accessToken);
        setIsAuthenticated(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // Update this version number whenever you want to clear local storage
    const expectedVersion = '1.1.1';
    const storedVersion = localStorage.getItem('appVersion');

    if (!storedVersion || storedVersion !== expectedVersion) {
      // Clear local storage
      localStorage.clear();

      // Clear session storage
      sessionStorage.clear();

      // Store the new version
      localStorage.setItem('appVersion', expectedVersion);
      console.log('App updated to version', expectedVersion);
    }
  }, []); // Empty dependency array ensures this effect runs only once after initial mount

  useEffect(() => {
    initialize();
  }, []);

  return <AuthContext.Provider value={{ isAuthenticated }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
