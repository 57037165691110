// @mui
import { Card, CardContent, CardHeader, Container, Grid, Typography } from '@mui/material';
import { useMemo } from 'react';
// hooks
import { useSelector } from 'react-redux';

// components
import Page from '../../components/Page';
import useDeferredState from '../../hooks/useDeferredState';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import { selectors as userSelectors } from '../../models/user/reducers';
import UtilsServices from '../../models/utils/services';
import ChartDonut from './ChartDonut';
import ChartLine from './ChartLine';

// ----------------------------------------------------------------------

export default function Dashboard() {
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const userInfo = useSelector(userSelectors.me);

  const [listingsGrowthViews] = useDeferredState(
    async () => UtilsServices.getListingsGrowthViews().then((r) => r.data),
    [],
    []
  );

  const [reservationStatus] = useDeferredState(
    async () => UtilsServices.geReservationsStatus().then((r) => r.data),
    [],
    []
  );

  const viewsLineSeries = useMemo(
    () => [
      {
        name: translate('listingsViews'),
        data: Object.values(listingsGrowthViews),
        color: '#1FC6AC',
      },
    ],
    [listingsGrowthViews, translate]
  );

  const donutSeries = Object.values(reservationStatus);

  return (
    <Page title={translate('dashboard')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          {translate('hi')} {userInfo?.name}! {languageCode === 'ar' ? '' : translate('Welcome back')} 👋🏼
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card dir="ltr">
              <CardHeader title={`${translate('listingsViews')}`} dir={languageCode === 'ar' ? 'rtl' : 'ltr'} />
              <CardContent
                sx={{
                  height: 420,
                }}
              >
                <ChartLine series={viewsLineSeries} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card dir="ltr">
              <CardHeader title={translate('siyaha.reservations')} dir={languageCode === 'ar' ? 'rtl' : 'ltr'} />
              <CardContent
                sx={{
                  height: 420,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ChartDonut series={donutSeries} dir={languageCode === 'ar' ? 'rtl' : 'ltr'} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} />

          <Grid item xs={12} md={6} />
        </Grid>
      </Container>
    </Page>
  );
}
