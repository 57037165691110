import Visibility from '@mui/icons-material/VisibilityOutlined';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// hooks
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// @mui
import * as Yup from 'yup';

import Iconify from '../../../components/Iconify';
// components
import Page from '../../../components/Page';
import PropertyCard from '../../../components/PropertyCard';
import QuickSearchToolbar from '../../../components/QuickSearchToolbar';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import * as propertiesActions from '../../../models/properties/actions';
import { selectors as propertiesSelectors } from '../../../models/properties/reducers';
import AddUnitModal from './AddListingsModal';
// ----------------------------------------------------------------------

const propertyCreationSchema = Yup.object({
  type: Yup.string()
    .oneOf([
      'villa',
      'tower_apartment',
      'building_apartment',
      'villa_apartment',
      'land',
      'duplex',
      'townhouse',
      'mansion',
      'villa_floor',
      'farm',
      'istraha',
      'store',
      'office',
      'storage',
      'building',
    ])
    .required('Required'),
});

export default function ListingsView() {
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listView, setListView] = useState(true);
  const [showAddUnitModal, setShowAddUnitModal] = useState(false);
  const [queryParams, setQueryParams] = useState({ include_archived: 0, is_siyaha: true });

  const onViewChange = () => {
    setListView((value) => !value);
  };

  useEffect(() => {
    dispatch(propertiesActions.getPropertiesRequest(queryParams));
  }, []);

  const properties = useSelector(propertiesSelectors.properties);
  const getPropertiesLoading = useSelector(propertiesSelectors.getPropertiesLoading);

  const callback = (type, r) => {
    if (type === 'success') {
      navigate(`/siyaha/listings/${r.data.id}`);
    } else {
      toast(translate(r?.response?.data?.message), { type: 'info' });
    }
  };

  const formik = useFormik({
    validationSchema: propertyCreationSchema,
    initialValues: {
      category: 'residential',
      purpose: 'rent',
      type: '',
      unit_number: '0',
      product: 'siyaha',
    },
    onSubmit: (values) => {
      dispatch(propertiesActions.createPropertyRequest({ ...values, callback }));
      dispatch(propertiesActions.getPropertiesRequest(queryParams));
      setShowAddUnitModal(false);
    },
    validateOnMount: true,
  });

  const handleRow = (params) => {
    navigate(`/siyaha/listings/${params.row.id}`);
  };

  const columns = [
    {
      field: 'unit_number',
      headerName: '#',
      maxWidth: 100,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'type',
      headerName: translate('unit.type'),
      maxWidth: 280,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => translate(`unit.${row.type}`),
    },
    {
      field: 'views',
      headerName: translate('unit.views'),
      maxWidth: 280,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => row.views,
    },
    {
      field: 'district',
      headerName: translate('unit.district'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => (languageCode === 'ar' ? row.district?.name_ar : row.district?.name_en || '-'),
    },
    {
      field: 'city',
      headerName: translate('unit.city'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => (languageCode === 'ar' ? row.city?.name_ar : row.city?.name_en || '-'),
    },
    {
      field: 'area',
      headerName: translate('unit.area'),
      headerClassName: 'datagrid-header',
      flex: true,
      align: 'center',
      headerAlign: 'center',
      valueGetter: ({ row }) => row.area || '-',
    },
    {
      field: 'created_by',
      headerName: translate('unit.createdBy'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => row.created_by || '-',
    },
    {
      field: 'sort',
      headerName: translate('unit.sort'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'published_on_website',
      headerName: translate('unit.publishedOnWebsite'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => (row.published_on_website ? translate('yes') : translate('no')),
    },
    // {
    //   field: 'useless',
    //   headerName: '',
    //   width: 50,
    //   renderCell: ({ row }) => <PropertyOptionMenu property={row} />,
    //   headerClassName: 'datagrid-header',
    //   headerAlign: 'center',
    //   align: 'center',
    // },
  ];
  const navigateToPropertyPage = (id) => {
    navigate(`/siyaha/listings/${id}`);
  };

  const handleCheckboxChange = () => {
    setQueryParams({ ...queryParams, include_archived: !queryParams.include_archived });
    dispatch(
      propertiesActions.getPropertiesRequest({
        ...queryParams,
        include_archived: !queryParams.include_archived === true ? 1 : 0,
      })
    );
  };

  return (
    <Page title={translate('units')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <AddUnitModal
          open={showAddUnitModal}
          formik={formik}
          onClose={() => setShowAddUnitModal(false)}
          showSearchbar={false}
          isBuy={false}
          title={translate('siyaha.unit.creationTitle')}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('units')}
            </Typography>

            <IconButton
              sx={{ p: 1, border: '1px solid rgba(145, 158, 171, 0.32)', borderRadius: '8px', ml: 2 }}
              onClick={onViewChange}
            >
              {listView ? <Iconify icon={'tabler:layout-cards'} /> : <Iconify icon={'tabler:table'} />}
            </IconButton>
          </Box>
          <Button variant="contained" sx={{ color: 'white', pt: 1, pb: 1 }} onClick={() => setShowAddUnitModal(true)}>
            {translate('siyaha.unit.createUnitButton')}
          </Button>
        </Box>

        {listView ? (
          <Card className="no-animation">
            <CardContent sx={{ p: 0 }}>
              <Box>
                <Box sx={{}}>
                  <FormControlLabel
                    sx={{ ml: 2, mt: 2 }}
                    label={translate('unit.includeArchived')}
                    control={<Checkbox name="include_archived" checked={Boolean(queryParams.include_archived)} />}
                    onChange={handleCheckboxChange}
                  />
                </Box>
                <DataGrid
                  loading={getPropertiesLoading}
                  onRowClick={handleRow}
                  columns={columns}
                  rows={properties || []}
                  getRowClassName={(params) => `clickable ${params.row.is_archived ? 'archived' : ''}`}
                  disableColumnFilter
                  disableColumnSelector
                  components={{
                    Toolbar: QuickSearchToolbar,
                  }}
                  hideFooterSelectedRowCount
                  disableSelectionOnClick
                  rowsPerPageOptions={[10]}
                  pageSize={10}
                  autoHeight
                  sx={{
                    '& .datagrid-header': {
                      backgroundColor: '#F4F6F8',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      borderRadius: 0,
                    },
                    border: 'none',
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        ) : (
          <Grid container spacing={2}>
            {properties &&
              properties.map((p, i) => (
                <Grid item xs={12} md={6} lg={4} xl={3} key={i}>
                  <PropertyCard property={p}>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        color="#000000"
                        onClick={() => navigateToPropertyPage(p.id)}
                      >
                        {translate(`deal.view`)}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ paddingInlineEnd: '3px' }} color="#000000">
                          {p.views}
                        </Typography>
                        <Visibility fontSize="small" />
                      </Box>
                    </Box>
                  </PropertyCard>
                </Grid>
              ))}
          </Grid>
        )}
      </Container>
    </Page>
  );
}
