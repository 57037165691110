import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useLocales from '../hooks/useLocales';
import * as utilActions from '../models/utils/actions';
import { selectors as utilSelectors } from '../models/utils/reducers';

const KSA = {
  lat: 24.69999996,
  lng: 46.73333003,
};

const LocationSelector = ({
  formik,
  row,
  onLocation,
  onMapClick,
  mapCenter = null,
  markerPosition = null,
  onPlaceChanged,
  isDisabled,
  disableDistrict,
  zoom = 12,
  ...props
}) => {
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const dispatch = useDispatch();

  const countries = useSelector(utilSelectors.countries);
  const cities = useSelector(utilSelectors.cities);
  const districts = useSelector(utilSelectors.districts);
  const searchBoxRef = useRef(null);

  const [api, setApi] = useState();
  const [map, setMap] = useState();

  useEffect(() => {
    dispatch(utilActions.getCountriesRequest());
  }, []);

  useEffect(() => {
    if (formik.values.country_id) {
      dispatch(utilActions.getCitiesByCountryIdRequest(formik.values.country_id));
    }
  }, [formik.values.country_id]);

  useEffect(() => {
    if (formik.values.city_id) {
      dispatch(utilActions.getDistrictsByCityIdRequest(formik.values.city_id));
    }
  }, [formik.values.city_id]);

  useEffect(() => {
    if (!markerPosition && !mapCenter && map && 'geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          map.setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          map.setCenter(KSA);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (api && map) {
      const autocomplete = new api.places.Autocomplete(searchBoxRef.current);
      const listener = () => handlePlaceChanged(autocomplete);

      autocomplete.addListener('place_changed', listener);
      autocomplete.bindTo('bounds', map);

      return () => {
        autocomplete.removeEventListener('place_changed', listener);
      };
    }
  }, [api, map]);

  useEffect(() => {
    window.callback = () => {
      console.log('Google maps loaded');
    };
  }, []);

  const renderMarker = ({ lat, lng }) =>
    new api.Marker({
      position: {
        lat,
        lng,
      },
      map,
    });

  useEffect(() => {
    if (api && map && markerPosition) {
      const marker = renderMarker(markerPosition);

      return () => {
        marker.setMap(null);
      };
    }
  }, [api, map, markerPosition]);

  const onCountryChange = (e) => {
    formik.handleChange(e);
  };

  const onCityChange = (e) => {
    formik.handleChange(e);
  };

  const onDistrictChange = (e) => {
    formik.handleChange(e);

    if (onLocation) {
      onLocation({
        districtId: e.target.value,
        cityId: formik.values.city_id,
        countryId: formik.values.country_id,
      });
    }
  };

  const onMapsLoaded = ({ map, maps }) => {
    setApi(maps);
    setMap(map);
  };

  const handlePlaceChanged = (autoComplete) => {
    onPlaceChanged(autoComplete);
  };

  return (
    <Grid container spacing={2} {...props}>
      {!disableDistrict && (
        <>
          <Grid item xs={12} md={row ? 8 : 12}>
            <FormControl error={formik.touched.country_id && formik.errors.country_id} fullWidth>
              <InputLabel id="country-select">{translate('Country')}</InputLabel>
              <Select
                name="country_id"
                labelId="country-select"
                label={translate('Country')}
                onChange={onCountryChange}
                value={formik.values.country_id}
                disabled={isDisabled}
              >
                {!!countries &&
                  countries?.map((c) => (
                    <MenuItem key={c.id} value={c.id}>
                      {languageCode === 'ar' ? c.name_ar : c.name_en}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={row ? 4 : 12}>
            <FormControl
              error={formik.touched.city_id && formik.errors.city_id}
              sx={{ minWidth: 200 }}
              disabled={!formik.values.country_id}
              fullWidth
            >
              <Autocomplete
                options={cities || []}
                getOptionLabel={(city) => (languageCode === 'ar' ? city.name_ar : city.name_en)}
                value={cities?.find((city) => city.id === formik.values.city_id) || null}
                onChange={(event, newValue) => {
                  formik.setFieldValue('city_id', newValue ? newValue.id : '');
                }}
                renderInput={(params) => <TextField {...params} label={translate('City')} />}
                disabled={isDisabled}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText="No options"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={row ? 4 : 12}>
            <FormControl
              error={formik.touched.district_id && formik.errors.district_id}
              sx={{ minWidth: 200 }}
              disabled={!formik.values.city_id}
              fullWidth
            >
              <Autocomplete
                name="district_id"
                id="district-select"
                options={districts || []}
                getOptionLabel={(option) => {
                  const label = languageCode === 'ar' ? option.name_ar : option.name_en;
                  return label || '';
                }}
                value={districts?.find((option) => option.id === formik.values.district_id) || null}
                onChange={(event, newValue) => {
                  formik.setFieldValue('district_id', newValue ? newValue.id : '');
                }}
                renderInput={(params) => <TextField {...params} label={translate('District')} />}
                disabled={isDisabled}
              />
            </FormControl>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <div style={{ height: '600px', width: '100%', position: 'relative' }}>
          <TextField
            name="address"
            inputRef={searchBoxRef}
            fullWidth
            sx={{
              position: 'absolute',
              top: 65,
              left: 0,
              zIndex: 999,
              backgroundColor: 'white',
              borderRadius: 1,
              maxWidth: 0.95,
              m: 1,
            }}
            disabled={isDisabled}
          />
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyCnjD6fjWH1vreSb0Kwu58S-aDbqqLLWMo',
              libraries: ['places'],
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={onMapsLoaded}
            zoom={zoom}
            options={{
              disableDefaultUI: false,
            }}
            onClick={!isDisabled && onMapClick}
            center={markerPosition || mapCenter || KSA}
          />
        </div>
      </Grid>
    </Grid>
  );
};

LocationSelector.propTypes = {
  mapCenter: PropTypes.object,
  onMapClick: PropTypes.func,
  onLocation: PropTypes.func,
  row: PropTypes.bool,
  formik: PropTypes.object,
};
export default LocationSelector;
