import { Box, Button, Divider, Modal, Typography } from '@mui/material';

import useLocales from '../../../hooks/useLocales';

const ConfirmationDialog = ({ workspace, onConfirm, onClose, open }) => {
  const { translate } = useLocales();
  const {
    currentLang: { value: currentLang },
  } = useLocales();
  const name = workspace[`name_${currentLang.toLowerCase()}`];

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'min(500px, calc(100%))',
          height: 300,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '16px',
          pt: 4,
          px: 4,
          pb: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
        }}
      >
        <Typography component="h4" variant={'h4'}>
          Are you sure you?
        </Typography>

        <Typography>By leaving {name}, you will lose access to any information related to the workspace.</Typography>

        <Divider />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            sx={{ marginRight: '5%', color: '#000', border: '1px solid rgba(145, 158, 171, 0.32)' }}
            variant="outlined"
            onClick={onClose}
          >
            {translate('cancel')}
          </Button>

          <Button variant="contained" color="error" onClick={onConfirm}>
            {translate('workspace.confirmLeave')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmationDialog;
