import { ENV, HOST_API, PORT_API } from '../../config';
import axios from '../../utils/axios';

const tenantUrl = localStorage.getItem('tenant_url');

class TeamServices {
  getUrl() {
    return HOST_API;
  }

  getTenantUrl() {
    const protocol = ENV === 'local' ? 'http' : 'https';
    const url = `${protocol}://${tenantUrl}${PORT_API}`;
    return url;
  }

  //

  getMembers = async () => {
    try {
      return await axios.get(`${this.getTenantUrl()}/api/members`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  getMember = async (id) => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/members/${id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  getInvitations = async () => {
    try {
      return await axios.get(`${this.getTenantUrl()}/api/invitations`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  cancelInvitation = async (id) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/invitations/${id}/cancel`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  removeMember = async ({ member, deleteAll }) => {
    try {
      return axios.delete(`${this.getTenantUrl()}/api/members/${member.member_id}?delete_all=${deleteAll}`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  changeRole = async ({ member, roleId }) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/members/${member.member_id}/change-role`, {
        company_role_id: roleId,
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  editShortLink = async ({ shortLink }) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/members/short-link`, {
        short_link: shortLink,
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  inviteMember = async ({ code, phone, role }) => {
    try {
      return axios.post(`${this.getTenantUrl()}/api/invitations`, {
        mobile_number: `${code.substring(1)}${phone}`,
        role_id: role,
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  search = async (name) => {
    const params = new URLSearchParams({
      name,
    });

    try {
      return axios.get(`${this.getTenantUrl()}/api/members?${params.toString()}`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  getTotalClients(params) {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/clients/total`, { params }).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getTotalDeals(id) {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/deals/total?member_id=${id}`).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getTotalProperties(params) {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/properties/total`, { params }).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getClientsGrowth(params) {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/clients/growth`, { params }).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getPropertiesGrowth(params) {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/properties/growth`, { params }).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getDealsGrowth(id) {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/deals/growth?member_id=${id}`).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getDealStages(id) {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/deals/stages?member_id=${id}`).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getListingsGrowthViews(id) {
    try {
      return axios
        .get(`${this.getTenantUrl()}/api/dashboard/properties/growth-views?member_id=${id}`)
        .then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

export { TeamServices };
export default new TeamServices();
