// @mui
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// hooks
import { toast } from 'react-toastify';
import * as Yup from 'yup';

// components
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import * as workspaceActions from '../../../models/workspace/actions';
import { selectors as workspaceSelectors } from '../../../models/workspace/reducers';

// ----------------------------------------------------------------------

export const termsSchema = Yup.object({
  terms_ar: Yup.string().max(20000, 'Terms and Conditions should be up to 20000 characters'),
  terms_en: Yup.string().max(20000, 'Terms and Conditions should be up to 20000 characters'),
});

export default function Terms() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const terms = useSelector(workspaceSelectors.terms);

  useEffect(() => {
    dispatch(workspaceActions.getTermsRequest());
  }, []);

  const callback = (type) => {
    if (type === 'success') {
      toast(translate('web.pages.terms.successUpdate'), { type: 'success' });
      dispatch(workspaceActions.getTermsRequest());
    } else {
      toast('Error!', { type: 'error' });
    }
  };

  const formik = useFormik({
    initialValues: {
      is_terms_active: !!terms?.is_terms_active || false,
      terms_ar: terms?.terms_ar || '',
      terms_en: terms?.terms_en || '',
    },
    enableReinitialize: true,
    validationSchema: termsSchema,
    isInitialValid: false,
    onSubmit: (values) => {
      dispatch(workspaceActions.editTermsRequest({ ...values, callback }));
    },
  });

  const [charCountAr, setCharCountAr] = useState(formik.values.terms_ar.length);
  const [charCountEn, setCharCountEn] = useState(formik.values.terms_en.length);

  useEffect(() => {
    setCharCountAr(formik.values.terms_ar.length);
    setCharCountEn(formik.values.terms_en.length);
  }, [formik]);

  const handleTermsChangeAr = (e) => {
    const { value } = e.target;
    setCharCountAr(value.length);
    formik.handleChange(e);
  };

  const handleTermsChangeEn = (e) => {
    const { value } = e.target;
    setCharCountEn(value.length);
    formik.handleChange(e);
  };

  return (
    <Page title={translate('web.pages.terms.title')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('web.pages.terms.title')}
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{ color: 'white', pt: 1, pb: 1 }}
            onClick={formik.handleSubmit}
            disabled={!formik.isValid}
          >
            {translate('web.pages.saveButton')}
          </Button>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph>
                  {translate('web.pages.terms.activationTitle')}
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label={translate('web.pages.terms.title')}
                      control={<Checkbox name="is_terms_active" checked={formik.values.is_terms_active} />}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph>
                  {translate('web.pages.terms.edit')}
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      name="terms_ar"
                      type="text"
                      multiline
                      minRows={15}
                      maxRows={50}
                      label={translate('web.pages.terms.termsAr')}
                      error={formik.touched.terms_ar && formik.errors.terms_ar}
                      value={formik.values.terms_ar}
                      onChange={handleTermsChangeAr}
                      fullWidth
                      InputProps={{
                        inputProps: { dir: 'rtl' },
                      }}
                    />
                    <Typography variant="caption" color="textSecondary">
                      {charCountAr}/20000
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      name="terms_en"
                      type="text"
                      multiline
                      minRows={15}
                      maxRows={50}
                      label={translate('web.pages.terms.termsEn')}
                      error={formik.touched.terms_en && formik.errors.terms_en}
                      value={formik.values.terms_en}
                      onChange={handleTermsChangeEn}
                      fullWidth
                      InputProps={{
                        inputProps: { dir: 'ltr' },
                      }}
                    />
                    <Typography variant="caption" color="textSecondary" dir="ltr">
                      {charCountEn}/20000
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
