// @mui
import { Box, List, ListSubheader } from '@mui/material';
import { styled } from '@mui/material/styles';

// hooks
import useLocales from '../../../hooks/useLocales';
//
import { NavListRoot } from './NavList';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

export default function NavSectionVertical({ navConfig, userRoles, isCollapse = false, ...other }) {
  const { translate } = useLocales();

  return (
    <Box {...other}>
      {navConfig.map(
        (group) =>
          (group.roles.length === 0 || userRoles.some((role) => group.roles.includes(role))) && (
            <List key={group.subheader} disablePadding sx={{ px: 2 }}>
              <ListSubheaderStyle
                sx={{
                  ...(isCollapse && {
                    opacity: 0,
                    display: 'none',
                  }),
                }}
              >
                {translate(group.subheader)}
              </ListSubheaderStyle>

              {group.items.map(
                (list) =>
                  (list?.roles?.length === 0 || userRoles.some((role) => list?.roles?.includes(role))) && (
                    <NavListRoot key={list.title + list.path} list={list} isCollapse={isCollapse} />
                  )
              )}
            </List>
          )
      )}
    </Box>
  );
}
