import { ENV, HOST_API, PORT_API } from '../../config';
import axios from '../../utils/axios';

const tenantUrl = localStorage.getItem('tenant_url');

class UtilServices {
  getUrl() {
    return HOST_API;
  }

  getTenantUrl() {
    const protocol = ENV === 'local' ? 'http' : 'https';
    const url = `${protocol}://${tenantUrl}${PORT_API}`;
    return url;
  }

  getDistricts = async (cityId) => axios.get(`${this.getUrl()}/api/districts?city_id=${cityId}`);

  getCitiesByCountryId = async (id) => {
    try {
      return axios.get(`${this.getUrl()}/api/cities?country_id=${id}`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  getCountries = async () => {
    try {
      return axios.get(`${this.getUrl()}/api/countries`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  getPresignedUrl = async (data) => {
    try {
      return axios.post(`${this.getTenantUrl()}/api/uploads`, data);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  uploadingToAPresignedUrl = async (url, image) => {
    try {
      return axios.put(url, image, {
        transformRequest: [
          (image, headers) => {
            delete headers.common.Authorization;
            axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
            return image;
          },
        ],
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  uploadFiles = async (data) => {
    try {
      return axios.post(`${this.getTenantUrl()}/api/uploads/files`, data);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  getTotalClients(params) {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/clients/total`, { params }).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getTotalDeals() {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/deals/total`).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getTotalProjects() {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/projects/total`).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getTotalProperties(params) {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/properties/total`, { params }).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getListingsViews() {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/properties/views`).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getListingsGrowthViews() {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/properties/growth-views`).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getClientsGrowth(params) {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/clients/growth`, { params }).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getPropertiesGrowth(params) {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/properties/growth`, { params }).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getDealsGrowth() {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/deals/growth`).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getDealStages() {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/deals/stages`).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  geReservationsStatus() {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/reservations/status`).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getTotalReservations() {
    try {
      return axios.get(`${this.getTenantUrl()}/api/dashboard/reservations/total`).then((r) => r.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  getBanks = async () => {
    try {
      return axios.get(`${this.getUrl()}/api/banks`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };
}

export { UtilServices };
export default new UtilServices();
