/* eslint no-useless-catch: 0 */
import { ENV, HOST_API, PORT_API } from '../../config';
import axios from '../../utils/axios';

const tenantUrl = localStorage.getItem('tenant_url');

class WorkspaceServices {
  getUrl() {
    return HOST_API;
  }

  getTenantUrl() {
    const protocol = ENV === 'local' ? 'http' : 'https';
    const url = `${protocol}://${tenantUrl}${PORT_API}`;
    return url;
  }

  getWorkspaceInformation = async () => {
    try {
      return await axios.get(`${this.getTenantUrl()}/api/workspace`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  updateWorkspaceInformation = async (data) => {
    try {
      const response = await axios.post(
        `${this.getTenantUrl()}/api/workspace`,
        {
          ...data,
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );

      return response;
    } catch (err) {
      throw err;
    }
  };

  updateWorkspaceLogo = async (data) => {
    try {
      const response = await axios.post(
        `${this.getTenantUrl()}/api/workspace/set-logo`,
        {
          logo_url: data.logo_url,
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );

      return response;
    } catch (err) {
      throw err;
    }
  };

  getTerms = () => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/workspace/get-terms`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  editTerms = (data) => {
    try {
      return axios.post(`${this.getTenantUrl()}/api/workspace/set-terms`, data);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  getPrivacy = () => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/workspace/get-privacy`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  editPrivacy = (data) => {
    try {
      return axios.post(`${this.getTenantUrl()}/api/workspace/set-privacy`, data);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };
}

export { WorkspaceServices };
export default new WorkspaceServices();
