import { ENV, HOST_API, PORT_API } from '../../config';
import axios from '../../utils/axios';

const tenantUrl = localStorage.getItem('tenant_url');

class NotesServices {
  getUrl() {
    return HOST_API;
  }

  getTenantUrl() {
    const protocol = ENV === 'local' ? 'http' : 'https';
    const url = `${protocol}://${tenantUrl}${PORT_API}`;
    return url;
  }

  createNote = async (payload) => {
    try {
      return axios.post(`${this.getTenantUrl()}/api/t-notes`, payload);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  getNotes = async ({ type, id, product }) => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/t-notes?type=${type}&id=${id}&product=${product}`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  editNote = async ({ id, content }) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/t-notes/${id}`, content);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  deleteNote = async ({ id }) => {
    try {
      return axios.delete(`${this.getTenantUrl()}/api/t-notes/${id}`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };
}

export { NotesServices };
export default new NotesServices();
