import { ENV, HOST_API, PORT_API } from '../../config';
import axios from '../../utils/axios';

const tenantUrl = localStorage.getItem('tenant_url');

class PropertiesServices {
  getUrl() {
    return HOST_API;
  }

  getTenantUrl() {
    const protocol = ENV === 'local' ? 'http' : 'https';
    const url = `${protocol}://${tenantUrl}${PORT_API}`;
    return url;
  }

  getProperties = (queryParams) => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/properties`, {
        params: queryParams,
        ...(queryParams.export ? { responseType: 'blob' } : {}),
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  searchProperties = (queryParams) => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/properties`, {
        params: queryParams,
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  filterProperties = (filters) => {
    try {
      return axios.get(
        `${this.getTenantUrl()}/api/properties?page=1&type=${filters.type}&category=${filters.category}&purpose=${
          filters.purpose
        }`
      );
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  getProperty = (id) => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/properties/${id}`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  exportPropertyAsPdf = (id) => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/properties/${id}`, {
        params: {
          pdf: true,
        },
        responseType: 'blob',
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  createProperty = (data) => {
    try {
      return axios.post(`${this.getTenantUrl()}/api/properties`, data);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  setCover = (id, data) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/properties/${id}/cover`, data);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  removeImages(id, urls) {
    try {
      return axios.delete(`${this.getTenantUrl()}/api/properties/${id}/images`, {
        data: {
          images_urls: urls,
        },
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  }

  editProperty(id, data) {
    try {
      return axios.put(`${this.getTenantUrl()}/api/properties/${id}`, data);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  }

  setImages(id, urls) {
    try {
      return axios.put(`${this.getTenantUrl()}/api/properties/${id}/images`, {
        images_urls: urls,
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  }

  togglePropertyAchieve = async (id) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/properties/${id}/archive`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  getAmenities = () => {
    try {
      return axios.get(`${this.getUrl()}/api/amenities`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };
}

export { PropertiesServices };
export default new PropertiesServices();
