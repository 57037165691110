import { Chip, Grid, IconButton, Link, Stack, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

import useLocales from '../hooks/useLocales';
import Datetime from './Datetime';
import Iconify from './Iconify';

const InfoBox = ({ label, number, date, backButtonLink = null, children = '', ...props }) => {
  const theme = useTheme();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const isArabic = languageCode === 'ar';

  return (
    <Grid
      {...props}
      container
      spacing={2}
      sx={{
        p: 2,
        pt: 0,
        marginBottom: 2,
        ...(props.sx || {}),
        position: 'relative',
      }}
    >
      <Grid item lg={8} xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              [theme.breakpoints.down('md')]: {
                m: 2,
              },
            }}
          >
            {backButtonLink && (
              <Link
                href={backButtonLink}
                underline="none"
                sx={{
                  position: 'absolute',
                  left: -10,
                  top: -5,
                  [theme.breakpoints.down('md')]: {
                    top: -10,
                    mx: 2,
                  },
                }}
              >
                <IconButton
                  sx={{
                    color: theme.palette.secondary.main,
                    boxShadow: theme.shadows[1],
                    bgcolor: alpha(theme.palette.common.white, 0.7),
                  }}
                >
                  <Iconify icon={isArabic ? 'mdi:arrow-right' : 'mdi:arrow-left'} />
                </IconButton>
              </Link>
            )}
            <Typography variant="h4">{label}</Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Stack
              spacing={1}
              sx={{
                alignItems: 'end',
              }}
            >
              <Chip
                label={
                  <Typography variant="caption">
                    {translate('number')}: {number}
                  </Typography>
                }
                variant="outlined"
                size="small"
                icon={<Iconify icon="mdi:folder-hash-outline" />}
                sx={{ px: 1, py: 2, width: 'fit-content', minWidth: '282px', justifyContent: 'start' }}
              />
              <Datetime title={translate('createdAt')} date={date} />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default InfoBox;
