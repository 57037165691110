/* eslint-disable react/prop-types */
import { Visibility, VisibilityOff } from '@mui/icons-material';
// @mui
import { IconButton, TextField } from '@mui/material';
import { useState } from 'react';

// hooks
import useLocales from '../../../hooks/useLocales';

export const Password = ({ formik }) => {
  const { translate } = useLocales();

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => setShowPassword((showPassword) => !showPassword);

  return (
    <TextField
      id="password"
      name="password"
      type={showPassword ? 'text' : 'password'}
      label={translate('auth.login.password')}
      variant="outlined"
      fullWidth
      sx={{ marginTop: '10px' }}
      value={formik.values.password}
      onChange={formik.handleChange}
      error={formik.touched.password && Boolean(formik.errors.password)}
      helperText={formik.touched.password && formik.errors.password}
      InputProps={{
        endAdornment: (
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleTogglePassword}
            onMouseDown={handleTogglePassword}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        ),
      }}
    />
  );
};
