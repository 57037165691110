import { alpha, Avatar, Box, Chip, Tooltip, Typography } from '@mui/material';
import { camelCase } from 'change-case';
import { format } from 'date-fns';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import Iconify from '../../components/Iconify';
import PropertyCard from '../../components/PropertyCard';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import { selectors as teamSelectors } from '../../models/team/reducers';
import { fNumber } from '../../utils/formatNumber';
import { dealsPerCategory } from '../deals/DealsView';

/**
 * @typedef {Object} Activity
 * @property {Object} before_data
 * @property {Object} after_data
 * @property {Object} user
 * @property {string} created_at
 */

const ActivityLabel = ({ label }) => (
  <Typography variant="subtitle2" sx={{ color: '#919EAB', fontWeight: 'bold', fontSize: 14 }}>
    {label}
  </Typography>
);

/**
 * @param {{activity: Activity, translate: Function, isArabic: boolean}} props
 * @returns {JSX.Element|null}
 */
export const ActivityMessage = ({ activity, translate, isArabic, type }) => {
  const members = useSelector(teamSelectors.members) || [];
  const changed = (field) => activity.before_data[field] !== activity.after_data[field];

  if (type !== 'deal') {
    return null;
  }

  const commonBoxStyles = {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    p: 1,
    bgcolor: (theme) => alpha(theme.palette.grey[300], 0.3),
    borderRadius: 1,
    '&:not(:has(.before-value, .after-value))': {
      display: 'none',
    },
  };

  const beforeValueStyles = {
    textDecoration: 'line-through',
    bgcolor: (theme) => alpha(theme.palette.warning.main, 0.2),
    p: 1,
    borderRadius: 1,
  };

  const afterValueStyles = {
    bgcolor: (theme) => alpha(theme.palette.success.main, 0.2),
    p: 1,
    borderRadius: 1,
  };

  if (changed('property_id')) {
    const label = translate('deal.matchedProperty');
    const beforeProperty = activity.before_data.property;
    const afterProperty = activity.after_data.property;

    return (
      <Box key={label} sx={commonBoxStyles}>
        <ActivityLabel label={label} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%', p: 1 }}>
          {beforeProperty && (
            <Box
              sx={{
                textDecoration: 'line-through',
                position: 'relative',
                '&:before': {
                  zIndex: 1,
                  content: '""',
                  position: 'absolute',
                  inset: 0,
                  bgcolor: beforeValueStyles.bgcolor,
                  pointerEvents: 'none',
                  borderRadius: 2,
                },
              }}
              className="before-value"
            >
              <PropertyCard property={beforeProperty} condensed />
            </Box>
          )}
          {afterProperty && (
            <Box
              sx={{
                position: 'relative',
                '&:before': {
                  zIndex: 1,
                  content: '""',
                  position: 'absolute',
                  inset: 0,
                  bgcolor: afterValueStyles.bgcolor,
                  pointerEvents: 'none',
                  borderRadius: 2,
                },
              }}
              className="after-value"
            >
              <PropertyCard property={afterProperty} condensed />
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  if (changed('districts_ids')) {
    const label = translate('Districts');
    const beforeDistricts = activity.before_data.districts || [];
    const afterDistricts = activity.after_data.districts || [];

    return (
      <Box key={label} sx={commonBoxStyles}>
        <ActivityLabel label={label} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%', p: 1 }}>
          {beforeDistricts.length > 0 &&
            beforeDistricts.map((district) => (
              <Tooltip
                title={`${isArabic ? district.name_ar : district.name_en} - ${
                  isArabic ? district.city.name_ar : district.city.name_en
                } - ${isArabic ? district.city.country.name_ar : district.city.country.name_en}`}
                placement="bottom"
                arrow
              >
                <Chip
                  key={district.id}
                  variant="subtitle2"
                  sx={beforeValueStyles}
                  label={isArabic ? district.name_ar : district.name_en}
                  className="before-value"
                />
              </Tooltip>
            ))}
          {afterDistricts.length > 0 &&
            afterDistricts.map((district) => (
              <Tooltip
                title={`${isArabic ? district.name_ar : district.name_en} - ${
                  isArabic ? district.city.name_ar : district.city.name_en
                } - ${isArabic ? district.city.country.name_ar : district.city.country.name_en}`}
                placement="bottom"
                arrow
              >
                <Chip
                  key={`${district.id}-${activity.id}`}
                  variant="subtitle2"
                  sx={afterValueStyles}
                  label={isArabic ? district.name_ar : district.name_en}
                  className="after-value"
                />
              </Tooltip>
            ))}
        </Box>
      </Box>
    );
  }

  if (changed('user_id')) {
    const beforeUser = activity.before_data.user_id
      ? members.find((member) => member.user_id === activity.before_data.user_id)
      : null;

    const afterUser = activity.after_data.user_id
      ? members.find((member) => member.user_id === activity.after_data.user_id)
      : null;

    const label = translate('deal.salesPerson');
    const beforeValue = beforeUser?.name || '-';
    const afterValue = afterUser?.name || '-';

    if (!beforeUser && !afterUser) return null;

    return (
      <Box key={label} sx={commonBoxStyles}>
        <ActivityLabel label={label} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%', p: 1 }}>
          {beforeUser && (
            <Typography variant="subtitle2" sx={beforeValueStyles} className="before-value">
              {beforeValue}
            </Typography>
          )}
          {afterUser && (
            <Typography variant="subtitle2" sx={afterValueStyles} className="after-value">
              {afterValue}
            </Typography>
          )}
        </Box>
      </Box>
    );
  }

  if (changed('stage')) {
    const stages = dealsPerCategory(translate);
    const before = stages[activity.before_data.stage];
    const after = stages[activity.after_data.stage];

    const label = translate('deal.stage');

    return (
      <Box key={label} sx={commonBoxStyles}>
        <ActivityLabel label={label} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%', p: 1 }}>
          <Chip
            label={before.title}
            sx={{
              borderColor: before.borderColor,
              borderWidth: 1,
              borderStyle: 'dashed',
              ...beforeValueStyles,
              backgroundColor: alpha(before.borderColor, 0.05),
              borderRadius: 1,
            }}
            className="before-value"
          />
          <Chip
            label={after.title}
            sx={{
              borderColor: after.borderColor,
              borderWidth: 1,
              borderStyle: 'dashed',
              ...afterValueStyles,
              backgroundColor: alpha(after.borderColor, 0.05),
              borderRadius: 1,
            }}
            className="after-value"
          />
        </Box>
      </Box>
    );
  }

  const changeFields = {
    purpose: {
      label: translate('deal.purpose'),
      formatter: (value) => translate(`deal.${value}`),
    },
    category: {
      label: translate('deal.category'),
      formatter: (value) => translate(`deal.${value}`),
    },
    type: {
      label: translate('deal.listingType'),
      formatter: (value) => translate(`deal.${value}`),
    },
    amount: {
      label: translate('deal.amount'),
      formatter: fNumber,
    },
    commission: {
      label: translate('deal.commission'),
      formatter: fNumber,
    },
    bathrooms: {
      label: translate('unit.bathrooms'),
      formatter: fNumber,
    },
    bedrooms: {
      label: translate('unit.bedrooms'),
      formatter: fNumber,
    },
    min_price: {
      label: translate('deal.minPrice'),
      formatter: fNumber,
    },
    max_price: {
      label: translate('deal.maxPrice'),
      formatter: fNumber,
    },
    min_area: {
      label: translate('deal.minArea'),
      formatter: fNumber,
    },
    max_area: {
      label: translate('deal.maxArea'),
      formatter: fNumber,
    },
    facade: {
      label: translate('unit.facade'),
      formatter: (value) => (value ? translate(`unit.${camelCase(value)}`) : '-'),
    },
    rent_period: {
      label: translate('deal.period'),
      formatter: (value) => (value ? translate(`deal.${value}`) : '-'),
    },
  };

  const changedFields = Object.keys(changeFields).filter(changed);

  if (changedFields.length === 0) return null;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {changedFields.map((field) => {
        const { label, formatter } = changeFields[field];
        const beforeValue = formatter(activity.before_data[field]);
        const afterValue = formatter(activity.after_data[field]);

        return (
          <Box key={field} sx={commonBoxStyles}>
            <ActivityLabel label={label} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%', p: 1 }}>
              {!['0', '-', ''].includes(beforeValue) && (
                <Typography variant="subtitle2" sx={beforeValueStyles} className="before-value">
                  {beforeValue}
                </Typography>
              )}
              {!['0', '-', ''].includes(afterValue) && (
                <Typography variant="subtitle2" sx={afterValueStyles} className="after-value">
                  {afterValue}
                </Typography>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

/**
 * @param {{activity: Activity}} props
 * @returns {JSX.Element|null}
 */
const ActivityItem = ({ activity, setActivities, type }) => {
  const ref = useRef();
  const { translate } = useLocales();
  const { themeDirection } = useSettings();
  const isArabic = themeDirection === 'rtl';

  useEffect(() => {
    if (ref.current && !ref.current.querySelector('.before-value') && !ref.current.querySelector('.after-value')) {
      setActivities((prev) => prev.filter((a) => a.id !== activity.id));
    }
  }, [ref, activity, setActivities]);

  return (
    <Box
      sx={{
        mb: 2,
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #D9D9D9',
        bgcolor: '#f5f5f5',
        p: 1,
        borderRadius: 1,
        ml: '-1rem',
        '& .before-value, & .after-value': {
          fontSize: 14,
        },
      }}
      data-activity-id={activity.id}
      key={activity.id}
    >
      <Avatar
        sx={{
          bgcolor: (theme) => theme.palette.background.paper,
          width: 40,
          height: 40,
          fontSize: 24,
          boxShadow: (theme) => theme.shadows[4],
        }}
      >
        <Iconify icon="bi:activity" />
      </Avatar>
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography variant="subtitle2" sx={{ color: '#919EAB' }}>
            {activity.user.name}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: '#919EAB' }}>
            {' '}
            -{' '}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: '#919EAB', fontWeight: 'normal' }}>
            {format(new Date(activity.created_at), 'h:mm a')}
          </Typography>
        </Box>
        <Box ref={ref} sx={{ overflow: 'hidden' }} className="activity-message" key={`${activity.id}-message`}>
          <ActivityMessage type={type} activity={activity} translate={translate} isArabic={isArabic} />
        </Box>
      </Box>
    </Box>
  );
};

export default ActivityItem;
