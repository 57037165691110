import { Box, Chip, Tooltip, Typography } from '@mui/material';

import useSettings from '../hooks/useSettings';
import { fDate, fDateTimeSuffix } from '../utils/formatTime';
import Iconify from './Iconify';

export default function Datetime({ date, title = '', minWidth = '282px', dateOnly = false, chipStyles = {} }) {
  const { themeDirection } = useSettings();
  const isArabic = themeDirection === 'rtl';

  return (
    <Box>
      <Tooltip
        title={
          <Typography sx={{ direction: `ltr /* @noflip */` }} variant="h6">
            {dateOnly ? fDate(date) : fDateTimeSuffix(date, isArabic)}
          </Typography>
        }
        placement="bottom"
        arrow
      >
        <Chip
          label={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              }}
            >
              {title && <Box>{title}</Box>}
              <Typography sx={{ direction: 'ltr /* @noflip */' }} variant="caption">
                {dateOnly ? fDate(date) : fDateTimeSuffix(date, isArabic)}
              </Typography>
            </Box>
          }
          variant="outlined"
          size="small"
          icon={<Iconify icon="mdi:calendar-clock" />}
          sx={{
            px: 1,
            py: 2,
            justifyContent: 'start',
            minWidth: `${minWidth}`,
            ...chipStyles,
          }}
        />
      </Tooltip>
    </Box>
  );
}
