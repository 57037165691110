// @mui
import { Box, Button, Link, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactCodeInput from 'react-verification-code-input';

// store
import AuthButton from '../../../components/buttons/AuthButton';
import { Loader } from '../../../components/Loader';
// hooks
import useLocales from '../../../hooks/useLocales';
// components
import AuthBaseLayout from '../../../layouts/AuthBaseLayout';
import * as authActions from '../../../models/auth/actions';
import { disableButton } from '../Auth.utils';
import { verifyCodeSchema } from '../Auth.validationSchemas';

const VerifyCode = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);

  const mobileNumber = state?.mobileNumber.substring(1);
  const isReset = state?.isReset;

  const handleCallback = (type, res) => {
    setLoading(false);
    if (res) {
      if (type === 'success') {
        navigate(isReset ? '/reset-password' : '/complete-account', { state: { token: res, mobileNumber } });
      }
    }
    if (type !== 'success') {
      toast.error(translate(`verifyCodeScreen.${res.message}`));
    }
  };

  if (!mobileNumber) {
    navigate('/login');
  }

  const resendCode = (event) => {
    setLoading(true);
    event.preventDefault();
    dispatch(authActions.sendOtpRequest({ mobileNumber, callback: handleCallback }));
  };

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    verifyCodeSchema,
    onSubmit: (values) => {
      const { code } = values;
      setLoading(true);
      dispatch(authActions.verifyCodeRequest({ mobileNumber, code, callback: handleCallback }));
    },
  });

  const isButtonDisabled = disableButton(['code'], formik) || loading || formik.values?.code?.length !== 4;

  return (
    <AuthBaseLayout
      heading={translate('auth.registration.heading')}
      subtitle={translate('auth.verifyCode.subtitle', { mobileNumber })}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            alignSelf: 'center',
            width: '100%',
            marginTop: '30px',
          }}
          style={{ direction: 'ltr', justifyContent: 'center' }}
        >
          <ReactCodeInput
            placeholder={['-', '-', '-', '-']}
            onChange={(val) => formik.setFieldValue('code', val)}
            onComplete={() => formik.setFieldError('code', null)}
            type={'number'}
            fields={4}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignSelf: 'center',
            width: '100%',
            marginTop: '30px',
          }}
        >
          <Typography variant="caption">{translate('auth.verifyCode.noVerificationCode')}</Typography>
          <Link
            component="button"
            variant="caption"
            type="button"
            onClick={resendCode}
            sx={{ textDecoration: 'underline', color: '#0066FF', marginLeft: '10px' }}
          >
            {translate('auth.verifyCode.resendCode')}
          </Link>
        </Box>

        <AuthButton type="submit" text={translate('auth.verifyCode.verifyCodeBtn')} isDisabled={isButtonDisabled} />

        {loading && <Loader />}
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" sx={{ marginTop: '30px' }}>
          <Button variant="text" onClick={() => navigate(-1)}>
            <Typography variant="caption">{translate('auth.verifyCode.back')}</Typography>
          </Button>
        </Box>
      </form>
    </AuthBaseLayout>
  );
};

export default VerifyCode;
