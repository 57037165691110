import { ENV, HOST_API, PORT_API } from '../../config';
import axios from '../../utils/axios';

const tenantUrl = localStorage.getItem('tenant_url');

class TTransactionsServices {
  getUrl() {
    return HOST_API;
  }

  getTenantUrl() {
    const protocol = ENV === 'local' ? 'http' : 'https';
    const url = `${protocol}://${tenantUrl}${PORT_API}`;
    return url;
  }

  getHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  getTransactions = async () => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/t-transactions`, {
        headers: this.getHeaders(),
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };
}

export { TTransactionsServices };
export default new TTransactionsServices();
