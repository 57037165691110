import React from 'react';
import { Navigate } from 'react-router-dom';

const RolesBasedRerouting = ({ allowedRoles, children, homeUrl, tenantRole }) => {
  if (!allowedRoles.includes(tenantRole)) {
    return <Navigate to={homeUrl} replace />;
  }

  return children;
};

export default RolesBasedRerouting;
