/* eslint-disable import/no-named-as-default */
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import WorkspaceServices from './services';

export function* getWorkspaceInformationSaga() {
  try {
    const response = yield call(WorkspaceServices.getWorkspaceInformation);
    yield put(actions.getWorkspaceInformationSuccess(response.data));
  } catch (err) {
    yield put(actions.getWorkspaceInformationFailure(err));
  }
}

export function* editWorkspaceInformationSaga({ payload }) {
  try {
    const response = yield call(WorkspaceServices.updateWorkspaceInformation, payload);
    yield put(actions.editWorkspaceInformationSuccess(response.data.data));
    payload?.callback?.('success', response.data.data);
  } catch (err) {
    payload?.callback?.('error', err.response);
    yield put(actions.editWorkspaceInformationFailure(err));
  }
}

export function* getTermsSaga() {
  try {
    const response = yield call(WorkspaceServices.getTerms);
    yield put(actions.getTermsSuccess(response.data));
  } catch (err) {
    yield put(actions.getTermsFailure(err));
  }
}

export function* editTermsSaga({ payload }) {
  try {
    const response = yield call(WorkspaceServices.editTerms, payload);
    yield put(actions.editTermsSuccess(response.data.data));
    payload?.callback?.('success', response.data.data);
  } catch (err) {
    payload?.callback?.('error', err.response);
    yield put(actions.editTermsFailure(err));
  }
}

export function* getPrivacySaga() {
  try {
    const response = yield call(WorkspaceServices.getPrivacy);
    yield put(actions.getPrivacySuccess(response.data));
  } catch (err) {
    yield put(actions.getPrivacyFailure(err));
  }
}

export function* editPrivacySaga({ payload }) {
  try {
    const response = yield call(WorkspaceServices.editPrivacy, payload);
    yield put(actions.editPrivacySuccess(response.data.data));
    payload?.callback?.('success', response.data.data);
  } catch (err) {
    payload?.callback?.('error', err.response);
    yield put(actions.editPrivacyFailure(err));
  }
}

export function* workspaceSaga() {
  yield all([
    takeLatest(actions.getWorkspaceInformationRequest, getWorkspaceInformationSaga),
    takeLatest(actions.editWorkspaceInformationRequest, editWorkspaceInformationSaga),
    takeLatest(actions.getTermsRequest, getTermsSaga),
    takeLatest(actions.editTermsRequest, editTermsSaga),
    takeLatest(actions.getPrivacyRequest, getPrivacySaga),
    takeLatest(actions.editPrivacyRequest, editPrivacySaga),
  ]);
}
