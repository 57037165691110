import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import useLocales from '../../hooks/useLocales';
import * as orderActions from '../../models/order/actions';

const OrderOptionsMenu = ({ order }) => {
  const [showMenu, setShowMenu] = useState(false);
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const handleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleOrderCancel = () => {
    dispatch(orderActions.cancelOrderRequest(order));
  };

  return (
    <IconButton id="basic-button" aria-haspopup="true" area-expanded={showMenu} onClick={handleMenu} ref={anchorRef}>
      <Menu
        open={showMenu}
        anchorEl={anchorRef?.current}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleOrderCancel}>{translate('Cancel')}</MenuItem>
      </Menu>
      <MoreVertIcon />
    </IconButton>
  );
};

export default OrderOptionsMenu;
