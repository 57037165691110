import { SvgIcon } from '@mui/material';

const BedIcon = () => (
  <SvgIcon width="12" height="12" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" sx={{ mr: 0.5 }}>
    <path
      d="M0 11.8863C0 12.0727 0.0553226 12.2205 0.165964 12.3296C0.281218 12.4432 0.433345 12.5 0.622357 12.5H1.06492C1.24933 12.5 1.39916 12.4432 1.5144 12.3296C1.62966 12.2205 1.68729 12.0727 1.68729 11.8863V10.9182C1.72877 10.9318 1.80023 10.9431 1.90165 10.9522C2.00769 10.9568 2.10681 10.9591 2.199 10.9591H15.8011C15.8932 10.9591 15.9923 10.9568 16.0984 10.9522C16.2044 10.9431 16.2758 10.9318 16.3128 10.9182V11.8863C16.3128 12.0727 16.3704 12.2205 16.4856 12.3296C16.6009 12.4432 16.7507 12.5 16.9351 12.5H17.3777C17.5666 12.5 17.7165 12.4432 17.8271 12.3296C17.9424 12.2205 18 12.0727 18 11.8863V7.69317C18 6.99772 17.811 6.46817 17.433 6.10454C17.0595 5.73636 16.5155 5.55227 15.8011 5.55227H2.19209C1.48675 5.55227 0.945068 5.73636 0.567036 6.10454C0.189013 6.46817 0 6.99772 0 7.69317V11.8863ZM1.74261 4.46136H3.58894V3.53409C3.58894 3.19772 3.67884 2.94317 3.85863 2.77045C4.04304 2.59318 4.30812 2.50454 4.65387 2.50454H7.19863C7.54438 2.50454 7.80716 2.59318 7.98693 2.77045C8.16679 2.94317 8.25663 3.19772 8.25663 3.53409V4.46136H9.80561V3.53409C9.80561 3.19772 9.89784 2.94317 10.0822 2.77045C10.2666 2.59318 10.5386 2.50454 10.8982 2.50454H13.3392C13.6942 2.50454 13.9639 2.59318 14.1483 2.77045C14.3327 2.94317 14.4249 3.19772 14.4249 3.53409V4.46136H16.2712V2.49773C16.2712 1.83863 16.0961 1.34091 15.7457 1.00454C15.3953 0.668181 14.8928 0.5 14.2382 0.5H3.77565C3.11641 0.5 2.61161 0.668181 2.26124 1.00454C1.91548 1.34091 1.74261 1.83863 1.74261 2.49773V4.46136Z"
      fill="#919EAB"
    />
  </SvgIcon>
);

export default BedIcon;
