import { Box, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useState } from 'react';

import Datetime from './Datetime';

export default function CustomDatePicker({ value, onChange, minDate, maxDate = null, shouldDisableDate = () => {} }) {
  const [open, setOpen] = useState(false);

  const handleButtonClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChange = (date) => {
    onChange(date);
    handleClose();
  };

  return (
    <Box
      sx={{
        position: 'relative',
        direction: 'ltr  /* @noflip */',
      }}
    >
      <Button
        sx={{
          cursor: 'pointer',
        }}
        onClick={handleButtonClick}
      >
        <Datetime
          chipStyles={{
            cursor: 'pointer',
          }}
          dateOnly
          minWidth="150px"
          date={value}
        />
      </Button>
      <DatePicker
        fullWidth
        shouldDisableDate={shouldDisableDate}
        value={value ? dayjs(value) : null}
        minDate={minDate ? dayjs(minDate) : null}
        maxDate={maxDate ? dayjs(maxDate) : null}
        defaultCalendarMonth={dayjs()}
        onChange={handleDateChange}
        sx={{
          visibility: 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        onClose={handleClose}
        open={open}
      />
    </Box>
  );
}
