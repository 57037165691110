import { ENV, HOST_API, PORT_API } from '../../config';
import axios from '../../utils/axios';

const tenantUrl = localStorage.getItem('tenant_url');

class TOrderServices {
  getUrl() {
    return HOST_API;
  }

  getTenantUrl() {
    const protocol = ENV === 'local' ? 'http' : 'https';
    const url = `${protocol}://${tenantUrl}${PORT_API}`;
    return url;
  }

  getHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  getTOrders = (queryParams) => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/t-orders`, {
        params: queryParams,
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  getTOrderDetails = async (id) => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/t-orders/${id}`, {
        headers: this.getHeaders(),
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  confirmPayment = async (id, values) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/t-orders/${id}/set-status`, values, {
        headers: this.getHeaders(),
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };
}

export { TOrderServices };
export default new TOrderServices();
