import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import tOrderServices from './services';

export function* getTOrdersSaga({ payload }) {
  try {
    const response = yield call(tOrderServices.getTOrders, payload);
    yield put(actions.getTOrdersSuccess(response.data));
  } catch (err) {
    yield put(actions.getTOrdersFailure(err));
  }
}

export function* getTOrderByIdSaga({ payload }) {
  try {
    const response = yield call(tOrderServices.getTOrderDetails, payload);
    yield put(actions.getTOrderDetailsSuccess(response.data));
  } catch (err) {
    yield put(actions.getTOrderDetailsFailure(err));
  }
}

export function* tOrdersSaga() {
  yield all([takeLatest(actions.getTOrdersRequest, getTOrdersSaga)]);
  yield all([takeLatest(actions.getTOrderDetailsRequest, getTOrderByIdSaga)]);
}
