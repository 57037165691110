import { SvgIcon } from '@mui/material';

const BathIcon = () => (
  <SvgIcon width="18" height="13" viewBox="0 0 18 13" fill="none">
    <path
      d="M0 1.03753C0 1.33206 0.0985447 1.57973 0.295633 1.78055C0.497201 1.9769 0.745802 2.07507 1.04144 2.07507H16.9519C17.2475 2.07507 17.4961 1.9769 17.6977 1.78055C17.8992 1.57973 18 1.33206 18 1.03753C18 0.743008 17.8992 0.497571 17.6977 0.30122C17.4961 0.100406 17.2475 0 16.9519 0H1.04144C0.745802 0 0.497201 0.100406 0.295633 0.30122C0.0985447 0.497571 0 0.743008 0 1.03753ZM5.0728 9.37799H12.9205C14.2554 9.37799 15.2699 9.05 15.9642 8.39403C16.6585 7.738 17.0056 6.77634 17.0056 5.50898V3.04567H0.994404V5.50898C0.994404 6.77634 1.33931 7.738 2.02911 8.39403C2.7234 9.05 3.73796 9.37799 5.0728 9.37799ZM3.10414 10.951C3.29227 11.0224 3.47817 11.0158 3.66182 10.9309C3.84547 10.8461 3.97089 10.7123 4.03808 10.5293L4.66966 8.77557L3.31243 8.2869L2.68086 10.0273C2.60918 10.2147 2.6159 10.3999 2.70101 10.5828C2.78612 10.7658 2.9205 10.8885 3.10414 10.951ZM14.8891 10.951C15.0773 10.8885 15.2116 10.7658 15.2923 10.5828C15.3774 10.3999 15.3886 10.217 15.3259 10.034L14.6876 8.2869L13.3236 8.77557L13.9619 10.5293C14.0291 10.7123 14.1546 10.8461 14.3382 10.9309C14.5219 11.0158 14.7055 11.0224 14.8891 10.951Z"
      fill="#919EAB"
    />
  </SvgIcon>
);

export default BathIcon;
