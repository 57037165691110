import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
  workspaceInformationDataLoading: false,
  workspaceInformationData: null,
  privacy: null,
  terms: null,
};

const reducer = createReducer(initialState, {
  [actions.getWorkspaceInformationRequest]: (state) => {
    state.workspaceInformationDataLoading = true;
    console.log('workspace requested');
  },
  [actions.getWorkspaceInformationSuccess]: (state, action) => {
    state.loading = false;
    state.workspaceInformationData = action.payload.data;
    console.log('workspace success');
  },
  [actions.getWorkspaceInformationFailure]: (state, action) => {
    state.workspaceInformationDataLoading = false;
    console.log(action, 'workspace error');
  },

  [actions.editWorkspaceInformationRequest]: (state) => {
    state.workspaceInformationDataLoading = true;
    console.log('edit workspace requested');
  },
  [actions.editWorkspaceInformationSuccess]: (state, action) => {
    state.loading = false;
    state.workspaceInformationData = action.payload.data;
    console.log('edit workspace success');
  },
  [actions.editWorkspaceInformationFailure]: (state, action) => {
    state.workspaceInformationDataLoading = false;
    console.log(action, 'edit workspace error');
  },

  [actions.getTermsRequest]: (state) => {
    state.workspaceInformationDataLoading = true;
  },
  [actions.getTermsSuccess]: (state, action) => {
    state.loading = false;
    state.terms = action.payload.data;
  },
  [actions.getTermsFailure]: (state, action) => {
    state.workspaceInformationDataLoading = false;
  },

  [actions.editTermsRequest]: (state) => {
    state.workspaceInformationDataLoading = true;
  },
  [actions.editTermsSuccess]: (state, action) => {
    state.loading = false;
    state.terms = action.payload.data;
  },
  [actions.editTermsFailure]: (state, action) => {
    state.workspaceInformationDataLoading = false;
  },

  [actions.getPrivacyRequest]: (state) => {
    state.workspaceInformationDataLoading = true;
  },
  [actions.getPrivacySuccess]: (state, action) => {
    state.loading = false;
    state.privacy = action.payload.data;
  },
  [actions.getPrivacyFailure]: (state, action) => {
    state.workspaceInformationDataLoading = false;
  },

  [actions.editPrivacyRequest]: (state) => {
    state.workspaceInformationDataLoading = true;
  },
  [actions.editPrivacySuccess]: (state, action) => {
    state.loading = false;
    state.privacy = action.payload.data;
  },
  [actions.editPrivacyFailure]: (state, action) => {
    state.workspaceInformationDataLoading = false;
  },
});

const workspaceInformationDataLoading = (state) => state.workspace.workspaceInformationDataLoading;
const workspaceInformationData = (state) => state.workspace.workspaceInformationData;
const privacy = (state) => state.workspace.privacy;
const terms = (state) => state.workspace.terms;

const selectors = {
  workspaceInformationDataLoading,
  workspaceInformationData,
  privacy,
  terms,
};

export { initialState, reducer, selectors };
export default reducer;
