import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import rootSaga from '../models/rootSaga';
import { rootReducer } from './rootReducer';
// ----------------------------------------------------------------------

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
const persistor = persistStore(store);

const { dispatch } = store;

const useSelector = useAppSelector;

const useDispatch = () => useAppDispatch();

sagaMiddleware.run(rootSaga);

export { dispatch, persistor, store, useDispatch, useSelector };
