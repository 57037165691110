import { Box, Button, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Loader } from '../../components/Loader';
import Page from '../../components/Page';
import useLocales from '../../hooks/useLocales';
import TServices from '../../models/t-services/services';
import RequestForm from './RequestForm';

export const addServiceSchema = Yup.object({
  is_free: Yup.boolean(),
  published_on_website: Yup.boolean(),
  name_ar: Yup.string().required('Required'),
});

const AddRequest = () => {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const callback = (type, response) => {
    setLoading(false);

    // if (type === 'success') {
    //   toast.success(translate('addContactScreen.successCreation'));
    //   navigate(`/crm/contacts/${response.data.id}`);
    // } else {
    //   if (response.status === 422) {
    //     const errorMessage = response.data.message;
    //     toast.error(translate(`addContactScreen.${errorMessage}`));
    //   }
    //   setLoading(false);
    // }
  };

  const formik = useFormik({
    initialValues: {
      is_free: false,
      published_on_website: false,
      name_ar: '',
      description_ar: '',
      name_en: '',
      description_en: '',
      base_price: '',
      tax_percentage: '',
      cover_image_url: '',
      is_archived: false,
    },
    validationSchema: addServiceSchema,
    isInitialValid: false,
    onSubmit: (values) => {
      setLoading(true);
      TServices.createService(values);
    },
  });

  return (
    <Page title={translate('service.createPageTitle')}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
        <Typography variant="h3" component="h1">
          {translate('services')}
        </Typography>

        <Button
          variant="contained"
          sx={{ p: '11px 22px', color: 'white', width: '176px', height: '48px' }}
          onClick={formik.submitForm}
          disabled={!formik.isValid || loading}
        >
          {!loading && translate('service.createButton')}
          {loading && <Loader />}
        </Button>
      </Box>
      <RequestForm formik={formik} />
    </Page>
  );
};

export default AddRequest;
