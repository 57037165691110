import { alpha, Box, Card, CardContent, Typography } from '@mui/material';
import { Droppable } from 'react-beautiful-dnd';

const BoardColumn = ({ droppableId, children, borderColor, label, isDragging, isTargetDrop }) => (
  <Droppable droppableId={droppableId}>
    {(provided) => (
      <Card
        sx={{
          minWidth: 255,
          mr: 2,
          mb: 2,
          minHeight: 400,
          backgroundColor: '#f5f5f5',
          border: '1px solid #919eab52',
          zIndex: 'auto',
          ...(isDragging &&
            isTargetDrop && {
              border: `2px solid ${borderColor}`,
              zIndex: -1,
            }),
          ...(isDragging &&
            !isTargetDrop && {
              opacity: 0.6,
            }),
        }}
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        {label && (
          <Typography
            sx={{
              p: 2,
              borderBottom: `2px solid ${borderColor}`,
              backgroundColor: '#fff',
            }}
          >
            <b>{label}</b>
          </Typography>
        )}
        <Box
          sx={{
            // height: 'calc(100vh - 320px)',
            // maxHeight: '100vh',
            // xs: {
            //   height: '100vh',
            // },
            '& [data-rbd-drop-indicator]': {
              backgroundColor: alpha(borderColor, 0.5),
              height: 50,
            },
          }}
        >
          <CardContent
            sx={{
              p: 1,
              minHeight: 400,
            }}
          >
            {children}
            {provided.placeholder}
          </CardContent>
        </Box>
      </Card>
    )}
  </Droppable>
);

export default BoardColumn;
