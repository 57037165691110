// components
import Chart, { useChart } from '../../components/chart';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

export default function ChartLine({ series, dir }) {
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const chartOptions = useChart({
    xaxis: {
      categories: [
        translate('Jan'),
        translate('Feb'),
        translate('Mar'),
        translate('Apr'),
        translate('May'),
        translate('Jun'),
        translate('Jul'),
        translate('Aug'),
        translate('Sep'),
        translate('Oct'),
        translate('Nov'),
        translate('Dec'),
      ],
    },
    tooltip: {
      x: {
        show: true,
      },
      marker: { show: true },
    },
  });

  return (
    <Chart
      type="line"
      series={series}
      options={chartOptions}
      height={320}
      style={{
        direction: dir,
      }}
    />
  );
}
