// components
import Chart, { useChart } from '../../components/chart';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

export default function ChartDonut({ series, dir }) {
  const { translate } = useLocales();
  const chartOptions = useChart({
    labels: [
      translate('siyaha.reservation.statues.on_hold'),
      translate('siyaha.reservation.statues.pending'),
      translate('siyaha.reservation.statues.confirmed'),
      translate('siyaha.reservation.statues.canceled'),
    ],
    colors: ['#FFC72C', '#12C2CF', '#009966', '#9E005D'],
    stroke: {
      show: true,
    },
    legend: {
      horizontalAlign: 'center',
      fontSize: '14px',
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          labels: {
            show: true,
            total: { label: translate('total') },
          },
        },
      },
    },
  });

  return (
    <Chart
      type="donut"
      series={series}
      options={chartOptions}
      width={400}
      style={{
        direction: dir,
      }}
    />
  );
}
