import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import reservationServices from './services';

export function* getReservationsSaga({ payload }) {
  try {
    const response = yield call(reservationServices.getReservations, payload);
    yield put(actions.getReservationsSuccess(response.data));
  } catch (err) {
    yield put(actions.getReservationsFailure(err));
  }
}

export function* getReservationByIdSaga({ payload }) {
  try {
    const response = yield call(reservationServices.getReservationDetails, payload);
    yield put(actions.getReservationDetailsSuccess(response.data));
  } catch (err) {
    yield put(actions.getReservationDetailsFailure(err));
  }
}

export function* reservationsSaga() {
  yield all([takeLatest(actions.getReservationsRequest, getReservationsSaga)]);
  yield all([takeLatest(actions.getReservationDetailsRequest, getReservationByIdSaga)]);
}
