import { Box, Button, Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import InfoBox from '../../components/InfoBox';
import { Loader } from '../../components/Loader';
import Page from '../../components/Page';
import useLocales from '../../hooks/useLocales';
import * as notificationsActions from '../../models/notifications/actions';
import { selectors as notificationsSelectors } from '../../models/notifications/reducers';
import {
  findPropertyRequestNotification,
  hasPropertyRequestNotification,
  isNotificationManuallyControlled,
} from '../../models/notifications/utils';
import tPropertyRequestServices from '../../models/t-property-requests/services';
import ConfirmationModal from './ConfirmationModal';
import RequestForm from './RequestForm';

const EditRequest = () => {
  const { id } = useParams();

  const { translate } = useLocales();
  const dispatch = useDispatch();

  const notifications = useSelector(notificationsSelectors.notifications);

  const [propertyRequest, setPropertyRequest] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [actionType, setActionType] = useState('');

  const formik = useFormik({
    initialValues: {
      id,
      type: propertyRequest.type || '',
      propertyType: propertyRequest.property_type || '',
      status: propertyRequest.status || '',
      contact: propertyRequest.contact || '',
      is_archived: !!propertyRequest.is_archived,
      purpose: propertyRequest.purpose || '',
      category: propertyRequest.category || '',
      note: propertyRequest.note || '',
      closing_reason: propertyRequest.closing_reason || '',
      actioned_by: propertyRequest.actioned_by || '',
      districts: propertyRequest.districts || [],
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    tPropertyRequestServices.getPropertyRequest(id).then((response) => {
      setPropertyRequest(response.data.data);
    });
  }, [showConfirmationModal]);

  useEffect(() => {
    const isUnseen = hasPropertyRequestNotification(id, notifications || []);
    const notification = findPropertyRequestNotification(id, notifications || []);

    if (notification && isUnseen && !isNotificationManuallyControlled(notification)) {
      dispatch(
        notificationsActions.markAsReadRequest({
          id: notification.id,
        })
      );
    }
  }, [id, notifications]);

  const createdAt = propertyRequest?.created_at || new Date();

  return (
    <Page title={translate('request.editPageTitle')}>
      <ConfirmationModal
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onSubmit={() => setShowConfirmationModal(false)}
        actionType={actionType}
        requestId={id}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ m: 2, mb: 0 }}>
          <InfoBox
            label={translate('request.editPageTitle')}
            number={propertyRequest?.id}
            date={createdAt}
            backButtonLink="/maktb/crm/requests"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'end',
                height: '100%',
                justifyContent: 'end',
              }}
            >
              {formik.values.status === 'new' && (
                <Button
                  onClick={() => {
                    setActionType('close');
                    setShowConfirmationModal(true);
                  }}
                  variant="outlined"
                  sx={{
                    color: '#999',
                    borderColor: '#999',
                    marginLeft: 2,
                    '&:hover': {
                      color: '#999',
                      borderColor: '#999',
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  {true && translate('request.closeButton')}
                  {false && <Loader />}
                </Button>
              )}

              {formik.values.status === 'new' && (
                <Button
                  onClick={() => {
                    setActionType('convert');
                    setShowConfirmationModal(true);
                  }}
                  variant="contained"
                  sx={{ color: 'white', marginLeft: 2 }}
                >
                  {true && translate('request.convertToDeal')}
                  {false && <Loader />}
                </Button>
              )}
            </Box>
          </InfoBox>
        </Grid>
      </Grid>
      <RequestForm formik={formik} />
    </Page>
  );
};

export default EditRequest;
