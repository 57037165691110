import { MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import OptionsMenu from '../../components/OptionsMenu';
import useLocales from '../../hooks/useLocales';
import MemberChangeRoleModal from './MemberChangeRoleModal';
import MemberRemoveModal from './MemberRemoveModal';

const MemberOptionsPopup = ({ member }) => {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const [showMemberRemoveModal, setShowMemberRemoveModal] = useState(false);
  const [showMemberChangeRoleModal, setShowMemberChangeRoleModal] = useState(false);

  return (
    <>
      <MemberRemoveModal
        open={showMemberRemoveModal}
        onClose={() => setShowMemberRemoveModal(false)}
        onSubmit={() => setShowMemberRemoveModal(false)}
        member={member}
      />
      <MemberChangeRoleModal
        open={showMemberChangeRoleModal}
        onClose={() => setShowMemberChangeRoleModal(false)}
        onSubmit={() => setShowMemberChangeRoleModal(false)}
        member={member}
      />
      <OptionsMenu>
        <MenuItem
          onClick={() => {
            navigate(`/team/${member.member_id}/dashboard`);
          }}
        >
          {translate('dashboard')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowMemberChangeRoleModal(true);
          }}
        >
          {translate('teams.roleChange')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowMemberRemoveModal(true);
          }}
        >
          {translate('teams.remove')}
        </MenuItem>
      </OptionsMenu>
    </>
  );
};

export default MemberOptionsPopup;
