// @mui
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
// hooks
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// components
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import * as userActions from '../../../models/user/actions';
import * as workspaceActions from '../../../models/workspace/actions';
import { selectors as workspaceSelectors } from '../../../models/workspace/reducers';
import LogoSelector from './LogoSelector';

// ----------------------------------------------------------------------

export default function WebsiteAppearance() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const dispatch = useDispatch();

  const workspaceInformationData = useSelector(workspaceSelectors.workspaceInformationData);

  useEffect(() => {
    dispatch(workspaceActions.getWorkspaceInformationRequest());
    dispatch(userActions.getUserInformationRequest());
  }, []);

  const callback = (type) => {
    if (type === 'success') {
      toast('Success!', { type: 'success' });
      dispatch(workspaceActions.getWorkspaceInformationRequest());
      dispatch(userActions.getUserWorkspacesRequest());
      dispatch(userActions.getUserInformationRequest());
    } else {
      toast('Error!', { type: 'error' });
    }
  };

  const formik = useFormik({
    onSubmit: (values) => {
      dispatch(workspaceActions.editWorkspaceInformationRequest({ ...values, callback }));
    },
    initialValues: {
      logo_url: workspaceInformationData?.logo_url || '',
      primary_color_hex: workspaceInformationData?.primary_color_hex || '',
      secondary_color_hex: workspaceInformationData?.secondary_color_hex || '',
      is_dark_mode_enabled: workspaceInformationData?.is_dark_mode_enabled || false,
    },
    enableReinitialize: true,
  });

  //
  return (
    <Page title="Website Appearance">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h3" component="h1" paragraph>
          {translate('workspaceTitle')}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card>
              <CardContent>
                <form onSubmit={formik.handleSubmit}>
                  <Typography variant="h4" paragraph>
                    {translate('web.identity.logo')}
                  </Typography>
                  <Grid
                    item
                    xs={12}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <LogoSelector formik={formik} />
                    <Typography sx={{ color: '#637381', mt: 2 }}>
                      {translate(
                        'For best resolution and ratio use 500px X 500px as minimum 1:1 ratio. Only PNG and JPG files are supported.'
                      )}
                    </Typography>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container justifyContent={'center'} mt={5}>
          <Grid item xs={12} md={12}>
            <Card>
              <CardContent>
                <form onSubmit={formik.handleSubmit}>
                  <Typography variant="h4" paragraph>
                    {translate('web.identity.colors')}
                  </Typography>
                  <Grid
                    container
                    xs={12}
                    md={12}
                    lg={12}
                    alignItems={'center'}
                    justifyContent={'space-evenly'}
                    flexDirection={'row'}
                  >
                    <Grid item flexDirection={'column'} xs={4} md={4} lg={4}>
                      <Grid mb={3} item display={'flex'} flexDirection={'column'}>
                        <FormLabel id="primary-color-hex-label">
                          {`${translate('web.identity.primaryColor')} (${formik.values.primary_color_hex}`})
                        </FormLabel>
                        <TextField
                          type="color"
                          name="primary_color_hex"
                          value={formik.values.primary_color_hex}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          inputProps={{
                            style: { backgroundColor: formik.values.primary_color_hex, borderRadius: '7px' },
                          }}
                          required
                        />
                      </Grid>
                      <Grid item display={'flex'} flexDirection={'column'}>
                        <FormLabel id="secondary-color-hex-label">
                          {`${translate('web.identity.secondaryColor')} (${formik.values.secondary_color_hex}`})
                        </FormLabel>
                        <TextField
                          type="color"
                          name="secondary_color_hex"
                          value={formik.values.secondary_color_hex}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="#000000"
                          inputProps={{
                            style: { backgroundColor: formik.values.secondary_color_hex, borderRadius: '7px' },
                          }}
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid item mt={3} flexDirection={'column'} xs={4} md={4} lg={4}>
                      <Grid item xs={12} display={'flex'} flexDirection={'column'}>
                        <Button
                          style={{
                            backgroundColor: formik.values.primary_color_hex,
                            color: formik.values.secondary_color_hex,
                            marginBottom: '15px',
                            boxShadow: `0px 4px 6px ${formik.values.primary_color_hex}4A`,
                            '&:hover': {
                              backgroundColor: formik.values.secondary_color_hex,
                            },
                            '&:active': {
                              backgroundColor: formik.values.secondary_color_hex,
                            },
                            '&:focus': {
                              backgroundColor: formik.values.secondary_color_hex,
                            },
                          }}
                          variant="contained"
                        >
                          {translate('web.identity.exampleButton')}
                        </Button>

                        <Button
                          sx={{
                            border: `1px solid ${formik.values.primary_color_hex}`,
                            color: formik.values.primary_color_hex,
                            marginBottom: '15px',
                            '&:hover': {
                              backgroundColor: `${formik.values.primary_color_hex}1A`,
                              border: `1px solid ${formik.values.primary_color_hex}`,
                            },
                          }}
                          variant="outlined"
                        >
                          {translate('web.identity.exampleButtonSecondary')}
                        </Button>

                        <Button
                          sx={{
                            color: formik.values.primary_color_hex,
                            '&:hover': {
                              backgroundColor: `${formik.values.primary_color_hex}1A`,
                            },
                          }}
                          variant="text"
                        >
                          {translate('web.identity.exampleButtonTertiary')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Typography variant="h4" paragraph mt={5}>
                    {translate('web.identity.darkTheme')}
                  </Typography>
                  <Grid container alignItems={'center'} justifyContent={'start'}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.is_dark_mode_enabled}
                          onChange={formik.handleChange}
                          name="is_dark_mode_enabled"
                          color="primary"
                        />
                      }
                      label={translate('web.identity.enableDarkTheme')}
                    />
                  </Grid>
                  <Grid container alignItems={'center'} justifyContent={'end'} mt={5}>
                    <Button
                      disabled={!formik.dirty}
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ color: 'white' }}
                    >
                      {translate('workspace.save')}
                    </Button>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
