import { useTranslation } from 'react-i18next';

import { allLangs, defaultLang } from '../config';
import useSettings from './useSettings';

const arabicNums = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];

export function convertToArabicNumbers(str) {
  return str.replace(/\d/g, (digit) => arabicNums[parseInt(digit, 10)]);
}

export function processedTranslation(text) {
  const arabicPattern = /[\u0600-\u06FF]/;

  if (arabicPattern.test(text)) {
    return convertToArabicNumbers(text);
  }

  return text;
}

export default function useLocales() {
  const { i18n, t } = useTranslation();
  const { onChangeDirectionByLang } = useSettings();

  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = allLangs.find((lang) => lang.value === langStorage) || defaultLang;

  const handleChangeLanguage = (newLang) => {
    i18n.changeLanguage(newLang);
    onChangeDirectionByLang(newLang);
  };

  const translate = (text, options) => {
    const translatedText = t(text, options);
    return translatedText;
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLangs,
  };
}
