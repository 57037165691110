import { Box, Pagination as MUIPagination, PaginationItem } from '@mui/material';
import React from 'react';

const Pagination = ({ meta, onPageChange }) => {
  if (!meta) return null;

  const { current_page: currentPage, last_page: lastPage } = meta;

  const handlePageChange = (event, value) => {
    onPageChange(value);
  };

  return (
    <Box display="flex" justifyContent="center" p={2}>
      <MUIPagination
        count={lastPage}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        showFirstButton
        showLastButton
        renderItem={(item) => <PaginationItem component="button" onClick={() => onPageChange(item.page)} {...item} />}
      />
    </Box>
  );
};

export default Pagination;
