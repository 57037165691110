// @mui
import { Box, TextField, Typography } from '@mui/material';

// hooks
import useLocales from '../../../hooks/useLocales';

export const MobileNumber = ({ formik, disabled = false }) => {
  const { translate } = useLocales();

  return (
    <Box display="flex" flexDirection="row" sx={{ marginTop: '30px' }} style={{ direction: 'ltr' }}>
      <Box sx={{ marginInlineStart: '5px', maxWidth: '110px' }}>
        <TextField
          id="code"
          name="code"
          type="code"
          label={translate('auth.login.countryCode')}
          variant="outlined"
          value={'+966'}
          disabled={disabled}
        />
      </Box>
      <Box sx={{ marginInlineStart: '5px', width: '100%' }}>
        <TextField
          id="phone"
          name="phone"
          type="phone"
          label={translate('auth.login.mobileNumber')}
          variant="outlined"
          fullWidth
          inputProps={{ maxLength: 9 }}
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          disabled={disabled}
        />
        <Box display="flex" flexDirection="row" sx={{ marginTop: '5px' }}>
          <Typography variant="caption" sx={{ marginLeft: '2px' }}>
            {translate('auth.login.mobileExample')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
