// @mui
import { Box, Button, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

// store
import AuthButton from '../../../components/buttons/AuthButton';
import { Loader } from '../../../components/Loader';
// hooks
import useLocales from '../../../hooks/useLocales';
// components
import AuthBaseLayout from '../../../layouts/AuthBaseLayout';
import * as authActions from '../../../models/auth/actions';
import { disableButton } from '../Auth.utils';
import { Password } from '../components';

const validationSchema = Yup.object({
  password: Yup.string().required('Required'),
});

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);

  const token = state?.token;

  const handleCallback = (type, res) => {
    setLoading(false);
    if (type === 'success') {
      toast.success(translate('auth.passwordReset.success'));
      navigate('/login');
    } else {
      toast.error(translate(`resetPasswordScreen.${res.message}`));
    }
  };

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const { password } = values;
      setLoading(true);
      dispatch(authActions.resetPasswordRequest({ password, token, callback: handleCallback }));
    },
  });

  const isButtonDisabled = disableButton(['password'], formik) || loading;

  return (
    <AuthBaseLayout
      heading={translate('auth.passwordReset.heading')}
      subtitle={translate('auth.passwordReset.subtitle')}
    >
      <form onSubmit={formik.handleSubmit}>
        <Password formik={formik} />

        <AuthButton text={translate('auth.passwordReset.changePasswordBtn')} isDisabled={isButtonDisabled} />

        {loading && <Loader />}
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" sx={{ marginTop: '30px' }}>
          <Button variant="text" onClick={() => navigate(-1)}>
            <Typography variant="caption">{translate('auth.verifyCode.back')}</Typography>
          </Button>
        </Box>
      </form>
    </AuthBaseLayout>
  );
};

export default ResetPassword;
