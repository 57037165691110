import { Box, Card, CardContent } from '@mui/material';
// @mui
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import QuickSearchToolbar from '../../components/QuickSearchToolbar';
import useLocales from '../../hooks/useLocales';
import TRequests from '../../models/t-property-requests/services';
import { requestsColumns } from '../requests/RequestsView';

export default function ContactPropertyRequestsList({ contactId }) {
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const navigate = useNavigate();

  const [tServices, setTServices] = useState([]);

  const [queryParams, setQueryParams] = useState({
    tenant_contact_id: contactId,
  });

  useEffect(() => {
    setQueryParams({ ...queryParams, tenant_contact_id: contactId });
  }, [contactId]);

  useEffect(() => {
    if (queryParams.tenant_contact_id) {
      TRequests.getPropertyRequests(queryParams).then((res) => {
        setTServices(res.data.data);
      });
    }
  }, [queryParams]);

  const handleRow = (params) => {
    navigate(`/maktb/crm/requests/${params.row.id}`);
  };

  const columns = requestsColumns(translate, languageCode);

  return (
    <Card>
      <CardContent sx={{ p: 0 }}>
        <Box sx={{ mt: 2 }}>
          <DataGrid
            onRowClick={handleRow}
            getRowClassName={`clickable`}
            loading={false}
            columns={columns}
            rows={tServices || []}
            disableColumnFilter
            disableColumnSelector
            components={{
              Toolbar: QuickSearchToolbar,
            }}
            hideFooterSelectedRowCount
            disableSelectionOnClick
            rowsPerPageOptions={[10]}
            pageSize={25}
            autoHeight
            sx={{
              '& .datagrid-header': {
                backgroundColor: '#F4F6F8',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderRadius: 0,
              },
              border: 'none',
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
