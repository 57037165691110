import { Box, Button, Dialog, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';

import useLocales from '../../hooks/useLocales';

const EditNoteModal = ({ open, onClose, note, onSubmit }) => {
  const { translate } = useLocales();
  const formik = useFormik({
    initialValues: {
      note: note?.content || '',
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    if (note) {
      formik.setValues({ note: note.content });
    }
  }, [note]);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ minHeight: 100, minWidth: 400, p: 2 }}>
        <Typography variant="h6">{translate('notes.editNote')}</Typography>
        <TextField
          name="note"
          type="text"
          multiline
          autoFocus
          rows={4}
          fullWidth
          value={formik.values.note}
          onChange={formik.handleChange}
          sx={{ mt: 2, mb: 2 }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="outlined" sx={{ color: '#212B36', borderColor: '#919eab52' }} onClick={onClose}>
            {translate('notes.cancelButton')}
          </Button>
          <Button onClick={formik.submitForm} variant="contained" color="primary" sx={{ color: 'white', ml: 2 }}>
            {translate('notes.editSave')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default EditNoteModal;
