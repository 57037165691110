// @mui
import { Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useLocales from '../../../hooks/useLocales';
// hooks
import useSettings from '../../../hooks/useSettings';
import * as userActions from '../../../models/user/actions';
import ConfirmationDialog from './ConfirmationDialog';
// components
import { ListItem, WorkspaceButton } from './Invitations.element';
import WorkspaceStatusButton from './WorkspaceStatusButton';
// ----------------------------------------------------------------------

export default function InvitationListItem({ invitation }) {
  const { themeDirection } = useSettings();
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showDialog, setShowDialog] = useState(false);
  const [actionType, setActionType] = useState('');

  const isArabic = themeDirection === 'rtl';

  const {
    workspace: { name_ar: workspaceNameAr, name_en: workspaceNameEn },
    status,
    id,
  } = invitation;

  const onAcceptClickHandler = () => {
    setActionType('accept');
    setShowDialog(true);
  };

  const onDeclineClickHandler = () => {
    setActionType('decline');
    setShowDialog(true);
  };

  const previousWorkspaces = JSON.parse(localStorage.getItem('workspaces')) || [];

  const invitationAccepted = () => {
    dispatch(
      userActions.getUserInformationRequest({
        callback: (type) => {
          if (type === 'success') {
            const workspaces = JSON.parse(localStorage.getItem('workspaces')) || [];
            const newWorkspace = workspaces.find(
              (workspace) => !previousWorkspaces.some((prevWorkspace) => prevWorkspace.id === workspace.id)
            );
            const index = workspaces.findIndex((workspace) => workspace.id === newWorkspace.id);

            dispatch(
              userActions.setDefaultWorkspaceRequest({
                id: newWorkspace.id,
                callback: (type) => {
                  if (type === 'success') {
                    localStorage.setItem('tenant_id', newWorkspace.id);
                    localStorage.setItem('tenant_url', newWorkspace.domain);
                    localStorage.setItem('tenant_role', newWorkspace.company_role.role_id);
                    localStorage.setItem('tenant_index', index);
                    navigate('/');
                    window.location.reload(true);
                  }
                },
              })
            );
          }
        },
      })
    );
  };

  const confirmInvitationAction = () => {
    const getUserInvitations = () => {
      dispatch(userActions.getUserInvitationsRequest());
    };
    if (actionType === 'accept') {
      dispatch(
        userActions.acceptInvitationRequest({
          id,
          callback: (type) => {
            if (type === 'success') {
              invitationAccepted();
            }
          },
        })
      );
    } else {
      dispatch(userActions.declineInvitationRequest({ id, callback: () => getUserInvitations() }));
    }
    setShowDialog(false);
  };

  return (
    <ListItem>
      <Grid container sx={{ display: 'flex' }}>
        <Grid item xs={6.5} lg={6} xl={9} sx={{ display: 'flex', alignItems: 'center' }}>
          {/* <Avatar
            src="https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_5.jpg"
            alt="Rayan Moran"
          /> */}
          <Typography sx={{ marginLeft: '1%', minWidth: '25%', fontSize: '0.8rem' }}>
            {isArabic ? workspaceNameAr : workspaceNameEn}
          </Typography>
          <WorkspaceStatusButton status={status} />
        </Grid>
        {status === 'pending' && (
          <Grid item xs={5.5} lg={6} xl={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
              <WorkspaceButton onClick={onDeclineClickHandler} sx={{ marginRight: '5%' }} variant="outlined">
                {translate('invitation.decline')}
              </WorkspaceButton>
              <WorkspaceButton onClick={onAcceptClickHandler} variant="contained">
                {translate('invitation.accept')}
              </WorkspaceButton>
            </Box>
          </Grid>
        )}
      </Grid>

      {showDialog && (
        <ConfirmationDialog
          workspace={isArabic ? workspaceNameAr : workspaceNameEn}
          onClose={() => setShowDialog(false)}
          onConfirm={() => confirmInvitationAction()}
          actionType={actionType}
        />
      )}
    </ListItem>
  );
}
