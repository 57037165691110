import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import useLocales from '../../../hooks/useLocales';
import BankAccountService from '../../../models/t-bank-accounts/services';
import UtilService from '../../../models/utils/services';

export const addBankAccountSchema = Yup.object({
  bank_id: Yup.string().required('Required'),
  beneficiary_name_ar: Yup.string().required('Required'),
  beneficiary_name_en: Yup.string().required('Required'),
  account_number: Yup.string().required('Required'),
  iban: Yup.string().required('Required'),
});

const AddBankAccountModal = ({ open, onClose }) => {
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();
  const [loading, setLoading] = useState(false);

  const [banks, setBanks] = useState([]);

  useEffect(() => {
    UtilService.getBanks().then((res) => {
      setBanks(res.data.data);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      beneficiary_name_ar: '',
      beneficiary_name_en: '',
      account_number: '',
      iban: '',
      swift: '',
      bank_id: '',
    },
    validationSchema: addBankAccountSchema,
    isInitialValid: false,
    onSubmit: (values) => {
      setLoading(true);
      BankAccountService.createBankAccount(values).then((res) => {
        setLoading(false);
        if (res.status === 201) {
          toast.success(translate('setting.paymentSetting.bankAccountCreated'));
          formik.resetForm();
          onClose();
        }
      });
    },
  });

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
        <Typography sx={{ p: 2, mb: 4 }} variant="h5">
          {translate('setting.paymentSetting.addBankAccount')}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <button type="submit" style={{ display: 'none' }}>
            Submit
          </button>

          <Grid item xs={12}>
            <FormControl sx={{ mb: 2 }} fullWidth error={formik.touched.bank_id && formik.errors.bank_id}>
              <InputLabel id="bank-label" shrink>
                {translate('setting.paymentSetting.bank')}
              </InputLabel>
              <Select
                name="bank_id"
                labelId="bank-select"
                label={translate('setting.paymentSetting.bank')}
                onChange={formik.handleChange}
                value={formik.values.bank_id}
                input={<OutlinedInput notched name="bank_id" label={translate('setting.paymentSetting.bank')} />}
              >
                {banks?.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {languageCode === 'ar' ? c.name_ar : c.name_en}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              name="beneficiary_name_ar"
              type="beneficiary_name_ar"
              label={translate('setting.paymentSetting.beneficiaryNameAr')}
              error={formik.touched.beneficiary_name_ar && formik.errors.beneficiary_name_ar}
              value={formik.values.beneficiary_name_ar}
              onChange={formik.handleChange}
              sx={{ mb: 2 }}
              fullWidth
            />
          </Grid>
          <TextField
            name="beneficiary_name_en"
            type="beneficiary_name_en"
            label={translate('setting.paymentSetting.beneficiaryNameEn')}
            error={formik.touched.beneficiary_name_en && formik.errors.beneficiary_name_en}
            value={formik.values.beneficiary_name_en}
            onChange={formik.handleChange}
            sx={{ mb: 2 }}
            fullWidth
          />
          <TextField
            name="account_number"
            type="account_number"
            label={translate('setting.paymentSetting.accountNumber')}
            error={formik.touched.account_number && formik.errors.account_number}
            value={formik.values.account_number}
            onChange={formik.handleChange}
            sx={{ mb: 2 }}
            fullWidth
          />
          <TextField
            name="iban"
            type="iban"
            label={translate('setting.paymentSetting.iban')}
            error={formik.touched.iban && formik.errors.iban}
            value={formik.values.iban}
            onChange={formik.handleChange}
            sx={{ mb: 2 }}
            fullWidth
          />
          <TextField
            name="swift"
            type="swift"
            label={translate('setting.paymentSetting.swift')}
            error={formik.touched.swift && formik.errors.swift}
            value={formik.values.swift}
            onChange={formik.handleChange}
            sx={{ mb: 2 }}
            fullWidth
          />
        </form>

        <Divider sx={{ mt: 2 }} />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <Button variant="outlined" sx={{ color: 'black', borderColor: '#919EAB66', mr: 2 }} onClick={onClose}>
            {translate('setting.paymentSetting.cancelButton')}
          </Button>
          <Button
            disabled={!formik.isValid || loading}
            variant="contained"
            sx={{ color: 'white' }}
            onClick={formik.submitForm}
          >
            {loading ? (
              <CircularProgress size={15} color={'secondary'} />
            ) : (
              translate('setting.paymentSetting.createButton')
            )}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddBankAccountModal;
