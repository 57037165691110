// src/GoogleAnalytics.js

import { useEffect } from 'react';

const GoogleAnalytics = ({ measurementId }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(...args) {
        window.dataLayer.push(...args);
      }
      gtag('js', new Date());
      gtag('config', measurementId);
    };

    return () => {
      document.head.removeChild(script);
    };
  }, [measurementId]);

  return null;
};

export default GoogleAnalytics;
