import { createAction } from '@reduxjs/toolkit';

export const getWorkspaceInformationRequest = createAction('GET_WORKSPACE_INFORMATION_REQUEST');

export const getWorkspaceInformationSuccess = createAction('GET_WORKSPACE_INFORMATION_SUCCESS');

export const getWorkspaceInformationFailure = createAction('GET_WORKSPACE_INFORMATION_FAILURE');

export const editWorkspaceInformationRequest = createAction('EDIT_WORKSPACE_INFORMATION_REQUEST');

export const editWorkspaceInformationSuccess = createAction('EDIT_WORKSPACE_INFORMATION_SUCCESS');

export const editWorkspaceInformationFailure = createAction('EDIT_WORKSPACE_INFORMATION_FAILURE');

export const getTermsRequest = createAction('GET_TERMS_REQUEST');
export const getTermsSuccess = createAction('GET_TERMS_SUCCESS');
export const getTermsFailure = createAction('GET_TERMS_FAILURE');

export const getPrivacyRequest = createAction('GET_PRIVACY_REQUEST');
export const getPrivacySuccess = createAction('GET_PRIVACY_SUCCESS');
export const getPrivacyFailure = createAction('GET_PRIVACY_FAILURE');

export const editTermsRequest = createAction('EDIT_TERMS_REQUEST');
export const editTermsSuccess = createAction('EDIT_TERMS_SUCCESS');
export const editTermsFailure = createAction('EDIT_TERMS_FAILURE');

export const editPrivacyRequest = createAction('EDIT_PRIVACY_REQUEST');
export const editPrivacySuccess = createAction('EDIT_PRIVACY_SUCCESS');
export const editPrivacyFailure = createAction('EDIT_PRIVACY_FAILURE');
