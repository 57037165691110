import { Box, Button, CircularProgress, Container, Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import InfoBox from '../../components/InfoBox';
import { Loader } from '../../components/Loader';
import Page from '../../components/Page';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import * as dealsActions from '../../models/deals/actions';
import { selectors as dealsSelectors } from '../../models/deals/reducers';
import * as notesActions from '../../models/notes/actions';
import * as notificationsActions from '../../models/notifications/actions';
import { selectors as notificationsSelectors } from '../../models/notifications/reducers';
import {
  findDealNotification,
  hasDealNotification,
  isNotificationManuallyControlled,
} from '../../models/notifications/utils';
import { FACADE_DIRECTIONS } from '../../utils/constants';
import Notes from '../notes/Notes';
import DealForm from './DealForm';

const dealEditSchema = Yup.object({
  rent_period: Yup.string().oneOf(['daily', 'weekly', 'monthly', 'quarterly', 'semi_annually', 'annually']),
  min_price: Yup.number().positive(),
  max_price: Yup.number().positive().moreThan(Yup.ref('min_price'), 'Max price must be more than Min price.'),
  facade: Yup.string().oneOf(FACADE_DIRECTIONS),
  amount: Yup.string().matches(/^\d+(\.\d{0,2})?$/, {
    message: 'Please enter a valid number format (e.g., 25000.34)',
    excludeEmptyString: true,
  }),
  commission: Yup.string().matches(/^\d+(\.\d{0,2})?$/, {
    message: 'Please enter a valid number format (e.g., 25000.34)',
    excludeEmptyString: true,
  }),
  min_area: Yup.number(),
  max_area: Yup.number().moreThan(Yup.ref('min_area'), 'Max area must be more than Min area.'),
  bedrooms: Yup.number().integer(),
  bathrooms: Yup.number().integer(),
  country_id: Yup.number(),
  city_id: Yup.number(),
  districts_ids: Yup.array().of(Yup.number()),
});

const EditDeal = () => {
  const { translate } = useLocales();
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { id } = useParams();
  const deal = useSelector(dealsSelectors.deal);
  const updateLoading = useSelector(dealsSelectors.updateLoading);
  const loading = useSelector(dealsSelectors.loading);
  const notifications = useSelector(notificationsSelectors.notifications);

  const createdAt = deal?.created_at || new Date();

  useEffect(() => {
    dispatch(dealsActions.getDealByIdRequest(id));
  }, [id]);

  useEffect(() => {
    const isUnseen = hasDealNotification(id, notifications || []);
    const notification = findDealNotification(id, notifications || []);

    if (notification && isUnseen && !isNotificationManuallyControlled(notification)) {
      dispatch(
        notificationsActions.markAsReadRequest({
          id: notification.id,
        })
      );
    }
  }, [id, notifications]);

  const callback = (type, error) => {
    if (type === 'success') {
      toast(translate('Successfully updated'), { type: 'success', autoClose: 700 });
      dispatch(notesActions.getNotesRequest({ type: 'deal', id, product: 'office' }));
    } else {
      toast('Failed to update', { type: 'error' });
    }
  };

  const toggleArchive = () => () => {
    dispatch(dealsActions.toggleArchiveDealRequest({ id: deal.id, callback }));
  };

  const dealFormik = useFormik({
    initialValues: {
      client: deal?.contact?.name || '',
      contact_id: deal?.contact?.id || '',
      mobile_number: deal?.contact?.mobile_number || '',
      purpose: deal?.purpose || '',
      category: deal?.category || '',
      type: deal?.type || '',
      stage: deal?.stage || '',
      sales_person_id: deal?.sales_person?.id || '',
      sales_person: deal?.sales_person?.name || '',
      rent_period: deal?.rent_period || 'daily',
      min_price: deal?.min_price || '',
      max_price: deal?.max_price || '',
      amount: deal?.amount || '',
      commission: deal?.commission || '',
      facade: deal?.facade || '',
      min_area: deal?.min_area || '',
      max_area: deal?.max_area || '',
      bedrooms: deal?.bedrooms || 0,
      bathrooms: deal?.bathrooms || 0,
      country_id: deal?.country?.id || '',
      city_id: deal?.city?.id || '',
      districts: deal?.districts || [],
      districts_ids: deal?.districts?.map((d) => d.id) || [],
      property: deal?.property || '',
    },
    isInitialValid: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      const { property, ...rest } = values;
      dispatch(
        dealsActions.editDealRequest({
          id: deal.id,
          property_id: property?.id,
          ...rest,
          callback,
        })
      );
    },
    validationSchema: dealEditSchema,
  });

  return (
    <Page title={translate('deals')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InfoBox
              label={translate('deal.information')}
              number={deal?.id}
              date={createdAt}
              backButtonLink="/maktb/crm/deals"
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'end',
                  height: '100%',
                  justifyContent: 'end',
                }}
              >
                <Button
                  onClick={toggleArchive()}
                  variant="outlined"
                  sx={{
                    color: '#999',
                    borderColor: '#999',
                    marginLeft: 2,
                    '&:hover': {
                      color: '#999',
                      borderColor: '#999',
                      backgroundColor: 'transparent',
                    },
                  }}
                  disabled={updateLoading}
                >
                  {!updateLoading && translate(deal?.is_archived ? 'deal.unarchiveButton' : 'deal.archiveButton')}
                  {updateLoading && <Loader />}
                </Button>

                <Button
                  onClick={dealFormik.submitForm}
                  variant="contained"
                  sx={{ color: 'white', marginLeft: 2 }}
                  disabled={updateLoading || !dealFormik.dirty}
                >
                  {!updateLoading && translate('deal.saveButton')}
                  {updateLoading && <Loader />}
                </Button>
              </Box>
            </InfoBox>
          </Grid>
        </Grid>

        {loading && (
          <Box
            sx={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              height: 200,
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {!loading && (
          <Grid container spacing={2}>
            <DealForm formik={dealFormik} />
            <Grid item xs={12} lg={4}>
              <Notes id={id} type={'deal'} />
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
};

export default EditDeal;
