// @mui
import { Card, CardContent, CardHeader, Container, Grid, Typography } from '@mui/material';
import { useMemo } from 'react';
// hooks
import { useSelector } from 'react-redux';

// components
import Page from '../../components/Page';
import useDeferredState from '../../hooks/useDeferredState';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import { selectors as userSelectors } from '../../models/user/reducers';
import UtilsServices from '../../models/utils/services';
import ChartDonut from './ChartDonut';
import ChartLine from './ChartLine';

// ----------------------------------------------------------------------

export default function Dashboard() {
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const userInfo = useSelector(userSelectors.me);

  const [clientsCount, clientsLoading] = useDeferredState(
    async () => UtilsServices.getTotalClients({ is_office: 1 }).then((r) => r.data),
    []
  );

  const [dealsCount, dealsLoading] = useDeferredState(
    async () => UtilsServices.getTotalDeals().then((r) => r.data),
    []
  );

  const [propertiesCount, propertiesLoading] = useDeferredState(
    async () => UtilsServices.getTotalProperties({ is_office: 1 }).then((r) => r.data),
    []
  );

  const [propertiesGrowth] = useDeferredState(
    async () => UtilsServices.getPropertiesGrowth({ is_office: 1 }).then((r) => r.data),
    [],
    []
  );

  const [listingsGrowthViews] = useDeferredState(
    async () => UtilsServices.getListingsGrowthViews().then((r) => r.data),
    [],
    []
  );

  const [dealsGrowth] = useDeferredState(async () => UtilsServices.getDealsGrowth().then((r) => r.data), [], []);

  const [clientsGrowth] = useDeferredState(
    async () => UtilsServices.getClientsGrowth({ is_office: 1 }).then((r) => r.data),
    [],
    []
  );

  const [dealsStats] = useDeferredState(async () => UtilsServices.getDealStages().then((r) => r.data), [], []);

  const lineSeries = useMemo(
    () => [
      {
        name: translate('clients'),
        data: Object.values(clientsGrowth),
        color: '#8165AF',
      },

      {
        name: translate('units'),
        data: Object.values(propertiesGrowth),
        color: '#EF9925',
      },

      {
        name: translate('deals'),
        data: Object.values(dealsGrowth),
        color: '#1FC6AC',
      },
    ],
    [translate, clientsGrowth, propertiesGrowth, dealsGrowth]
  );

  const viewsLineSeries = useMemo(
    () => [
      {
        name: translate('listingsViews'),
        data: Object.values(listingsGrowthViews),
        color: '#1FC6AC',
      },
    ],
    [listingsGrowthViews, translate]
  );

  const donutSeries = Object.values(dealsStats);

  return (
    <Page title={translate('dashboard')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          {translate('hi')} {userInfo?.name}! {languageCode === 'ar' ? '' : translate('Welcome back')} 👋🏼
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card dir="ltr">
              <CardHeader title={`${translate('listingsViews')}`} dir={languageCode === 'ar' ? 'rtl' : 'ltr'} />
              <CardContent
                sx={{
                  height: 420,
                }}
              >
                <ChartLine series={viewsLineSeries} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                py: 5,
                textAlign: 'center',
              }}
            >
              <Typography variant="h3">{dealsLoading ? '-' : dealsCount || 0}</Typography>

              <Typography variant="subtitle2" sx={{ opacity: 0.64 }}>
                {translate('deals')}
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                py: 5,
                textAlign: 'center',
              }}
            >
              <Typography variant="h3">{propertiesLoading ? '-' : propertiesCount || 0}</Typography>

              <Typography variant="subtitle2" sx={{ opacity: 0.64 }}>
                {translate('units')}
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                py: 5,
                textAlign: 'center',
              }}
            >
              <Typography variant="h3">{clientsLoading ? '-' : clientsCount || 0}</Typography>

              <Typography variant="subtitle2" sx={{ opacity: 0.64 }}>
                {translate('clients')}
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card dir="ltr">
              <CardHeader title={`${translate('growth')} 2024`} dir={languageCode === 'ar' ? 'rtl' : 'ltr'} />
              <CardContent
                sx={{
                  height: 420,
                }}
              >
                <ChartLine series={lineSeries} dir={languageCode === 'ar' ? 'rtl' : 'ltr'} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card dir="ltr">
              <CardHeader title={translate('deals')} dir={languageCode === 'ar' ? 'rtl' : 'ltr'} />
              <CardContent
                sx={{
                  height: 420,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ChartDonut series={donutSeries} dir={languageCode === 'ar' ? 'rtl' : 'ltr'} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
