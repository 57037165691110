import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
  loading: false,
  products: null,
};

const reducer = createReducer(initialState, {
  [actions.getProductsRequest]: (state) => {
    console.log('products requested');
    state.loading = true;
  },
  [actions.getProductsSuccess]: (state, action) => {
    console.log('products success');
    state.loading = false;
    state.products = action.payload.data;
  },
  [actions.getProductsFailure]: (state, action) => {
    console.log('products error');
    state.loading = false;
  },
});

const loading = (state) => state.products.loading;
const products = (state) => state.products.products;

const selectors = {
  loading,
  products,
};

export { initialState, reducer, selectors };
export default reducer;
