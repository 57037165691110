import { Box, Button, Card, CardContent, Container, Divider, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useFormik } from 'formik';
import parsePhoneNumber from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import DealsList from '../../components/DealsList';
import InfoBox from '../../components/InfoBox';
import { Loader } from '../../components/Loader';
import Page from '../../components/Page';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import * as contactActions from '../../models/contacts/actions';
import { selectors as contactSelectors } from '../../models/contacts/reducers';
import { selectors as subscriptionSelectors } from '../../models/subscription/reducers';
import Notes, { TabLabel } from '../notes/Notes';
import ContactForm from './ContactForm';
import ContactOwnedPropertiesList from './ContactOwnedPropertiesList';
import ContactPropertyRequestsList from './ContactPropertyRequestsList';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 0,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const EditContact = () => {
  const { translate } = useLocales();
  const { themeStretch } = useSettings();
  const { id } = useParams();
  const dispatch = useDispatch();
  const contact = useSelector(contactSelectors.contact);
  const [loading, setLoading] = useState(false);
  const [tabIdx, setTabIdx] = useState(0);

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');

  /* looks ugly, but without a rerender is better */
  let code = '';
  let phone = '';

  if (contact) {
    const parsedPhoneNumber = parsePhoneNumber(`+${contact.mobile_number}`);
    code = parsedPhoneNumber?.countryCallingCode || '';
    phone = parsedPhoneNumber?.nationalNumber || '';
  }
  const callback = (type) => {
    if (type === 'success') {
      toast.success(translate('addContactScreen.successUpdate'));
      setLoading(false);
    } else {
      toast(translate('Error'), { type: 'error' });
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      phone,
      code,
      name: contact?.name || '',
      gender: contact?.gender || '',
      email: contact?.email || '',
      is_property_owner: !!contact?.is_property_owner,
      is_property_buyer: !!contact?.is_property_buyer,
      is_property_renter: !!contact?.is_property_renter,
      is_property_broker: !!contact?.is_property_broker,
      is_property_agent: !!contact?.is_property_agent,
      is_property_representative: !!contact?.is_property_representative,
      note: contact?.note || '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      const { ...rest } = values;
      dispatch(
        contactActions.editContactRequest({
          id: contact.id,
          callback,
          values: rest,
        })
      );
    },
  });

  useEffect(() => {
    dispatch(contactActions.getContactByIdRequest(id));
  }, []);

  const toggleArchive = () => () => {
    dispatch(contactActions.toggleArchiveContactRequest({ id: contact.id, callback }));
  };

  const handleTabChange = (evt, value) => {
    setTabIdx(value);
  };

  const createdAt = contact?.created_at || new Date();

  return (
    <Page title={translate('contact.information')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InfoBox
              label={translate('contact.information')}
              number={contact?.id}
              date={createdAt}
              backButtonLink="/maktb/crm/contacts"
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'end',
                  height: '100%',
                  justifyContent: 'end',
                }}
              >
                <Button
                  onClick={toggleArchive()}
                  variant="outlined"
                  sx={{
                    color: '#999',
                    borderColor: '#999',
                    marginLeft: 2,
                    '&:hover': {
                      color: '#999',
                      borderColor: '#999',
                      backgroundColor: 'transparent',
                    },
                  }}
                  disabled={loading}
                >
                  {!loading && translate(contact?.is_archived ? 'contact.unarchiveButton' : 'contact.archiveButton')}
                  {loading && <Loader />}
                </Button>
                <Button
                  variant="contained"
                  sx={{ color: 'white', marginLeft: 2 }}
                  onClick={formik.submitForm}
                  disabled={loading || !formik.dirty}
                >
                  {!loading && translate('contact.saveButton')}
                  {loading && <Loader />}
                </Button>
              </Box>
            </InfoBox>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <ContactForm formik={formik} phoneNumberDisabled />
          <Grid item xs={12} lg={4}>
            <Notes id={id} type={'contact'} />
          </Grid>
        </Grid>
        {!activeMaktbSubscription?.is_trial && (
          <Box>
            <Tabs value={tabIdx} onChange={handleTabChange} sx={{ pt: 2 }}>
              <Tab
                label={<TabLabel labelText={translate('deals')} badgeContent={contact?.total_deals} />}
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <TabLabel
                    labelText={translate('contact.ownedProperties')}
                    badgeContent={contact?.total_owned_properties}
                  />
                }
                {...a11yProps(1)}
              />
              <Tab
                label={<TabLabel labelText={translate('requests')} badgeContent={contact?.total_property_requests} />}
                {...a11yProps(2)}
              />
            </Tabs>

            <Divider sx={{ mb: 2 }} />

            <TabPanel value={tabIdx} index={0}>
              {contact?.total_deals > 0 ? (
                <DealsList id={contact?.id} idKey="tenant_contact_id" />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1">{translate('contact.noDeals')}</Typography>
                  </CardContent>
                </Card>
              )}
            </TabPanel>

            <TabPanel value={tabIdx} index={1}>
              {contact?.total_owned_properties > 0 ? (
                <ContactOwnedPropertiesList contactId={contact?.id} />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1">{translate('contact.noOwnedProperties')}</Typography>
                  </CardContent>
                </Card>
              )}
            </TabPanel>

            <TabPanel value={tabIdx} index={2}>
              {contact?.total_property_requests > 0 ? (
                <ContactPropertyRequestsList contactId={contact?.id} />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1">{translate('contact.noPropertyRequests')}</Typography>
                  </CardContent>
                </Card>
              )}
            </TabPanel>
          </Box>
        )}
      </Container>
    </Page>
  );
};

export default EditContact;
