import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useLocales, { convertToArabicNumbers } from '../../hooks/useLocales';
import { selectors as notificationsSelectors } from '../../models/notifications/reducers';
import { hasDealNotification } from '../../models/notifications/utils';

const BoardRow = ({ index, deal }) => {
  const {
    id,
    contact: { name },
    purpose,
    type,
    updated_at: updatedAt,
    sales_person: salesPerson,
  } = deal;

  const { currentLang, translate } = useLocales();
  const navigate = useNavigate();
  const theme = useTheme();

  const notifications = useSelector(notificationsSelectors.notifications);

  const isUnseen = hasDealNotification(id, notifications || []);
  const isAR = currentLang.value === 'ar';

  const dateFormatter = new Intl.DateTimeFormat(isAR ? 'ar' : 'en-US', {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  });

  const initialsOfSalesPerson = salesPerson ? salesPerson.name[0] : null;

  return (
    <>
      <Draggable index={index} draggableId={id.toString()}>
        {(provided) => (
          <Card
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{
              mb: 1,
              borderRadius: '8px',
              ...(isUnseen ? { backgroundColor: alpha(theme.palette.primary.main, 0.1) } : {}),
            }}
          >
            <CardContent
              sx={{
                p: 1,
                '&:last-child': {
                  paddingBottom: 1,
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {provided.placeholder}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      fontSize={12}
                      color="#000000"
                      onClick={() => {
                        navigate(`/maktb/crm/deals/${id}`);
                      }}
                    >
                      {translate(`deal.view`)}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography fontSize={12}>{name}</Typography>
                  <Typography fontSize={12} color="#B3B3B3">
                    {translate(`deal.${purpose}`)}
                  </Typography>
                </Box>
                <Box sx={{ pt: 1, pb: 1 }}>
                  <Typography fontSize={14}>
                    <b>{translate(`deal.${type}`)}</b>
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex' }}>
                    <Typography fontSize={12} color="#0000004d">
                      {isAR
                        ? convertToArabicNumbers(dateFormatter.format(Date.parse(updatedAt)))
                        : dateFormatter.format(Date.parse(updatedAt))}
                    </Typography>
                  </Box>
                  {initialsOfSalesPerson && <Avatar>{initialsOfSalesPerson}</Avatar>}
                </Box>
              </Box>
            </CardContent>
          </Card>
        )}
      </Draggable>
    </>
  );
};

export default BoardRow;
