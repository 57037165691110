import { createAction } from '@reduxjs/toolkit';

export const getNotificationsRequest = createAction('GET_NOTIFICATIONS_REQUEST');
export const getNotificationsSuccess = createAction('GET_NOTIFICATIONS_SUCCESS');
export const getNotificationsFailure = createAction('GET_NOTIFICATIONS_FAILURE');

export const markAsReadRequest = createAction('MARK_AS_READ_REQUEST');
export const markAsReadSuccess = createAction('MARK_AS_READ_SUCCESS');
export const markAsReadFailure = createAction('MARK_AS_READ_FAILURE');

export const markAllAsReadRequest = createAction('MARK_ALL_AS_READ_REQUEST');
export const markAllAsReadSuccess = createAction('MARK_ALL_AS_READ_SUCCESS');
export const markAllAsReadFailure = createAction('MARK_ALL_AS_READ_FAILURE');

export const markAsUnreadRequest = createAction('MARK_AS_UNREAD_REQUEST');
export const markAsUnreadSuccess = createAction('MARK_AS_UNREAD_SUCCESS');
export const markAsUnreadFailure = createAction('MARK_AS_UNREAD_FAILURE');

export const deleteNotificationRequest = createAction('DELETE_NOTIFICATION_REQUEST');
export const deleteNotificationSuccess = createAction('DELETE_NOTIFICATION_SUCCESS');
export const deleteNotificationFailure = createAction('DELETE_NOTIFICATION_FAILURE');

export const deleteAllNotificationsRequest = createAction('DELETE_ALL_NOTIFICATIONS_REQUEST');
export const deleteAllNotificationsSuccess = createAction('DELETE_ALL_NOTIFICATIONS_SUCCESS');
export const deleteAllNotificationsFailure = createAction('DELETE_ALL_NOTIFICATIONS_FAILURE');

export const getPaginatedNotificationsRequest = createAction('GET_PAGINATED_NOTIFICATIONS_REQUEST');
export const getPaginatedNotificationsSuccess = createAction('GET_PAGINATED_NOTIFICATIONS_SUCCESS');
export const getPaginatedNotificationsFailure = createAction('GET_PAGINATED_NOTIFICATIONS_FAILURE');
