// @mui
import { Box, Button, Card, CardContent, Container, Divider, Tab, Tabs, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// components
import Page from '../../components/Page';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import * as teamActions from '../../models/team/actions';
import { selectors as teamSelectors } from '../../models/team/reducers';
import InvitationOptionsPopup from './InvitationOptionsPopup';
import InviteMemberModal from './InviteMemberModal';
import MemberOptionsPopup from './MemberOptionsPopup';

// ----------------------------------------------------------------------
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

export default function Team() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const [tabIdx, setTabIdx] = useState(0);
  const { currentLang } = useLocales();
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const navigate = useNavigate();

  const isAR = currentLang.value === 'ar';
  const dateFormatter = new Intl.DateTimeFormat(isAR ? 'ar' : 'en-US', {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: 12,
  });

  const members = useSelector(teamSelectors.members);
  const invitations = useSelector(teamSelectors.invitations);

  useEffect(() => {
    dispatch(teamActions.getMembersRequest());
    dispatch(teamActions.getInvitationsRequest());
  }, []);

  const handleTabChange = (evt, value) => {
    setTabIdx(value);
  };

  const roleGetter = (c) => {
    if (currentLang.value === 'ar') {
      return c.row.role.name_ar;
    }

    return c.row.role.name_en;
  };

  const dateJoinedGetter = (c) => {
    const date = Date.parse(c.row.created_at);
    return date ? dateFormatter.format(date) : '';
  };

  const membersColumns = [
    {
      field: 'mobile_number',
      headerName: translate('teams.mobileNumber'),
      maxWidth: 200,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'name',
      headerName: translate('teams.name'),
      maxWidth: 200,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'role',
      headerName: translate('teams.role'),
      flex: true,
      valueGetter: roleGetter,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'created_at',
      headerName: translate('teams.dateJoined'),
      flex: true,
      valueGetter: dateJoinedGetter,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'useless',
      headerName: '',
      width: 150,
      renderCell: (c) => c?.row?.role.role_id !== 3 && <MemberOptionsPopup member={c.row} />,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const invitationsColumns = [
    {
      field: 'id',
      headerName: '#',
      maxWidth: 100,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'mobile_number',
      headerName: translate('teams.mobileNumber'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'status',
      headerName: translate('teams.status'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => translate(`teams.${row.status}`),
    },
    {
      field: 'role',
      headerName: translate('teams.role'),
      flex: true,
      valueGetter: roleGetter,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'created_at',
      headerName: translate('teams.dateSent'),
      flex: true,
      valueGetter: dateJoinedGetter,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'useless',
      headerName: '',
      width: 50,
      renderCell: (c) => (c.row.status === 'pending' ? <InvitationOptionsPopup invitation={c.row} /> : <span />),
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const handleRowClick = (params) => {
    navigate(`/team/${params.row.member_id}/dashboard`);
  };

  const handleInviteMember = () => {
    setShowInvitationModal(true);
  };

  return (
    <Page title={translate('team')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h3" component="h1">
            {translate('team')}
          </Typography>
          <Button variant="contained" sx={{ color: 'white', pt: 1, pb: 1 }} onClick={handleInviteMember}>
            {translate('member.inviteMember')}
          </Button>

          <InviteMemberModal
            open={showInvitationModal}
            onClose={() => setShowInvitationModal(false)}
            onSubmit={() => setShowInvitationModal(false)}
          />
        </Box>

        <Box
          sx={{
            '& div.MuiBox-root': {
              p: 0,
            },
          }}
        >
          <Tabs value={tabIdx} onChange={handleTabChange}>
            <Tab label={translate('member.members')} {...a11yProps(0)} />
            <Tab label={translate('member.invitations')} {...a11yProps(1)} />
          </Tabs>

          <Divider sx={{ mb: 2 }} />

          <TabPanel value={tabIdx} index={0}>
            <Card className="no-animation">
              <CardContent sx={{ p: 0 }}>
                <Box sx={{ minHeight: 300 }}>
                  <DataGrid
                    // onRowClick={handleRowClick}
                    columns={membersColumns}
                    rows={members || []}
                    hideFooterSelectedRowCount
                    disableSelectionOnClick
                    rowsPerPageOptions={[5]}
                    getRowId={(c) => c.member_id}
                    autoHeight
                    sx={{
                      '& .datagrid-header': {
                        backgroundColor: '#F4F6F8',
                      },
                      '& .MuiDataGrid-columnHeaders': {
                        borderRadius: 0,
                      },
                      border: 'none',
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </TabPanel>

          <TabPanel value={tabIdx} index={1}>
            <Card className="no-animation">
              <CardContent sx={{ p: 0 }}>
                <Box sx={{}}>
                  <DataGrid
                    columns={invitationsColumns}
                    rows={invitations || []}
                    hideFooterSelectedRowCount
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    rowsPerPageOptions={[10]}
                    pageSize={10}
                    autoHeight
                    sx={{
                      '& .datagrid-header': {
                        backgroundColor: '#F4F6F8',
                      },
                      '& .MuiDataGrid-columnHeaders': {
                        borderRadius: 0,
                      },
                      border: 'none',
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </TabPanel>
        </Box>
      </Container>
    </Page>
  );
}
