import { SvgIcon } from '@mui/material';

const SelectPhotosImage = () => (
  <SvgIcon sx={{ height: 1, width: 1 }} viewBox="0 0 161 118" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.05"
      d="M106.501 71.94C105.391 73.2393 104.192 73.2175 103.478 73.0344L103.505 72.3615C103.505 72.3615 106.457 71.0332 106.381 71.3122C106.353 71.4168 106.409 71.6683 106.501 71.94Z"
      fill="#FF4842"
    />
    <path
      opacity="0.05"
      d="M114.591 73.4109C112.706 74.4834 111.6 73.2263 111.344 72.8702C111.501 72.4618 111.739 71.7991 111.69 71.6102C111.62 71.3297 114.567 72.658 114.567 72.658L114.591 73.4109Z"
      fill="#FF4842"
    />
    <path
      opacity="0.05"
      d="M112.572 62.7102C112.572 62.7102 114.365 61.6928 114.756 62.1506C115.147 62.6084 112.572 62.7102 112.572 62.7102Z"
      fill="#FF4842"
    />
    <path
      opacity="0.05"
      d="M113.655 63.2695C113.655 63.2695 113.36 63.4149 114.011 63.6009C114.662 63.7869 113.655 63.2695 113.655 63.2695Z"
      fill="#FF4842"
    />
    <path
      opacity="0.05"
      d="M102.811 60.4716C102.75 60.489 104.303 61.5034 105.705 60.6634C105.699 60.6634 103.161 60.3669 102.811 60.4716Z"
      fill="#FF4842"
    />
    <path
      opacity="0.05"
      d="M105.629 62.4121C105.629 62.4121 105.203 63.2347 104.333 63.3568C103.462 63.4789 105.629 62.4121 105.629 62.4121Z"
      fill="#FF4842"
    />
    <path
      opacity="0.05"
      d="M111.329 64.1968C111.329 64.1968 113.371 67.3941 112.98 68.1484C112.589 68.9027 111.329 64.1968 111.329 64.1968Z"
      fill="#FF4842"
    />
    <path
      opacity="0.05"
      d="M107.298 76.9107C107.298 77.0894 107.298 77.2566 107.28 77.3946C107.227 78.0414 105.611 77.7972 105.611 77.7972C104.135 77.9992 103.603 77.3016 103.428 76.6345C103.373 76.4179 103.345 76.1952 103.347 75.9718C103.346 75.8386 103.355 75.7055 103.373 75.5736V75.4413C103.691 75.3469 104.036 75.4162 104.291 75.6259C104.948 76.1506 105.819 75.748 105.819 75.748C105.819 75.748 106.458 75.8003 106.705 76.0096C106.843 76.1273 107.072 75.8439 107.246 75.5736C107.257 75.748 107.273 76.0634 107.283 76.4005C107.295 76.5735 107.298 76.7479 107.298 76.9107Z"
      fill="#FF4842"
    />
    <path
      opacity="0.05"
      d="M104.706 76.8728C104.706 76.8728 104.263 77.3001 103.428 76.6403C103.373 76.4236 103.345 76.2009 103.347 75.9775C103.773 76.2973 104.564 76.8728 104.706 76.8728Z"
      fill="#FF4842"
    />
    <path
      opacity="0.05"
      d="M107.298 76.9105C107.225 76.9658 107.132 76.9891 107.042 76.9747C106.951 76.9604 106.87 76.9098 106.818 76.8349C106.99 76.7059 107.149 76.5598 107.291 76.3989C107.295 76.5733 107.298 76.7477 107.298 76.9105Z"
      fill="#FF4842"
    />
    <path
      opacity="0.05"
      d="M110.762 77.21C110.762 77.3902 110.762 77.5573 110.778 77.6939C110.832 78.3421 112.448 78.0965 112.448 78.0965C113.924 78.3 114.455 77.6024 114.631 76.9338C114.718 76.5869 114.736 76.2267 114.685 75.8729V75.7407C114.367 75.6478 114.022 75.717 113.766 75.9252C113.109 76.4499 112.24 76.0473 112.24 76.0473C112.24 76.0473 111.6 76.1011 111.354 76.3104C111.215 76.4281 110.986 76.1447 110.814 75.8744C110.803 76.0488 110.786 76.3641 110.775 76.7013C110.764 76.8713 110.761 77.0457 110.762 77.21Z"
      fill="#FF4842"
    />
    <path
      opacity="0.05"
      d="M113.354 77.1692C113.354 77.1692 113.797 77.5965 114.631 76.9367C114.686 76.7205 114.714 76.4984 114.713 76.2754C114.285 76.5937 113.494 77.1692 113.354 77.1692Z"
      fill="#FF4842"
    />
    <path
      opacity="0.05"
      d="M110.762 77.2098C110.835 77.2651 110.927 77.2883 111.018 77.274C111.108 77.2596 111.189 77.2091 111.24 77.1342C111.068 77.0054 110.91 76.8593 110.768 76.6982C110.764 76.8712 110.761 77.0456 110.762 77.2098Z"
      fill="#FF4842"
    />
    <g opacity="0.05">
      <path
        d="M119.637 23.3668C119.668 23.3 119.698 23.2331 119.726 23.1677C119.754 23.1023 119.764 23.066 119.78 23.0137C119.726 23.1287 119.678 23.2466 119.637 23.3668Z"
        fill="#FF4842"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.6485 107.557C39.2908 107.133 39.1268 106.583 39.1953 106.035L45.7104 53.5453L46.2506 49.1852C46.3758 48.182 47.2373 47.4258 48.2639 47.418L67.3471 47.293C67.896 47.2897 68.4205 47.0688 68.8021 46.6802C69.1836 46.2917 69.3902 45.7681 69.3751 45.2278L69.3677 45.0287C69.3489 44.4838 69.5556 43.9546 69.9404 43.5626C70.3253 43.1705 70.8553 42.9491 71.409 42.949H91.462C92.5876 42.949 93.5008 43.8462 93.5033 44.9546C93.5032 45.045 93.4972 45.1353 93.4856 45.2249C93.4087 45.7906 93.5797 46.3618 93.9559 46.796C94.3321 47.2302 94.878 47.4865 95.4575 47.5008L114.716 47.9848C115.309 47.9991 115.866 48.2666 116.242 48.7175C116.618 49.1684 116.777 49.7582 116.676 50.3334L107.049 105.706L106.867 106.752C106.822 107.019 106.722 107.275 106.572 107.502L39.6485 107.557Z"
      fill="#7A4F01"
    />
    <rect
      width="11.221"
      height="37.8774"
      transform="matrix(0.82682 0.562467 -0.574373 0.818594 97.7669 31.8286)"
      fill="white"
    />
    <rect
      width="7.92149"
      height="7.8789"
      transform="matrix(0.825215 0.564818 -0.576726 0.816938 98.4354 33.2974)"
      fill="#FFC107"
    />
    <rect
      opacity="0.5"
      width="7.93253"
      height="7.88923"
      transform="matrix(0.82682 0.562467 -0.574372 0.818594 93.3146 41.0542)"
      fill="#FFC107"
    />
    <rect
      opacity="0.3"
      width="7.93254"
      height="7.88923"
      transform="matrix(0.82682 0.562466 -0.574373 0.818594 87.8423 48.8508)"
      fill="#FFC107"
    />
    <rect
      width="11.2631"
      height="37.5905"
      transform="matrix(0.961007 0.276524 -0.284522 0.95867 84.6586 29.8862)"
      fill="white"
    />
    <rect
      width="7.96232"
      height="7.85917"
      transform="matrix(0.961007 0.276524 -0.284522 0.95867 86.0798 30.9268)"
      fill="#FF4842"
    />
    <rect
      opacity="0.5"
      width="7.96232"
      height="7.85917"
      transform="matrix(0.961007 0.276524 -0.284522 0.95867 83.3799 40.0227)"
      fill="#FF4842"
    />
    <rect
      opacity="0.3"
      width="7.9623"
      height="7.85917"
      transform="matrix(0.961007 0.276524 -0.284521 0.95867 80.6803 49.1174)"
      fill="#FF4842"
    />
    <rect
      width="11.2755"
      height="37.5479"
      transform="matrix(0.996877 0.0789753 -0.0814413 0.996678 76.9915 30.4546)"
      fill="white"
    />
    <rect
      width="7.97109"
      height="7.85027"
      transform="matrix(0.996877 0.0789753 -0.0814412 0.996678 78.5913 31.1907)"
      fill="#1890FF"
    />
    <rect
      opacity="0.5"
      width="7.97109"
      height="7.85027"
      transform="matrix(0.996877 0.0789753 -0.0814412 0.996678 77.8186 40.6345)"
      fill="#1890FF"
    />
    <rect
      opacity="0.3"
      width="7.97109"
      height="7.85027"
      transform="matrix(0.996877 0.0789753 -0.0814412 0.996678 77.0472 50.0818)"
      fill="#1890FF"
    />
    <rect
      width="11.2763"
      height="37.5453"
      transform="matrix(0.998994 -0.0448395 0.046246 0.99893 71.9347 31.9592)"
      fill="white"
    />
    <rect
      width="7.97163"
      height="7.84973"
      transform="matrix(0.998994 -0.0448396 0.0462459 0.99893 73.614 32.4895)"
      fill="#FFC107"
    />
    <rect
      opacity="0.5"
      width="7.98154"
      height="7.85959"
      transform="matrix(0.99879 -0.0491727 0.0507144 0.998713 74.3644 41.656)"
      fill="#FFC107"
    />
    <rect
      opacity="0.3"
      width="7.97163"
      height="7.84973"
      transform="matrix(0.998994 -0.0448395 0.046246 0.99893 74.49 51.4229)"
      fill="#FFC107"
    />
    <path
      d="M76.7181 81.4362L66.699 85.2527L52.0556 90.8263C51.5021 89.8089 50.6873 88.4341 49.6792 86.7933C46.2106 81.134 40.4719 72.3311 35.362 64.595C29.6056 55.8749 24.6507 48.5167 24.6507 48.5167L28.2374 47.4994L57.536 39.1238L76.7181 81.4362Z"
      fill="#F4F6F8"
    />
    <path
      opacity="0.1"
      d="M76.7179 81.4362L66.6988 85.2527C61.1019 86.4009 55.3904 86.9177 49.6747 86.7933C46.2061 81.134 40.4674 72.3311 35.3575 64.595L28.2387 47.4936L57.5358 39.1238L76.7179 81.4362Z"
      fill="black"
    />
    <path
      d="M84.1719 79.0616C84.1719 79.0616 68.6075 88.1275 45.3693 86.0769L26.8441 41.6047L35.7222 40.102L66.4141 34.9121L83.8309 78.1969L84.1719 79.0616Z"
      fill="#FFC107"
    />
    <path
      opacity="0.1"
      d="M83.8251 78.2027C80.1602 78.38 75.9196 78.3626 71.654 77.8612C67.037 77.3264 62.3936 76.2247 58.4128 74.2046C55.0844 72.5173 52.2387 70.6817 49.7575 68.4799C43.303 62.7639 39.3089 54.5874 35.7222 40.1078L66.4142 34.918L83.8251 78.2027Z"
      fill="#FF4842"
    />
    <path
      d="M95.2435 75.7161C95.2435 75.7161 83.7705 77.928 72.0924 76.5663C67.4755 76.0314 62.832 74.9298 58.8512 72.9097C55.5228 71.2223 52.6786 69.3868 50.1959 67.185C42.2078 60.1087 37.9894 49.2639 33.6396 27.3635C33.6396 27.3635 52.7125 31.8965 71.5728 21.9673C71.5669 21.9746 77.9241 61.0374 95.2435 75.7161Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M43.5051 35.4034C43.5051 35.4034 59.0149 34.5401 63.3455 31.2483L64.0023 33.3266C64.0023 33.3266 53.7545 37.7781 43.5051 37.8319V35.4034Z"
      fill="#FFC107"
    />
    <path
      opacity="0.3"
      d="M43.5051 45.6638C43.5051 45.6638 58.522 44.8542 69.868 39.458L70.2517 40.3213C70.2517 40.3213 61.2481 45.4966 43.5051 46.8511L43.5051 45.6638Z"
      fill="#FFC107"
    />
    <path
      opacity="0.3"
      d="M44.7141 49.6618C44.7141 49.6618 59.7309 48.8523 71.0754 43.4561L71.4592 44.3193C71.4592 44.3193 62.4556 49.4932 44.7141 50.8492L44.7141 49.6618Z"
      fill="#FFC107"
    />
    <path
      opacity="0.3"
      d="M45.9212 53.666C45.9212 53.666 60.938 52.8564 72.284 47.4602L72.6663 48.3235C72.6663 48.3235 63.6627 53.4988 45.9212 54.8533L45.9212 53.666Z"
      fill="#FFC107"
    />
    <path
      opacity="0.3"
      d="M47.1303 57.6696C47.1303 57.6696 62.1471 56.8601 73.4917 51.4639L73.8754 52.3271C73.8754 52.3271 64.8718 57.5025 47.1303 58.857L47.1303 57.6696Z"
      fill="#FFC107"
    />
    <path
      opacity="0.3"
      d="M48.3375 61.675C48.3375 61.675 63.3544 60.8655 74.7004 55.4692L75.0826 56.3325C75.0826 56.3325 66.079 61.5064 48.3375 62.8624V61.675Z"
      fill="#FFC107"
    />
    <path
      d="M153.926 111.389C153.926 111.389 147.686 111.019 148.421 115.325C148.421 115.325 148.273 116.085 148.976 116.431C148.976 116.431 148.986 116.111 149.615 116.22C149.839 116.257 150.067 116.268 150.294 116.252C150.6 116.232 150.891 116.114 151.122 115.915C151.122 115.915 152.877 115.201 153.559 112.379C153.559 112.379 154.063 111.763 154.043 111.604L152.99 112.04C152.99 112.04 153.351 112.789 153.067 113.409C153.067 113.409 153.033 112.068 152.831 112.101C152.79 112.101 152.283 112.36 152.283 112.36C152.283 112.36 152.902 113.668 152.431 114.61C152.431 114.61 152.608 113.004 152.086 112.455L151.348 112.88C151.348 112.88 152.071 114.226 151.581 115.325C151.581 115.325 151.706 113.641 151.191 112.984L150.52 113.5C150.52 113.5 151.2 114.826 150.785 115.738C150.785 115.738 150.731 113.776 150.373 113.627C150.373 113.627 149.783 114.138 149.697 114.354C149.697 114.354 150.162 115.316 149.873 115.825C149.873 115.825 149.696 114.517 149.551 114.517C149.551 114.517 148.961 115.389 148.905 115.97C148.905 115.97 148.93 115.087 149.41 114.428C149.072 114.492 148.76 114.651 148.512 114.886C148.512 114.886 148.604 114.273 149.554 114.22C149.554 114.22 150.038 113.564 150.167 113.523C150.167 113.523 149.222 113.446 148.65 113.696C148.65 113.696 149.154 113.114 150.341 113.38L151.004 112.848C151.004 112.848 149.759 112.68 149.233 112.866C149.233 112.866 149.839 112.354 151.182 112.721L151.904 112.296C151.904 112.296 150.844 112.071 150.213 112.151C150.213 112.151 150.878 111.796 152.117 112.18L152.632 111.952C152.632 111.952 151.855 111.806 151.628 111.777C151.401 111.748 151.387 111.693 151.387 111.693C151.88 111.611 152.385 111.667 152.847 111.853C152.847 111.853 153.944 111.458 153.926 111.389Z"
      fill="#FFC107"
    />
    <ellipse opacity="0.1" cx="149.628" cy="116.806" rx="4.41618" ry="0.73539" fill="#FFC107" />
    <path
      d="M156.832 97.1744C156.832 97.1744 153.315 96.9652 153.733 99.3937C153.689 99.646 153.814 99.8976 154.044 100.017C154.044 100.017 154.052 99.837 154.406 99.898C154.532 99.9185 154.66 99.9243 154.788 99.9154C154.96 99.9045 155.124 99.8381 155.255 99.7265C155.255 99.7265 156.243 99.3239 156.629 97.7325C156.629 97.7325 156.914 97.3852 156.902 97.2965L156.311 97.545C156.311 97.545 156.514 97.968 156.354 98.3182C156.354 98.3182 156.335 97.561 156.221 97.5785C156.198 97.5785 155.913 97.7238 155.913 97.7238C155.913 97.7238 156.261 98.4505 155.998 98.9926C155.998 98.9926 156.099 98.0871 155.804 97.7761L155.386 98.0174C155.386 98.0174 155.793 98.776 155.517 99.3951C155.517 99.3951 155.588 98.4447 155.297 98.0755L154.919 98.3662C154.919 98.3662 155.302 99.1146 155.067 99.6277C155.067 99.6277 155.036 98.5217 154.835 98.4388C154.692 98.5585 154.564 98.6947 154.453 98.8443C154.453 98.8443 154.716 99.3879 154.553 99.6742C154.553 99.6742 154.453 98.9373 154.372 98.9344C154.372 98.9344 154.041 99.4213 154.007 99.7556C154.023 99.4454 154.122 99.1448 154.292 98.8836C154.101 98.919 153.925 99.0089 153.786 99.1423C153.786 99.1423 153.838 98.7964 154.376 98.7673C154.376 98.7673 154.649 98.3967 154.722 98.3734C154.722 98.3734 154.189 98.3298 153.866 98.4708C153.866 98.4708 154.15 98.1453 154.819 98.2935L155.194 97.9927C155.194 97.9927 154.491 97.8982 154.195 98.0028C154.195 98.0028 154.537 97.7122 155.294 97.9244L155.702 97.6846C155.388 97.617 155.067 97.5901 154.747 97.6046C154.747 97.6046 155.123 97.4041 155.82 97.6206L156.115 97.4927C156.115 97.4927 155.672 97.407 155.548 97.3939C155.424 97.3808 155.412 97.3459 155.412 97.3459C155.69 97.3005 155.975 97.3316 156.236 97.436C156.236 97.436 156.844 97.2122 156.832 97.1744Z"
      fill="#FFC107"
    />
    <ellipse opacity="0.1" cx="154.412" cy="100.228" rx="2.49001" ry="0.4142" fill="#FFC107" />
    <path
      d="M136.068 99.2816C136.068 99.2816 131.82 99.0287 132.322 101.96C132.269 102.265 132.421 102.569 132.698 102.713C132.698 102.713 132.698 102.495 133.141 102.568C133.294 102.593 133.449 102.6 133.603 102.589C133.812 102.576 134.01 102.495 134.167 102.36C134.167 102.36 135.361 101.874 135.826 99.953C135.826 99.953 136.17 99.5345 136.155 99.4255L135.431 99.7278C135.431 99.7278 135.676 100.236 135.482 100.659C135.482 100.659 135.459 99.7467 135.321 99.767C135.293 99.767 134.949 99.9443 134.949 99.9443C134.949 99.9443 135.37 100.831 135.053 101.476C135.053 101.476 135.174 100.383 134.818 100.008L134.313 100.299C134.313 100.299 134.805 101.216 134.471 101.963C134.471 101.963 134.557 100.816 134.207 100.364L133.749 100.716C133.749 100.716 134.213 101.619 133.929 102.239C133.929 102.239 133.894 100.904 133.65 100.803C133.65 100.803 133.25 101.151 133.19 101.293C133.19 101.293 133.506 101.948 133.31 102.294C133.31 102.294 133.188 101.405 133.09 101.401C133.09 101.401 132.693 101.991 132.647 102.393C132.667 102.02 132.785 101.658 132.991 101.344C132.761 101.387 132.549 101.496 132.381 101.656C132.381 101.656 132.443 101.239 133.09 101.203C133.09 101.203 133.419 100.755 133.507 100.729C133.507 100.729 132.864 100.675 132.474 100.845C132.474 100.845 132.817 100.453 133.624 100.632L134.076 100.268C134.076 100.268 133.23 100.155 132.87 100.28C132.87 100.28 133.283 99.9327 134.198 100.187L134.69 99.8964C134.69 99.8964 133.968 99.7438 133.537 99.799C133.537 99.799 133.991 99.5577 134.833 99.8193L135.184 99.6638C135.184 99.6638 134.656 99.5621 134.501 99.5461C134.346 99.5301 134.338 99.488 134.338 99.488C134.673 99.4321 135.017 99.4693 135.332 99.5955C135.332 99.5955 136.081 99.3267 136.068 99.2816Z"
      fill="#FFC107"
    />
    <ellipse opacity="0.1" cx="133.143" cy="102.969" rx="3.00661" ry="0.499947" fill="#FFC107" />
    <rect
      width="24.8413"
      height="45.0143"
      rx="4.73723"
      transform="matrix(0.313534 -0.949577 0.952391 0.30488 99.1577 32.479)"
      fill="white"
    />
    <g opacity="0.2">
      <ellipse cx="111.738" cy="22.024" rx="4.62873" ry="4.55767" fill="#FFC107" />
    </g>
    <g opacity="0.2">
      <rect
        width="1.25029"
        height="20.1372"
        transform="matrix(0.313534 -0.949577 0.952391 0.30488 122.812 21.2036)"
        fill="#FFC107"
      />
    </g>
    <g opacity="0.2">
      <rect
        width="1.25029"
        height="10.258"
        transform="matrix(0.313533 -0.949577 0.952391 0.304881 121.813 24.2305)"
        fill="#FFC107"
      />
    </g>
    <g opacity="0.2">
      <rect
        width="1.25029"
        height="16.5911"
        transform="matrix(0.313533 -0.949577 0.952391 0.30488 120.811 27.2595)"
        fill="#FFC107"
      />
    </g>
    <g opacity="0.2">
      <rect
        width="1.25029"
        height="20.1372"
        transform="matrix(0.313534 -0.949577 0.952391 0.30488 119.812 30.2871)"
        fill="#FFC107"
      />
    </g>
    <g opacity="0.5">
      <rect
        width="2.41762"
        height="5.57263"
        transform="matrix(0.313534 -0.949577 0.952391 0.30488 131.874 40.2078)"
        fill="#FFC107"
      />
    </g>
    <rect
      width="24.5636"
      height="38.4857"
      rx="4"
      transform="matrix(0.546683 -0.837339 0.844951 0.534843 95.2244 75.552)"
      fill="white"
    />
    <rect
      width="6.94908"
      height="1.64586"
      transform="matrix(0.542185 -0.840259 0.847769 0.530365 100.043 74.1174)"
      fill="#DFE3E8"
    />
    <rect
      width="15.2617"
      height="1.5092"
      transform="matrix(0.542186 -0.840258 0.84777 0.530364 104.503 75.3003)"
      fill="#DFE3E8"
    />
    <rect
      width="15.2617"
      height="1.5092"
      transform="matrix(0.542186 -0.840258 0.84777 0.530364 107.236 77.0085)"
      fill="#DFE3E8"
    />
    <rect
      width="15.2617"
      height="1.50919"
      transform="matrix(0.542186 -0.840259 0.84777 0.530364 109.969 78.7197)"
      fill="#DFE3E8"
    />
    <rect
      width="15.2415"
      height="1.50697"
      transform="matrix(0.546683 -0.837339 0.844951 0.534843 112.796 81.199)"
      fill="#DFE3E8"
    />
    <rect
      width="15.2617"
      height="1.5092"
      transform="matrix(0.542186 -0.840258 0.84777 0.530364 115.434 82.1384)"
      fill="#F4F6F8"
    />
    <rect
      width="15.2617"
      height="1.5092"
      transform="matrix(0.542186 -0.840258 0.84777 0.530364 118.167 83.8467)"
      fill="#F4F6F8"
    />
    <rect
      width="3.95192"
      height="3.97798"
      transform="matrix(0.542186 -0.840259 0.84777 0.530365 129.642 76.562)"
      fill="#DFE3E8"
    />
    <path
      d="M94.7858 53.7442L72.1912 53.984C71.3454 53.9928 70.6643 54.6699 70.6635 55.5028V57.3412C70.663 58.1657 69.9951 58.8395 69.158 58.86L52.8541 59.2451C52.1431 59.2627 51.512 58.7992 51.3264 58.1231L50.5604 55.3516C50.3767 54.683 49.7568 54.2215 49.0534 54.2296L27.275 54.4607C26.8234 54.4651 26.3965 54.6641 26.1068 55.0052C25.8171 55.3464 25.6938 55.7954 25.7695 56.2338L34.531 107.008C34.6566 107.736 35.2963 108.27 36.0469 108.273L104.849 108.564C105.31 108.566 105.748 108.364 106.042 108.015C106.337 107.666 106.457 107.205 106.371 106.759L96.3164 54.9781C96.1765 54.2564 95.5319 53.7368 94.7858 53.7442Z"
      fill="url(#paint0_linear_1322_5321)"
    />
    <ellipse opacity="0.1" cx="20.8884" cy="97.4479" rx="2.3926" ry="0.324093" fill="#FFC107" />
    <ellipse opacity="0.1" cx="11.7239" cy="98.3617" rx="2.3926" ry="0.324093" fill="#FFC107" />
    <ellipse opacity="0.1" cx="15.3918" cy="100.968" rx="1.70035" ry="0.22963" fill="#FFC107" />
    <ellipse opacity="0.1" cx="5.88047" cy="100.738" rx="1.70035" ry="0.229626" fill="#FFC107" />
    <path
      d="M11.6723 98.2907C11.6723 98.2907 15.0272 87.4604 11.1468 83.1222C8.2435 79.8769 4.9476 80.2649 3.4716 80.6661C2.69919 80.8692 2.05611 81.3957 1.71221 82.1063C1.19561 83.2021 1.28859 84.981 4.93136 87.0578C11.0287 90.5342 11.408 95.3026 11.408 95.3026L11.6723 98.2907Z"
      fill="#FFC107"
    />
    <path
      d="M3.99866 82.2021C3.99866 82.2021 13.8879 85.2542 11.6739 98.2906"
      stroke="#7A4F01"
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <path
      d="M9.77558 83.2937C9.77558 83.2937 8.41323 84.1977 8.84717 85.5565"
      stroke="#7A4F01"
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <path
      d="M4.99042 84.0987C4.99042 84.0987 5.82288 83.2645 6.83689 83.7252"
      stroke="#7A4F01"
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <path
      d="M8.90021 88.2931C8.90021 88.2931 10.3629 87.5664 10.7408 88.4776"
      stroke="#7A4F01"
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <path
      d="M12.518 89.8146C12.518 89.8146 11.4198 89.7288 11.4006 90.2506"
      stroke="#7A4F01"
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <path
      d="M11.8081 98.3763C11.8081 98.3763 7.87748 90.505 6.79853 90.6736C6.28636 90.7564 6.0502 91.2549 5.94245 91.7519C5.80997 92.3968 5.88477 93.0663 6.15647 93.6674C6.7144 94.9318 8.21254 97.3226 11.8081 98.3763Z"
      fill="#FFC107"
    />
    <path
      d="M6.82788 92.1707C6.82788 92.1707 10.9518 98.0596 11.7489 98.3183"
      stroke="#7A4F01"
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <path d="M6.44849 93.0905H7.41084" stroke="#7A4F01" strokeWidth="0.5" strokeLinecap="round" />
    <path d="M7.60144 95.2443L9.11139 95.3083" stroke="#7A4F01" strokeWidth="0.5" strokeLinecap="round" />
    <path d="M8.52108 93.5208L8.35724 94.2954" stroke="#7A4F01" strokeWidth="0.5" strokeLinecap="round" />
    <path d="M10.1255 95.8765L10.096 96.5799" stroke="#7A4F01" strokeWidth="0.5" strokeLinecap="round" />
    <path
      d="M11.6914 98.3763C11.6914 98.3763 15.6205 90.505 16.6995 90.6736C17.2131 90.7564 17.4493 91.2549 17.5556 91.7519C17.688 92.3968 17.6132 93.0663 17.3415 93.6674C16.7836 94.9318 15.284 97.3226 11.6914 98.3763Z"
      fill="#FFC107"
    />
    <path
      d="M16.6714 92.1707C16.6714 92.1707 12.5386 98.0596 11.7489 98.3183"
      stroke="#7A4F01"
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <path d="M17.0509 93.0905H16.0885" stroke="#7A4F01" strokeWidth="0.5" strokeLinecap="round" />
    <path d="M15.898 95.2443L14.3881 95.3083" stroke="#7A4F01" strokeWidth="0.5" strokeLinecap="round" />
    <path d="M14.9785 93.5208L15.1423 94.2954" stroke="#7A4F01" strokeWidth="0.5" strokeLinecap="round" />
    <path d="M13.374 95.8765L13.4035 96.5799" stroke="#7A4F01" strokeWidth="0.5" strokeLinecap="round" />
    <path
      d="M4.94307 100.215C4.83613 100.129 4.75838 100.013 4.72019 99.882C4.68255 99.7467 4.74836 99.6043 4.87665 99.5434C5.02425 99.4896 5.17185 99.587 5.28993 99.6887C5.40801 99.7904 5.54232 99.898 5.68992 99.8733C5.53058 99.7311 5.45883 99.517 5.50099 99.3094C5.50705 99.2671 5.52493 99.2274 5.55265 99.1946C5.63236 99.1103 5.77701 99.1466 5.87295 99.2135C6.16815 99.4227 6.26113 99.8282 6.26261 100.194C6.27739 100.06 6.27739 99.9247 6.26261 99.7904C6.25468 99.653 6.31663 99.5206 6.42792 99.4373C6.49977 99.3987 6.58078 99.3796 6.66261 99.3821C6.79977 99.3627 6.9388 99.4018 7.04489 99.4896C7.14827 99.6282 7.14217 99.8184 7.03013 99.9503C6.91705 100.075 6.7833 100.18 6.63456 100.261C6.52167 100.328 6.42535 100.419 6.35265 100.527C6.34373 100.542 6.33678 100.557 6.33198 100.574H5.47295C5.28065 100.478 5.1024 100.358 4.94307 100.215Z"
      fill="#FFC107"
    />
    <path
      d="M19.9171 97.0436C19.8099 96.9584 19.7321 96.8427 19.6942 96.7122C19.6564 96.5773 19.7215 96.435 19.8492 96.3736C19.9968 96.3198 20.1444 96.4172 20.2625 96.5189C20.3806 96.6207 20.5134 96.7311 20.6654 96.7093C20.5061 96.5678 20.4338 96.3543 20.475 96.1469C20.4816 96.1045 20.5 96.0648 20.5282 96.0321C20.6079 95.9478 20.7525 95.9841 20.8485 96.0495C21.1511 96.2602 21.2367 96.6657 21.2381 97.032C21.2529 96.8972 21.2529 96.7613 21.2381 96.6265C21.2248 96.4907 21.2799 96.3571 21.3857 96.269C21.4579 96.2313 21.5388 96.2128 21.6204 96.2152C21.7573 96.1962 21.896 96.2347 22.0027 96.3213C22.1064 96.4605 22.0997 96.6516 21.9865 96.7835C21.8742 96.9086 21.7409 97.0139 21.5924 97.0945C21.4795 97.1607 21.3831 97.2511 21.3105 97.359C21.3018 97.3742 21.2949 97.3903 21.2898 97.4069H20.45C20.2562 97.3105 20.0769 97.1882 19.9171 97.0436Z"
      fill="#FFC107"
    />
    <path
      d="M14.9268 100.215C14.8199 100.128 14.7418 100.013 14.7024 99.8821C14.6648 99.7468 14.7306 99.6044 14.8589 99.5434C15.0065 99.4897 15.1541 99.587 15.2722 99.6888C15.3902 99.7905 15.5216 99.9009 15.6751 99.8806C15.516 99.7383 15.4438 99.5245 15.4847 99.3167C15.4913 99.2743 15.5097 99.2346 15.5378 99.2019C15.6176 99.1176 15.7622 99.1539 15.8581 99.2208C16.1607 99.4301 16.2449 99.8355 16.2478 100.202C16.2626 100.068 16.2626 99.932 16.2478 99.7978C16.2362 99.6607 16.2942 99.5268 16.4028 99.4402C16.4746 99.4014 16.5556 99.3823 16.6375 99.385C16.7746 99.3659 16.9136 99.405 17.0198 99.4926C17.1233 99.6313 17.1165 99.8219 17.0035 99.9533C16.891 100.078 16.7577 100.183 16.6094 100.264C16.4965 100.331 16.4002 100.422 16.3275 100.53C16.3186 100.545 16.3116 100.56 16.3068 100.577H15.4508C15.2605 100.48 15.0843 100.358 14.9268 100.215Z"
      fill="#FFC107"
    />
    <ellipse opacity="0.1" cx="3.54683" cy="14.0012" rx="3.19259" ry="3.14357" fill="#FFC107" />
    <ellipse opacity="0.1" cx="157.064" cy="39.1223" rx="3.19259" ry="3.14357" fill="#FFC107" />
    <ellipse opacity="0.1" cx="99.6743" cy="4.01857" rx="3.19259" ry="3.14357" fill="#FFC107" />
    <ellipse opacity="0.1" cx="9.93204" cy="31.344" rx="5.3505" ry="5.26835" fill="#FFC107" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.5612 73.7483C59.6239 73.7483 56.4321 76.8911 56.4321 80.7679C56.4321 84.6447 59.6239 87.7875 63.5612 87.7875C67.4985 87.7875 70.6903 84.6447 70.6903 80.7679C70.6903 76.8911 67.4985 73.7483 63.5612 73.7483V73.7483Z"
      fill="#B78103"
    />
    <path
      opacity="0.72"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.561 74.5647C67.0402 74.5647 69.8608 77.3419 69.8608 80.7678C69.8608 84.1936 67.0402 86.9708 63.561 86.9708C60.0817 86.9708 57.2612 84.1936 57.2612 80.7678C57.2642 77.3432 60.0829 74.5677 63.561 74.5647Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.6132 81.2808L60.2684 80.9495C60.1939 80.8824 60.1517 80.7887 60.1517 80.6907C60.1517 80.5926 60.1939 80.4989 60.2684 80.4319L63.2931 77.5229C63.3629 77.4513 63.4604 77.4106 63.5624 77.4106C63.6645 77.4106 63.762 77.4513 63.8318 77.5229L66.8564 80.4293C66.9295 80.4971 66.9708 80.5905 66.9708 80.6881C66.9708 80.7857 66.9295 80.8791 66.8564 80.9469L66.5117 81.2782C66.4411 81.3484 66.3439 81.388 66.2424 81.388C66.1408 81.388 66.0436 81.3484 65.973 81.2782L64.1846 79.4665V83.7653C64.1853 83.8605 64.1465 83.952 64.0767 84.0195C64.0069 84.0871 63.912 84.1251 63.8129 84.1251H63.3147C63.1079 84.1251 62.9403 83.964 62.9403 83.7653V79.4717L61.1519 81.2833C61.081 81.3532 60.9835 81.3924 60.882 81.3919C60.7804 81.3914 60.6834 81.3513 60.6132 81.2808Z"
      fill="#B78103"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1322_5321"
        x1="50.0336"
        y1="38.4216"
        x2="26.7764"
        y2="108.166"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC107" />
        <stop offset="1" stopColor="#FFE16A" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default SelectPhotosImage;
