import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  debounce,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Iconify from '../../../components/Iconify';
import LocationSelector from '../../../components/LocationSelector';
import NumberInput from '../../../components/NumberInput';
import Page from '../../../components/Page';
import { CLIENT_PORT, ENV } from '../../../config';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import * as contactActions from '../../../models/contacts/actions';
import { selectors as contactSelectors } from '../../../models/contacts/reducers';
import * as propertiesActions from '../../../models/properties/actions';
import { selectors as propertiesSelectors } from '../../../models/properties/reducers';
import { selectors as subscriptionSelectors } from '../../../models/subscription/reducers';
import { selectors as userSelectors } from '../../../models/user/reducers';
import { cleanAndFormatNumber } from '../../../utils/formatNumber';
import CoverPictureSelector from './CoverPictureSelector';
import PropertyImagesSelector from './ListingsImagesSelector';
import PricingRulesForm from './PricingRulesForm';

const commonFieldsProps = {
  InputLabelProps: {
    shrink: true,
  },
};

const serializeOption = (opt) => `${opt.name} - ${opt.mobile_number}`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const ListingsForm = ({ formik, filters }) => {
  const { currentLang } = useLocales();
  const { themeStretch } = useSettings();
  const { themeDirection } = useSettings();

  const [publishOnWebsiteError, setPublishOnWebsiteError] = useState('');
  const [markerPosition, setMarkerPosition] = useState(null);
  const [tabIdx, setTabIdx] = useState(0);
  const [copied, setCopied] = useState(false);

  const handleTabChange = (evt, value) => {
    setTabIdx(value);
  };

  const dispatch = useDispatch();
  const search = useSelector(contactSelectors.search);
  const searchLoading = useSelector(contactSelectors.loading);
  const [charCountAr, setCharCountAr] = useState(formik.values.description_ar.length);
  const [charCountEn, setCharCountEn] = useState(formik.values.description_en.length);

  const amenities = useSelector(propertiesSelectors.amenities);
  const workspace = useSelector(userSelectors.workspace);
  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);

  const activePaySubscription = activeSubscriptions?.find((sub) => sub.solution === 'pay') || null;
  const activeSiyahaSubscription = activeSubscriptions?.find((sub) => sub.solution === 'siyaha') || null;

  const handleSearch = debounce((e) => {
    const input = e.target.value;
    dispatch(contactActions.searchContactByNameRequest({ input, filters }));
  }, 500);

  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();
  useEffect(() => {
    if (formik.values.latitude && formik.values.longitude) {
      setMarkerPosition({
        lat: parseFloat(formik.values.latitude),
        lng: parseFloat(formik.values.longitude),
      });
    }
  }, [formik.values.longitude, formik.values.latitude]);

  useEffect(() => {
    dispatch(propertiesActions.getAmenitiesRequest());
  }, [dispatch]);

  const numberInputFeatures = [
    {
      name: 'king_beds',
      label: 'unit.kingBeds',
    },
    {
      name: 'single_beds',
      label: 'unit.singleBeds',
    },
    {
      name: 'number_of_guests',
      label: 'unit.numberOfGuests',
    },
    {
      name: 'number_of_kids',
      label: 'unit.numberOfKids',
    },
    {
      name: 'bedrooms',
      label: 'unit.bedrooms',
    },
    {
      name: 'bathrooms',
      label: 'unit.bathrooms',
    },
    {
      name: 'number_of_floors',
      label: 'unit.floors',
    },
    {
      name: 'unit_floor_number',
      label: 'unit.floorNumber',
    },
    {
      name: 'living_rooms',
      label: 'unit.dailyLivingRoom',
    },
    {
      name: 'dining_rooms',
      label: 'unit.diningRooms',
    },
    {
      name: 'maid_rooms',
      label: 'unit.maidRooms',
    },
    {
      name: 'driver_rooms',
      label: 'unit.driverRooms',
    },
    {
      name: 'majlis_rooms',
      label: 'unit.majlisRooms',
    },
    {
      name: 'storage_rooms',
      label: 'unit.storageRooms',
    },
    {
      name: 'basement_rooms',
      label: 'unit.basement',
    },
    { name: 'pools', label: 'unit.pool' },
    {
      name: 'balconies',
      label: 'unit.balconies',
    },
    {
      name: 'kitchens',
      label: 'unit.kitchens',
    },
    {
      name: 'gardens',
      label: 'unit.gardens',
    },
    {
      name: 'mulhaq_rooms',
      label: 'unit.mulhaqRooms',
    },
    {
      name: 'elevators',
      label: 'unit.elevators',
    },
    {
      name: 'parking_spots',
      label: 'unit.parkingBay',
    },
  ];

  const handleChangeLocation = ({ districtId }) => {
    formik.setFieldValue('district_id', districtId);
  };

  const updateLatLng = ({ lat, lng }) => {
    formik.setFieldValue('latitude', lat);
    formik.setFieldValue('longitude', lng);

    setMarkerPosition({
      lat,
      lng,
    });
  };

  const handleMapClick = (mapEvent) => {
    updateLatLng(mapEvent);
  };

  const onPlaceChanged = (autoComplete) => {
    const place = autoComplete.getPlace();
    const { lat, lng } = place.geometry.location;
    updateLatLng({ lat: lat(), lng: lng() });
  };

  const startYear = new Date().getFullYear() - 100;
  const currentYear = new Date().getFullYear();

  const yearsArray = [];
  // eslint-disable-next-line for-direction
  for (let year = currentYear; year >= startYear; year -= 1) {
    yearsArray.push(year);
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const amenityId = parseInt(name, 10);

    if (checked) {
      formik.setFieldValue('amenities', [...formik.values.amenities, amenityId]);
    } else {
      formik.setFieldValue(
        'amenities',
        formik.values.amenities.filter((id) => id !== amenityId)
      );
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handlePublishedOnWebsiteChange = (event) => {
    const { checked } = event.target;

    if (checked && !formik.values.is_online_payment_enabled && !formik.values.is_bank_transfer_payment_enabled) {
      setPublishOnWebsiteError(translate('unit.publishedStatusMustEnablePayment'));
      formik.setFieldValue('published_on_website', false);
    } else {
      setPublishOnWebsiteError('');
      formik.handleChange(event);
    }
  };

  const yearBuilt = formik.values.year_built.toString() === '0' ? '' : formik.values.year_built;

  const protocol = ENV === 'local' ? 'http' : 'https';
  const clientDomain = workspace?.client_domain;
  let url = '';

  if (ENV === 'local') {
    url = `${protocol}://${clientDomain}${CLIENT_PORT}/reservations/${formik.values.id}`;
  } else {
    url = `${protocol}://${clientDomain}/reservations/${formik.values.id}`;
  }

  const handlePriceChange = (e) => {
    const { name, value } = e.target;

    const parsedValue = cleanAndFormatNumber(value, true);

    const oldPrice = formik.values[name];

    [
      'sunday_price',
      'monday_price',
      'tuesday_price',
      'wednesday_price',
      'thursday_price',
      'friday_price',
      'saturday_price',
    ]
      .filter((key) => formik.values[key] === oldPrice)
      .forEach((key) => {
        formik.setFieldValue(key, parsedValue);
      });
  };

  const handleDescriptionChangeAr = (e) => {
    const { value } = e.target;
    setCharCountAr(value.length);
    formik.handleChange(e);
  };

  const handleDescriptionChangeEn = (e) => {
    const { value } = e.target;
    setCharCountEn(value.length);
    formik.handleChange(e);
  };

  const translateText = async () => {
    try {
      const response = await axios.post(
        `https://translation.googleapis.com/language/translate/v2?key=AIzaSyCnjD6fjWH1vreSb0Kwu58S-aDbqqLLWMo`,
        {
          q: formik.values.description_ar,
          target: 'en',
        }
      );
      formik.setFieldValue('description_en', response.data.data.translations[0].translatedText);
    } catch (error) {
      console.error('Error translating text:', error);
    }
  };

  useEffect(() => {
    if (
      !!publishOnWebsiteError &&
      (formik.values.is_online_payment_enabled || formik.values.is_bank_transfer_payment_enabled)
    ) {
      setPublishOnWebsiteError('');
    }

    if (
      !(formik.values.is_online_payment_enabled || formik.values.is_bank_transfer_payment_enabled) &&
      formik.values.published_on_website
    ) {
      formik.setFieldValue('published_on_website', false);
      setPublishOnWebsiteError(translate('unit.publishedStatusMustEnablePayment'));
    }

    setCharCountAr(formik.values.description_ar.length);
    setCharCountEn(formik.values.description_en.length);
  }, [formik]);

  return (
    <Page title={translate('units')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box
          sx={{
            '& div.MuiBox-root': {
              p: 0,
            },
          }}
        >
          {/* <Tabs value={tabIdx} onChange={handleTabChange}>
            <Tab label={translate('member.members')} {...a11yProps(0)} />
            <Tab label={translate('member.invitations')} {...a11yProps(1)} />
          </Tabs> */}

          <TabPanel value={tabIdx} index={0}>
            <form onSubmit={formik.handleSubmit}>
              <button type="submit" style={{ display: 'none' }}>
                Submit
              </button>
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid item xs={11} lg={8}>
                  <Grid container spacing={2}>
                    {/* facts */}
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                            {translate('unit.facts')}
                          </Typography>

                          <Box sx={{ pt: 3, pb: 3, mt: 2 }}>
                            <Grid container spacing={4}>
                              <Grid item xs={6}>
                                <TextField
                                  {...commonFieldsProps}
                                  name="name_ar"
                                  label={translate('unit.name_ar')}
                                  value={formik.values.name_ar}
                                  onChange={formik.handleChange}
                                  fullWidth
                                  sx={{ ml: 2 }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  {...commonFieldsProps}
                                  name="name_en"
                                  label={translate('unit.name_en')}
                                  value={formik.values.name_en}
                                  onChange={formik.handleChange}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                  <Box sx={{ flex: 1, mx: 1 }}>
                                    <TextField
                                      name="description_ar"
                                      type="text"
                                      multiline
                                      rows={6}
                                      label={translate('unit.descriptionAr')}
                                      error={formik.touched.description_ar && formik.errors.description_ar}
                                      value={formik.values.description_ar}
                                      onChange={handleDescriptionChangeAr}
                                      sx={{ mx: 1, mb: 1 }}
                                      fullWidth
                                      inputProps={{ maxLength: 240, style: {} }}
                                      disabled={activeSiyahaSubscription?.is_trial}
                                    />
                                    <Typography variant="caption" color="textSecondary">
                                      {charCountAr}/240
                                    </Typography>
                                  </Box>
                                  <Box sx={{ flex: 1, mx: 1 }}>
                                    <TextField
                                      name="description_en"
                                      type="text"
                                      multiline
                                      rows={6}
                                      label={translate('unit.descriptionEn')}
                                      error={formik.touched.description_en && formik.errors.description_en}
                                      value={formik.values.description_en}
                                      onChange={handleDescriptionChangeEn}
                                      sx={{ mx: 1, mb: 1 }}
                                      fullWidth
                                      inputProps={{ maxLength: 240 }}
                                      dir={'ltr'}
                                      disabled={activeSiyahaSubscription?.is_trial}
                                    />
                                    <Typography variant="caption" color="textSecondary">
                                      {charCountEn}/240
                                    </Typography>
                                    <Button
                                      disabled={activeSiyahaSubscription?.is_trial}
                                      color="secondary"
                                      onClick={translateText}
                                    >
                                      {translate('unit.translateFromArabic')}
                                    </Button>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid sx={{ pt: 4 }} container spacing={4}>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  {...commonFieldsProps}
                                  name="type"
                                  label={translate('unit.listingType')}
                                  value={translate(`unit.${formik.values.type}`)}
                                  disabled
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  {...commonFieldsProps}
                                  name="category"
                                  label={translate('unit.category')}
                                  value={translate(`unit.${formik.values.category}`)}
                                  disabled
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  {...commonFieldsProps}
                                  name="purpose"
                                  label={translate('unit.purpose')}
                                  value={translate(`unit.${formik.values.purpose}`)}
                                  disabled
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  {...commonFieldsProps}
                                  name="unit_number"
                                  label={translate('unit.unit_number')}
                                  value={formik.values.unit_number}
                                  fullWidth
                                  onChange={formik.handleChange}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    {/* facts */}

                    {/* rent */}

                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              mb: 4,
                            }}
                          >
                            <Typography variant="h6">{translate('siyaha.unit.dailyRatePrice')}</Typography>
                            <Typography variant="subtitle2">({translate('Saudi Riyals')})</Typography>
                          </Box>
                          <Box sx={{ pt: 3, pb: 3, mt: 2 }}>
                            <Grid container spacing={2} rowSpacing={6}>
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
                                    gap: 2,
                                  }}
                                >
                                  <NumberInput
                                    name="sunday_price"
                                    label={translate('weekDays.7')}
                                    showControls={false}
                                    formik={formik}
                                    fullWidth
                                    onChange={handlePriceChange}
                                  />
                                  <NumberInput
                                    name="monday_price"
                                    label={translate('weekDays.1')}
                                    showControls={false}
                                    formik={formik}
                                    fullWidth
                                  />
                                  <NumberInput
                                    name="tuesday_price"
                                    label={translate('weekDays.2')}
                                    showControls={false}
                                    formik={formik}
                                    fullWidth
                                  />
                                  <NumberInput
                                    name="wednesday_price"
                                    label={translate('weekDays.3')}
                                    showControls={false}
                                    formik={formik}
                                    fullWidth
                                  />
                                  <NumberInput
                                    name="thursday_price"
                                    label={translate('weekDays.4')}
                                    showControls={false}
                                    formik={formik}
                                    fullWidth
                                  />
                                  <NumberInput
                                    name="friday_price"
                                    label={translate('weekDays.5')}
                                    showControls={false}
                                    formik={formik}
                                    fullWidth
                                  />
                                  <NumberInput
                                    name="saturday_price"
                                    label={translate('weekDays.6')}
                                    showControls={false}
                                    formik={formik}
                                    fullWidth
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  overflow: 'auto',
                                }}
                              >
                                <PricingRulesForm formik={formik} />
                              </Grid>
                            </Grid>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12}>
                      <Card sx={{ marginBottom: 2 }}>
                        <CardContent>
                          <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                            {translate('unit.features')}
                          </Typography>

                          <Box sx={{ pt: 3, pb: 3, mt: 2 }}>
                            <Grid container spacing={2} rowSpacing={6}>
                              <Grid item xs={12} sm={6} md={4}>
                                <NumberInput
                                  name="area"
                                  label={translate('unit.area')}
                                  showControls={false}
                                  fullWidth
                                  formik={formik}
                                  helperText={translate('sqm')}
                                />
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth>
                                  <InputLabel id="unit-year-built">{translate('unit.yearBuilt')}</InputLabel>
                                  <Select
                                    name="year_built"
                                    label={translate('unit.yearBuilt')}
                                    labelId="unit-year-built"
                                    value={yearBuilt}
                                    error={formik.touched.year_built && formik.errors.year_built}
                                    onChange={formik.handleChange}
                                  >
                                    {yearsArray.map((year) => (
                                      <MenuItem key={year} value={year}>
                                        {year}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>

                            <Grid container spacing={2} rowSpacing={6} sx={{ mt: 1 }}>
                              {['building', 'mansion', 'duplex', 'townhouse', 'villa'].find(
                                (t) => t === formik.values.type
                              ) && (
                                <Grid item xs={12} sm={6} md={3}>
                                  <NumberInput
                                    name="apartments"
                                    label={translate('unit.apartments')}
                                    formik={formik}
                                    showControls
                                    positive
                                  />
                                </Grid>
                              )}

                              {[
                                'tower_apartment',
                                'mansion',
                                'duplex',
                                'building_apartment',
                                'townhouse',
                                'villa',
                                'villa_apartment',
                                'villa_floor',
                              ].find((t) => t === formik.values.type) &&
                                numberInputFeatures.map((props, i) => (
                                  <Grid item xs={12} sm={6} md={3} key={i}>
                                    <NumberInput fullWidth showControls positive formik={formik} {...props} />
                                  </Grid>
                                ))}
                            </Grid>
                          </Box>
                        </CardContent>
                      </Card>

                      {amenities &&
                        amenities.map((section) => (
                          <Grid item xs={12} key={section.category_en} sx={{ paddingY: 1 }}>
                            <Card>
                              <CardContent>
                                <Typography variant="h6" paragraph sx={{ mb: 2 }}>
                                  {themeDirection === 'rtl' ? section.category_ar : section.category_en}
                                </Typography>
                                <Grid container spacing={2}>
                                  {section.items.map((item, i) => (
                                    <Grid item xs={12} sm={3} key={i}>
                                      <FormControlLabel
                                        label={themeDirection === 'rtl' ? item.amenity_name_ar : item.amenity_name_en}
                                        control={
                                          <Checkbox
                                            name={item.id.toString()}
                                            checked={formik.values.amenities.includes(item.id)}
                                            onChange={handleCheckboxChange}
                                          />
                                        }
                                      />
                                    </Grid>
                                  ))}
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={11} lg={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                            {translate('unit.publishedStatus')}
                          </Typography>

                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <FormControl
                                error={!!publishOnWebsiteError}
                                sx={{ display: 'flex', flexDirection: 'column' }}
                              >
                                <FormControlLabel
                                  label={translate('unit.publishedOnWebsite')}
                                  sx={{
                                    ...(!formik.values.is_online_payment_enabled &&
                                    !formik.values.is_bank_transfer_payment_enabled
                                      ? {
                                          opacity: 0.5,
                                        }
                                      : {}),
                                  }}
                                  control={
                                    <Checkbox
                                      name="published_on_website"
                                      checked={formik.values.published_on_website}
                                      onChange={handlePublishedOnWebsiteChange}
                                    />
                                  }
                                />
                                {publishOnWebsiteError && <FormHelperText>{publishOnWebsiteError}</FormHelperText>}
                              </FormControl>
                            </Grid>
                            {formik.initialValues.published_on_website && formik.values.id && (
                              <Grid item xs={12}>
                                {workspace?.client_domain && (
                                  <Box>
                                    <Typography variant="subtitle2" paragraph>
                                      {translate('unit.link')}
                                    </Typography>
                                    <Box>
                                      <Button
                                        component="a"
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        startIcon={<Iconify icon="icon-park-outline:share" />}
                                        sx={{ fontSize: '0.75rem' }}
                                        color="secondary"
                                      >
                                        {translate('unit.navToPropertyPage')}
                                      </Button>
                                      <Tooltip
                                        title={copied ? translate('unit.copiedUrl') : translate('unit.copyUrl')}
                                        onClose={() => setCopied(false)}
                                      >
                                        <Button
                                          onClick={handleCopyClick}
                                          startIcon={<FileCopyIcon />}
                                          sx={{ fontSize: '0.75rem' }}
                                          color="secondary"
                                        >
                                          {translate('unit.copyUrl')}
                                        </Button>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                )}
                              </Grid>
                            )}
                            {/* <Grid item xs={12}>
                      <FormControlLabel
                        label={translate('unit.publishedOnNuzulApp')}
                        control={<Checkbox name="published_on_app" checked={formik.values.published_on_app} />}
                        onChange={formik.handleChange}
                      />
                    </Grid> */}
                            <Grid item xs={12}>
                              <TextField
                                {...commonFieldsProps}
                                name="sort"
                                label={translate('unit.sort')}
                                value={formik.values.sort}
                                fullWidth
                                onChange={formik.handleChange}
                                helperText={translate('unit.sortHelperText')}
                              />
                            </Grid>
                          </Grid>

                          <Typography variant="h6" paragraph sx={{ mt: 4 }}>
                            {translate('unit.paymentMethods')}
                          </Typography>
                          <Grid container spacing={3}>
                            {/* nuzul pay */}
                            <Grid item xs={12}>
                              <FormControlLabel
                                label={translate('unit.isOnlinePaymentEnabled')}
                                control={
                                  <Checkbox
                                    disabled={activePaySubscription === null}
                                    name="is_online_payment_enabled"
                                    checked={formik.values.is_online_payment_enabled}
                                  />
                                }
                                onChange={formik.handleChange}
                              />
                              {activePaySubscription === null && (
                                <>
                                  <br />
                                  <span
                                    style={{
                                      backgroundColor: '#ddd',
                                      marginLeft: 7,
                                      marginRight: 7,
                                      paddingRight: 12,
                                      paddingLeft: 12,
                                      paddingTop: 3,
                                      paddingBottom: 3,
                                      borderRadius: 5,
                                      fontSize: 12,
                                    }}
                                  >
                                    {translate('setting.paymentSetting.requiresNuzulPay')}
                                  </span>
                                </>
                              )}
                            </Grid>

                            <Grid item xs={12}>
                              <FormControlLabel
                                label={translate('unit.isBankTransferPaymentEnabled')}
                                control={
                                  <Checkbox
                                    name="is_bank_transfer_payment_enabled"
                                    checked={formik.values.is_bank_transfer_payment_enabled}
                                  />
                                }
                                onChange={formik.handleChange}
                              />
                            </Grid>
                            {/* 
                            <Grid item xs={12}>
                              <FormControlLabel
                                label={translate('unit.isCashPaymentEnabled')}
                                control={
                                  <Checkbox
                                    name="is_cash_payment_enabled"
                                    checked={formik.values.is_cash_payment_enabled}
                                  />
                                }
                                onChange={formik.handleChange}
                              />
                            </Grid> */}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12}>
                      <CoverPictureSelector formik={formik} />
                    </Grid>

                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                            {translate('unit.photos')}
                          </Typography>
                          <PropertyImagesSelector formik={formik} />
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                            {translate('unit.location')}
                          </Typography>

                          <Box>
                            <LocationSelector
                              formik={formik}
                              onLocation={handleChangeLocation}
                              onMapClick={handleMapClick}
                              markerPosition={markerPosition}
                              onPlaceChanged={onPlaceChanged}
                            />
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </TabPanel>

          {/* <TabPanel value={tabIdx} index={1}>
            <form>
              <Box>hey</Box>
            </form>
          </TabPanel> */}
        </Box>
      </Container>
    </Page>
  );
};

export default ListingsForm;
