import {
  Box,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useLocales from '../../../hooks/useLocales';
import { MobileNumber } from '../../auth/components';

const GuestForm = ({ formik, phoneNumberDisabled = false }) => {
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" sx={{ mb: 3 }}>
          {translate('guest.information')}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <button type="submit" style={{ display: 'none' }}>
            Submit
          </button>
          <Box sx={{ display: 'flex' }}>
            <TextField
              name="name"
              value={formik.values.name}
              label={translate('contact.name')}
              fullWidth
              sx={{ mr: 2 }}
              onChange={formik.handleChange}
            />
            <FormControl error={formik.touched.gender && formik.errors.gender} sx={{ minWidth: 150 }}>
              <InputLabel id="gender-select">{translate('contact.gender')}</InputLabel>
              <Select
                value={formik.values.gender}
                name="gender"
                labelId="gender-select"
                label={translate('contact.gender')}
                onChange={formik.handleChange}
              >
                <MenuItem value="male">{translate('contact.male')}</MenuItem>
                <MenuItem value="female">{translate('contact.female')}</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <MobileNumber formik={formik} disabled={phoneNumberDisabled} />
          <TextField
            name="email"
            type="email"
            label={translate('contact.email')}
            error={formik.touched.email && formik.errors.email}
            value={formik.values.email}
            onChange={formik.handleChange}
            sx={{ mb: 2 }}
            fullWidth
          />

          <TextField
            name="note"
            type="text"
            multiline
            rows={4}
            label={translate('contact.note')}
            error={formik.touched.note && formik.errors.note}
            value={formik.values.note}
            onChange={formik.handleChange}
            sx={{ mb: 2 }}
            fullWidth
          />
        </form>
      </CardContent>
    </Card>
  );
};
export default GuestForm;
