// @mui
import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
// hooks
import { useNavigate } from 'react-router-dom';

// components
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import OrderServices from '../../../models/t-orders/services';
import { fDateTimeSuffix } from '../../../utils/formatTime';

// ----------------------------------------------------------------------

export default function OrdersView() {
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const navigate = useNavigate();

  const isArabic = languageCode === 'ar';

  const [tOrders, setTOrders] = useState([]);

  const columns = [
    {
      field: 'id',
      headerName: '#',
      maxWidth: 100,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'tenant_contact.name',
      headerName: translate('order.customer'),
      headerClassName: 'datagrid-header',
      flex: true,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => (params.row.tenant_contact ? params.row.tenant_contact.name : ''),
      // renderCell: (params) => (
      //   <Box
      //     onClick={() => {
      //       navigate(`/maktb/crm/contacts/${params.row.tenant_contact.id}`);
      //     }}
      //     sx={{ color: 'secondary.main', textDecoration: 'underline', cursor: 'pointer' }}
      //   >
      //     {params.row.tenant_contact ? params.row.tenant_contact.name : ''}
      //   </Box>
      // ),
    },
    {
      field: 'status',
      headerName: translate('order.status'),
      headerClassName: 'datagrid-header',
      flex: true,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => translate(`order.${params.row.status}`),
    },
    {
      field: 'type',
      headerName: translate('order.type'),
      headerClassName: 'datagrid-header',
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) =>
        `${
          params.row.property_order
            ? translate(`order.${params.row.property_order?.type}`)
            : translate(`order.serviceRequest`)
        } ${params.row?.description_ar ? '-' : ''} ${params.row?.description_ar || ''}`,
      width: 400,

      // renderCell: (params) => (
      //   <Box
      //     onClick={() => {
      //       navigate(`/maktb/properties/${params.row.property_order?.property_id}`);
      //     }}
      //     sx={{ color: 'secondary.main', textDecoration: 'underline', cursor: 'pointer' }}
      //   >
      //     {translate(`order.${params.row.property_order?.type}`)} {params.row?.description_ar ? '-' : ''}
      //     {params.row?.description_ar || ''}
      //   </Box>
      // ),
    },
    {
      field: 'total_amount_with_tax',
      headerName: translate('order.totalAmount'),
      headerClassName: 'datagrid-header',
      flex: true,
      align: 'center',
      headerAlign: 'center',
      valueGetter: ({ row }) =>
        row?.service_order?.is_free ? translate('service.isFree') : `${row.total_amount_with_tax} ${translate('SAR')}`,
    },
    {
      field: 'created_at',
      headerName: translate('order.orderDate'),
      headerClassName: 'datagrid-header',
      flex: true,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => fDateTimeSuffix(params.row.created_at, isArabic),
    },
  ];

  useEffect(() => {
    const queryParams = {
      include_archived: false,
      is_office: true,
    };

    OrderServices.getTOrders(queryParams).then((response) => {
      setTOrders(response.data.data);
    });
  }, []);

  const handleRow = (params) => {
    navigate(`/maktb/operations/orders/${params.row.uuid}`);
  };

  return (
    <Page title={translate('orders')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('orders')}
            </Typography>
          </Box>
        </Box>

        <Card>
          <CardContent sx={{ p: 0 }}>
            <Box>
              <DataGrid
                onRowClick={handleRow}
                loading={false}
                columns={columns}
                rows={tOrders || []}
                disableColumnFilter
                disableColumnSelector
                hideFooterSelectedRowCount
                disableSelectionOnClick
                rowsPerPageOptions={[10]}
                pageSize={10}
                autoHeight
                sx={{
                  '& .datagrid-header': {
                    backgroundColor: '#F4F6F8',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderRadius: 0,
                  },
                  border: 'none',
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
