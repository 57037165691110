import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
  loading: false,
  properties: null,
  totalProperties: null,
  property: null,
  search: null,
  filteredProperties: null,
  getPropertiesLoading: false,
  amenities: null,
};

const reducer = createReducer(initialState, {
  [actions.getPropertiesRequest]: (state) => {
    state.getPropertiesLoading = true;
  },
  [actions.getPropertiesSuccess]: (state, action) => {
    state.getPropertiesLoading = false;
    state.properties = action.payload.data;
    state.totalProperties = action.payload.meta.total;
  },
  [actions.getPropertiesFailure]: (state) => {
    state.getPropertiesLoading = false;
  },
  [actions.editPropertyRequest]: (state) => {
    state.loading = true;
  },
  [actions.editPropertySuccess]: (state, action) => {
    state.loading = false;
    state.property = action.payload.data;
  },
  [actions.editPropertyFailure]: (state) => {
    state.loading = false;
  },
  [actions.searchPropertiesRequest]: (state) => {
    state.loading = true;
  },
  [actions.searchPropertiesSuccess]: (state, action) => {
    state.loading = false;
    state.search = action.payload.data;
  },
  [actions.searchPropertiesFailure]: (state) => {
    state.loading = false;
  },
  [actions.filterPropertiesRequest]: (state) => {
    state.loading = true;
  },
  [actions.filterPropertiesSuccess]: (state, action) => {
    state.loading = false;
    state.filteredProperties = action.payload.data;
  },
  [actions.filterPropertiesFailure]: (state) => {
    state.loading = false;
  },
  [actions.createPropertyRequest]: (state) => {
    state.loading = false;
  },
  [actions.createPropertyFailure]: (state) => {
    state.loading = false;
    state.getPropertiesLoading = false;
  },
  [actions.createPropertySuccess]: (state, action) => {
    state.loading = false;
    state.property = action.payload.data;
  },
  [actions.getPropertyRequest]: (state) => {
    state.loading = true;
  },
  [actions.getPropertiesFailure]: (state) => {
    state.loading = false;
  },
  [actions.getPropertySuccess]: (state, action) => {
    state.loading = false;
    state.property = action.payload.data;
  },
  [actions.toggleArchivePropertyRequest]: (state) => {
    state.loading = true;
  },
  [actions.toggleArchivePropertySuccess]: (state, action) => {
    state.loading = false;
    state.property = action.payload.data;
  },
  [actions.toggleArchivePropertyFailure]: (state) => {
    state.loading = false;
  },
  [actions.getAmenitiesRequest]: (state) => {
    state.loading = true;
  },
  [actions.getAmenitiesFailure]: (state) => {
    state.loading = false;
  },
  [actions.getAmenitiesSuccess]: (state, action) => {
    state.loading = false;
    state.amenities = action.payload.data;
  },
});

const loading = (state) => state.properties.loading;
const getPropertiesLoading = (state) => state.properties.getPropertiesLoading;
const properties = (state) => state.properties.properties;
const totalProperties = (state) => state.properties.totalProperties;
const property = (state) => state.properties.property;
const search = (state) => state.properties.search;
const filteredProperties = (state) => state.properties.filteredProperties;
const amenities = (state) => state.properties.amenities;

const selectors = {
  loading,
  property,
  properties,
  totalProperties,
  search,
  filteredProperties,
  getPropertiesLoading,
  amenities,
};

export { initialState, reducer, selectors };
export default reducer;
