import { Box, Button, styled } from '@mui/material';

const InvitationsContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '3% 3%',
  backgroundColor: 'white',
  borderRadius: '16px',
  boxShadow: '0px 0px 17px rgba(175, 175, 175, 0.3)',
  position: 'relative',
}));

const ListItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderBottom: 'solid 1px #919EAB52',
  padding: '0 1% 1% 0',
  marginTop: '2%',
}));

const WorkspaceTitleButton = styled(Button)(({ theme }) => ({
  marginLeft: '1%',
  color: 'black',
  borderColor: '#919EAB52',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.6rem',
  },
}));

const WorkspaceButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    height: '4%',
    width: '4%',
    fontSize: '0.6rem',
  },
}));

export { InvitationsContainer, ListItem, WorkspaceButton, WorkspaceTitleButton };
