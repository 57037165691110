// @mui
import { Card, CardContent, CardHeader, Container, Grid, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
// hooks
import { useSelector } from 'react-redux';

// components
import Page from '../../components/Page';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import { selectors as userSelectors } from '../../models/user/reducers';
import ChartLine from './ChartLine';

// ----------------------------------------------------------------------

export default function Dashboard() {
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const [transactionsAmount, setTransactionsAmount] = useState(0);

  // const [listingsGrowthViews] = useDeferredState(
  //   async () => UtilsServices.getListingsGrowthViews().then((r) => r.data),
  //   [],
  //   []
  // );

  const userInfo = useSelector(userSelectors.me);

  const viewsLineSeries = useMemo(
    () => [
      {
        name: translate('listingsViews'),
        data: Object.values([]),
        color: '#1FC6AC',
      },
    ],
    [translate]
  );

  return (
    <Page title={translate('dashboard')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          {translate('hi')} {userInfo?.name}! {languageCode === 'ar' ? '' : translate('Welcome back')} 👋🏼
        </Typography>

        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                py: 5,
                textAlign: 'center',
              }}
            >
              <Typography variant="h3">{transactionsAmount || 0}</Typography>

              <Typography variant="subtitle2" sx={{ opacity: 0.64 }}>
                {translate('مجموع العمليات خلال اكتوبر')}
              </Typography>
            </Card>
          </Grid> */}
          <Grid item xs={12} md={12}>
            <Card dir="ltr">
              <CardHeader title={`${translate('pay.salesGrowth')} 2024`} dir={languageCode === 'ar' ? 'rtl' : 'ltr'} />
              <CardContent
                sx={{
                  height: 420,
                }}
              >
                <ChartLine series={viewsLineSeries} dir={languageCode === 'ar' ? 'rtl' : 'ltr'} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
