import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
// eslint-disable-next-line import/no-named-as-default
import NotificationsServices from './services';

export function* getNotificationSaga({ payload }) {
  try {
    const response = yield call(NotificationsServices.getNotifications, { page: payload?.page });
    yield put(actions.getNotificationsSuccess(response.data));
    payload?.callback?.('success', response.data);
  } catch (err) {
    yield put(actions.getNotificationsFailure(err));
    payload?.callback?.('error', err.response.data);
  }
}

export function* getPaginatedNotificationsSaga({ payload }) {
  try {
    const response = yield call(NotificationsServices.getNotifications, { page: payload.page });
    yield put(actions.getPaginatedNotificationsSuccess(response.data));
    payload?.callback?.('success', response.data);
  } catch (err) {
    yield put(actions.getPaginatedNotificationsFailure(err));
    payload?.callback?.('error', err.response.data);
  }
}

export function* markAsReadSaga({ payload }) {
  try {
    const response = yield call(NotificationsServices.markAsRead, { id: payload.id });
    yield put(actions.markAsReadSuccess({ id: payload.id }));
    payload?.callback?.('success', response.data);
  } catch (err) {
    yield put(actions.markAsReadFailure(err));
    payload?.callback?.('error', err.response.data);
  }
}

export function* markAsUnreadSaga({ payload }) {
  try {
    const response = yield call(NotificationsServices.markAsUnread, { id: payload.id });
    yield put(actions.markAsUnreadSuccess({ id: payload.id }));
    payload?.callback?.('success', response.data);
  } catch (err) {
    yield put(actions.markAsUnreadFailure(err));
    payload?.callback?.('error', err.response.data);
  }
}

export function* deleteNotificationSaga({ payload }) {
  try {
    const response = yield call(NotificationsServices.deleteNotification, { id: payload.id });
    yield put(actions.deleteNotificationSuccess({ id: payload.id }));
    payload?.callback?.('success', response.data);
  } catch (err) {
    yield put(actions.deleteNotificationFailure(err));
    payload?.callback?.('error', err.response.data);
  }
}

export function* markAllAsReadSaga({ payload }) {
  try {
    const response = yield call(NotificationsServices.markAllAsRead);
    yield put(actions.markAllAsReadSuccess());
    payload?.callback?.('success', response.data);
  } catch (err) {
    yield put(actions.markAllAsReadFailure(err));
    payload?.callback?.('error', err.response.data);
  }
}

export function* deleteAllNotificationsSaga({ payload }) {
  try {
    const response = yield call(NotificationsServices.deleteAllNotifications);
    yield put(actions.deleteAllNotificationsSuccess());
    payload?.callback?.('success', response.data);
  } catch (err) {
    yield put(actions.deleteAllNotificationsFailure(err));
    payload?.callback?.('error', err.response.data);
  }
}

export function* notificationsSaga() {
  yield all([
    takeLatest(actions.getNotificationsRequest, getNotificationSaga),
    takeLatest(actions.getPaginatedNotificationsRequest, getPaginatedNotificationsSaga),
    takeLatest(actions.markAsReadRequest, markAsReadSaga),
    takeLatest(actions.markAsUnreadRequest, markAsUnreadSaga),
    takeLatest(actions.deleteNotificationRequest, deleteNotificationSaga),
    takeLatest(actions.markAllAsReadRequest, markAllAsReadSaga),
    takeLatest(actions.deleteAllNotificationsRequest, deleteAllNotificationsSaga),
  ]);
}
