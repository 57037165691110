// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import { TENANT_ROLES } from '../../../utils/constants';
import { getSelectedProduct } from '../../../utils/location';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const selectedProduct = getSelectedProduct();

export const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  deals: getIcon('ic_deals'),
  dashboard: getIcon('ic_dashboard'),
  team: getIcon('ic_team'),
  realEstate: getIcon('ic_property'),
  contract: getIcon('ic_contract'),
  project: getIcon('ic_project'),
  website: getIcon('ic_website'),
  chat: getIcon('ic_chat'),
  settings: getIcon('ic_settings'),
  services: getIcon('ic_shop'),
  requests: getIcon('ic_request'),
  orders: getIcon('ic_orders'),
  reservations: getIcon('ic_calendar'),
};

const navPayConfig = [
  // GENERAL
  // ----------------------------------------------------------------------

  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: '/pay/dashboard',
        icon: ICONS.dashboard,
        roles: [TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager, TENANT_ROLES.companyAssociate],
      },
    ],
    roles: [TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager, TENANT_ROLES.companyAssociate],
  },
  {
    subheader: 'financial',
    items: [
      {
        title: 'transactions',
        path: '/pay/transactions',
        icon: null,
        roles: [TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager],
      },
      {
        title: 'deposits',
        path: '/pay/deposits',
        icon: null,
        roles: [TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager],
      },
      // {
      //   title: 'Payment Links',
      //   path: '/pay/payment-links',
      //   icon: ICONS.user,
      //   roles: [TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager],
      // },
    ],
    roles: [TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager],
  },

  {
    subheader: 'management',
    items: [
      {
        title: 'team',
        path: '/team',
        icon: ICONS.team,
        // children: [
        //   { title: 'Four', path: '/dashboard/user/four' },
        //   { title: 'Five', path: '/dashboard/user/five' },
        //   { title: 'Six', path: '/dashboard/user/six' },
        // ],
        roles: [TENANT_ROLES.companyOwner],
      },
      {
        title: 'settings',
        path: '/settings',
        icon: ICONS.settings,
        roles: [TENANT_ROLES.companyOwner],
      },
    ],
    roles: [TENANT_ROLES.companyOwner],
  },
];

const navSiyahaConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: '/siyaha/dashboard',
        icon: ICONS.dashboard,
        roles: [TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager, TENANT_ROLES.companyAssociate],
      },
    ],
    roles: [TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager, TENANT_ROLES.companyAssociate],
  },

  {
    subheader: 'GRM',
    items: [
      {
        title: 'guests',
        path: '/siyaha/crm/guests',
        icon: ICONS.user,
        roles: [
          TENANT_ROLES.companyOwner,
          TENANT_ROLES.companyManager,
          TENANT_ROLES.companyAssociate,
          TENANT_ROLES.companyMember,
        ],
      },
    ],
    roles: [
      TENANT_ROLES.companyOwner,
      TENANT_ROLES.companyManager,
      TENANT_ROLES.companyAssociate,
      TENANT_ROLES.companyMember,
    ],
  },

  // {
  //   subheader: 'Financials',
  //   items: [
  //     { title: 'orders', path: '/maktb/financials/orders', icon: ICONS.orders },
  //     { title: 'sales', path: '/siyaha/financials/sales', icon: ICONS.analytics },
  //   ],
  //   roles: [TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager, TENANT_ROLES.companyAssociate],
  // },

  {
    subheader: 'siyaha.listingsAndReservations',
    items: [
      {
        title: 'siyaha.reservations',
        path: '/siyaha/reservations',
        icon: ICONS.reservations,
        roles: [
          TENANT_ROLES.companyOwner,
          TENANT_ROLES.companyManager,
          TENANT_ROLES.companyAssociate,
          TENANT_ROLES.companyMember,
        ],
      },

      {
        title: 'siyaha.units',
        path: '/siyaha/listings',
        icon: ICONS.realEstate,
        roles: [
          TENANT_ROLES.companyOwner,
          TENANT_ROLES.companyManager,
          TENANT_ROLES.companyAssociate,
          TENANT_ROLES.companyMember,
        ],
      },
    ],
    roles: [
      TENANT_ROLES.companyOwner,
      TENANT_ROLES.companyManager,
      TENANT_ROLES.companyAssociate,
      TENANT_ROLES.companyMember,
    ],
  },

  {
    subheader: 'marketing',
    items: [
      // { title: 'SMS Campaigns', path: '/marketing/sms-campaigns', icon: ICONS.chat },
      {
        title: 'website',
        path: '/website',
        icon: ICONS.website,
        roles: [TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager],
      },
    ],
    roles: [TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager],
  },

  {
    subheader: 'management',
    items: [
      {
        title: 'team',
        path: '/team',
        icon: ICONS.team,
        // children: [
        //   { title: 'Four', path: '/dashboard/user/four' },
        //   { title: 'Five', path: '/dashboard/user/five' },
        //   { title: 'Six', path: '/dashboard/user/six' },
        // ],
        roles: [TENANT_ROLES.companyOwner],
      },
      {
        title: 'settings',
        path: '/settings',
        icon: ICONS.settings,
        roles: [TENANT_ROLES.companyOwner],
      },
    ],
    roles: [TENANT_ROLES.companyOwner],
  },
];

const navOfficeConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: '/maktb/dashboard',
        icon: ICONS.dashboard,
        roles: [TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager, TENANT_ROLES.companyAssociate],
      },
      {
        title: 'membership',
        path: `/member/dashboard`,
        icon: ICONS.user,
        roles: [
          TENANT_ROLES.companyOwner,
          TENANT_ROLES.companyManager,
          TENANT_ROLES.companyAssociate,
          TENANT_ROLES.companyMember,
        ],
      },
    ],
    roles: [
      TENANT_ROLES.companyOwner,
      TENANT_ROLES.companyManager,
      TENANT_ROLES.companyAssociate,
      TENANT_ROLES.companyMember,
    ],
  },
  {
    subheader: 'CRM',
    items: [
      {
        title: 'deals',
        path: '/maktb/crm/deals',
        icon: ICONS.deals,
        roles: [
          TENANT_ROLES.companyOwner,
          TENANT_ROLES.companyManager,
          TENANT_ROLES.companyAssociate,
          TENANT_ROLES.companyMember,
        ],
      },
      {
        title: 'requests',
        path: '/maktb/crm/requests',
        icon: ICONS.requests,
        roles: [TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager, TENANT_ROLES.companyAssociate],
      },
      {
        title: 'contacts',
        path: '/maktb/crm/contacts',
        icon: ICONS.user,
        roles: [
          TENANT_ROLES.companyOwner,
          TENANT_ROLES.companyManager,
          TENANT_ROLES.companyAssociate,
          TENANT_ROLES.companyMember,
        ],
      },
    ],
    roles: [
      TENANT_ROLES.companyOwner,
      TENANT_ROLES.companyManager,
      TENANT_ROLES.companyAssociate,
      TENANT_ROLES.companyMember,
    ],
  },

  {
    subheader: 'Operations',
    items: [
      {
        title: 'orders',
        path: '/maktb/operations/orders',
        icon: ICONS.orders,
        roles: [
          TENANT_ROLES.companyOwner,
          TENANT_ROLES.companyManager,
          TENANT_ROLES.companyAssociate,
          TENANT_ROLES.companyMember,
        ],
      },
      // {
      //   title: 'sales',
      //   path: '/maktb/financials/sales',
      //   icon: ICONS.analytics,
      //   roles: [
      //     TENANT_ROLES.companyOwner,
      //     TENANT_ROLES.companyManager,
      //     TENANT_ROLES.companyAssociate,
      //     TENANT_ROLES.companyMember,
      //   ],
      // },
    ],
    roles: [
      TENANT_ROLES.companyOwner,
      TENANT_ROLES.companyManager,
      TENANT_ROLES.companyAssociate,
      TENANT_ROLES.companyMember,
    ],
  },

  {
    subheader: 'listings & services',
    items: [
      {
        title: 'units',
        path: '/maktb/properties',
        icon: ICONS.realEstate,
        roles: [
          TENANT_ROLES.companyOwner,
          TENANT_ROLES.companyManager,
          TENANT_ROLES.companyAssociate,
          TENANT_ROLES.companyMember,
        ],
      },
      {
        title: 'services',
        path: '/maktb/services',
        icon: ICONS.services,
        roles: [
          TENANT_ROLES.companyOwner,
          TENANT_ROLES.companyManager,
          TENANT_ROLES.companyAssociate,
          TENANT_ROLES.companyMember,
        ],
      },
      {
        title: 'projects',
        path: '/maktb/projects',
        icon: ICONS.project,
        roles: [
          TENANT_ROLES.companyOwner,
          TENANT_ROLES.companyManager,
          TENANT_ROLES.companyAssociate,
          TENANT_ROLES.companyMember,
        ],
      },
    ],
    roles: [
      TENANT_ROLES.companyOwner,
      TENANT_ROLES.companyManager,
      TENANT_ROLES.companyAssociate,
      TENANT_ROLES.companyMember,
    ],
  },

  {
    subheader: 'marketing',
    items: [
      // { title: 'SMS Campaigns', path: '/marketing/sms-campaigns', icon: ICONS.chat },
      {
        title: 'website',
        path: '/website',
        icon: ICONS.website,
        roles: [TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager],
      },
    ],
    roles: [TENANT_ROLES.companyOwner, TENANT_ROLES.companyManager],
  },

  {
    subheader: 'management',
    items: [
      {
        title: 'team',
        path: '/team',
        icon: ICONS.team,
        // children: [
        //   { title: 'Four', path: '/dashboard/user/four' },
        //   { title: 'Five', path: '/dashboard/user/five' },
        //   { title: 'Six', path: '/dashboard/user/six' },
        // ],
        roles: [TENANT_ROLES.companyOwner],
      },
      {
        title: 'settings',
        path: '/settings',
        icon: ICONS.settings,
        roles: [TENANT_ROLES.companyOwner],
      },
    ],
    roles: [TENANT_ROLES.companyOwner],
  },
];

export const navConfigMap = {
  pay: navPayConfig,
  siyaha: navSiyahaConfig,
  maktb: navOfficeConfig,
};

// eslint-disable-next-line no-nested-ternary
export default selectedProduct === 'pay'
  ? navPayConfig
  : selectedProduct === 'siyaha'
  ? navSiyahaConfig
  : navOfficeConfig;
