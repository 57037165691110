import { Box, Button, Card, CardContent, Container, Divider, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { useFormik } from 'formik';
import parsePhoneNumber from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import InfoBox from '../../../components/InfoBox';
import { Loader } from '../../../components/Loader';
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import * as contactActions from '../../../models/contacts/actions';
import { selectors as contactSelectors } from '../../../models/contacts/reducers';
import * as reservationsActions from '../../../models/t-reservations/actions';
import { selectors as reservationsSelectors } from '../../../models/t-reservations/reducers';
import Notes, { TabLabel } from '../../notes/Notes';
import { columns } from '../reservations/ReservationsView';
import GuestForm from './GuestForm';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 0,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const EditGuest = () => {
  const { translate } = useLocales();
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const { id } = useParams();
  const dispatch = useDispatch();
  const contact = useSelector(contactSelectors.contact);
  const [loading, setLoading] = useState(false);
  const [tabIdx, setTabIdx] = useState(0);

  const reservations = useSelector(reservationsSelectors.reservations);
  const getReservationsLoading = useSelector(reservationsSelectors.getReservationsLoading);

  const navigate = useNavigate();

  /* looks ugly, but without a rerender is better */
  let code = '';
  let phone = '';

  if (contact) {
    const parsedPhoneNumber = parsePhoneNumber(`+${contact.mobile_number}`);
    code = parsedPhoneNumber?.countryCallingCode || '';
    phone = parsedPhoneNumber?.nationalNumber || '';
  }
  const callback = (type) => {
    if (type === 'success') {
      toast.success(translate('addContactScreen.successUpdate'));
      setLoading(false);
    } else {
      toast(translate('Error'), { type: 'error' });
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      phone,
      code,
      name: contact?.name || '',
      gender: contact?.gender || '',
      email: contact?.email || '',
      is_property_owner: !!contact?.is_property_owner,
      is_property_buyer: !!contact?.is_property_buyer,
      is_property_renter: !!contact?.is_property_renter,
      is_property_broker: !!contact?.is_property_broker,
      is_property_agent: !!contact?.is_property_agent,
      is_property_representative: !!contact?.is_property_representative,
      note: contact?.note || '',
      is_siyaha: true,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      const { ...rest } = values;
      dispatch(
        contactActions.editContactRequest({
          id: contact.id,
          callback,
          values: rest,
        })
      );
    },
  });

  useEffect(() => {
    dispatch(contactActions.getContactByIdRequest(id));
  }, []);

  useEffect(() => {
    dispatch(reservationsActions.getReservationsRequest({ tenant_contact_id: id }));
  }, [id]);

  const toggleArchive = () => () => {
    dispatch(contactActions.toggleArchiveContactRequest({ id: contact.id, callback }));
  };

  const handleTabChange = (evt, value) => {
    setTabIdx(value);
  };

  const createdAt = contact?.created_at || new Date();

  const handleRow = (params) => {
    navigate(`/siyaha/reservations/${params.row.id}`);
  };

  return (
    <Page title={translate('guest.information')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
          <InfoBox
            label={translate('guests')}
            number={contact?.id}
            date={createdAt}
            backButtonLink="/siyaha/crm/guests"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'end',
                height: '100%',
                justifyContent: 'end',
              }}
            >
              <Button
                onClick={toggleArchive()}
                variant="outlined"
                sx={{
                  color: '#999',
                  borderColor: '#999',
                  marginLeft: 2,
                  '&:hover': {
                    color: '#999',
                    borderColor: '#999',
                    backgroundColor: 'transparent',
                  },
                }}
                disabled={loading}
              >
                {!loading && translate(contact?.is_archived ? 'contact.unarchiveButton' : 'contact.archiveButton')}
                {loading && <Loader />}
              </Button>
              <Button
                variant="contained"
                sx={{ color: 'white', marginLeft: 2 }}
                onClick={formik.submitForm}
                disabled={loading || !formik.dirty}
              >
                {!loading && translate('guest.saveButton')}
                {loading && <Loader />}
              </Button>
            </Box>
          </InfoBox>
        </Box>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={8}
            sx={{
              [theme.breakpoints.down('lg')]: {
                margin: 'auto',
              },
            }}
          >
            <GuestForm formik={formik} phoneNumberDisabled />
            <Box>
              <Tabs value={tabIdx} onChange={handleTabChange} sx={{ pt: 2 }}>
                <Tab
                  label={
                    <TabLabel labelText={translate('siyaha.reservations')} badgeContent={contact?.total_reservations} />
                  }
                  {...a11yProps(0)}
                />
              </Tabs>

              <Divider sx={{ mb: 2 }} />

              <TabPanel value={tabIdx} index={0}>
                {contact?.total_reservations > 0 ? (
                  <Card className="no-animation">
                    <CardContent sx={{ p: 0 }}>
                      <Box>
                        <DataGrid
                          loading={getReservationsLoading}
                          onRowClick={handleRow}
                          getRowClassName={() => `clickable`}
                          columns={columns(translate)}
                          rows={reservations || []}
                          disableColumnFilter
                          disableColumnSelector
                          hideFooterSelectedRowCount
                          disableSelectionOnClick
                          rowsPerPageOptions={[10]}
                          pageSize={10}
                          autoHeight
                          sx={{
                            '& .datagrid-header': {
                              backgroundColor: '#F4F6F8',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                              borderRadius: 0,
                            },
                            border: 'none',
                          }}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                ) : (
                  <Card>
                    <CardContent>
                      <Typography variant="subtitle1">{translate('contact.noReservations')}</Typography>
                    </CardContent>
                  </Card>
                )}
              </TabPanel>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Notes id={id} type={'contact'} product={'siyaha'} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default EditGuest;
