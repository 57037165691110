import { Box, Button, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';

import useLocales from '../../hooks/useLocales';
import * as dealsActions from '../../models/deals/actions';
import { selectors as dealsSelectors } from '../../models/deals/reducers';
import BoardColumn from './BoardColumn';
import BoardRow from './BoardRow';

const Board = ({ data, forwardOnly, onItemMovement, ...props }) => {
  const dispatch = useDispatch();
  const stages = useSelector(dealsSelectors.stages);
  const { translate } = useLocales();

  const [isDragging, setIsDragging] = useState(false);
  const [destination, setDestination] = useState(null);

  const columns = data;

  const handleLoadMore = (stage) => {
    dispatch(dealsActions.loadMoreDealsRequest({ stage, page: stages[stage].page + 1 }));
  };

  const handleItemMovement = (itemId, from, to) => {
    onItemMovement(itemId, from, to);
  };

  const handleDragUpdate = (evt) => {
    setDestination(evt.destination);
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (evt) => {
    const { source, destination, draggableId } = evt;
    setIsDragging(false);
    setDestination(null);

    if (!destination || destination.droppableId === source.droppableId) {
      return;
    }

    // restrict movement only to the right =>>
    if (forwardOnly) {
      const keys = Object.keys(columns);
      const sourceIndex = keys.findIndex((item) => item === source.droppableId);
      const destIndex = keys.findIndex((item) => item === destination.droppableId);

      if (!(sourceIndex < destIndex)) {
        return;
      }
    }

    const id = parseInt(draggableId, 10);
    handleItemMovement(id, source.droppableId, destination.droppableId);
  };

  const loadDeals = (stage) =>
    new Promise((resolve) => {
      if (stages[stage].loading) return;
      dispatch(
        dealsActions.loadMoreDealsRequest({
          stage,
          page: 1,
          callback: resolve,
        })
      );
    });

  useEffect(() => {
    const fetchDeals = async () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const stage of ['new', 'visit', 'negotiation', 'won', 'lost']) {
        // eslint-disable-next-line no-await-in-loop
        await loadDeals(stage);
      }
    };

    fetchDeals();
  }, []);

  return (
    <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragUpdate={handleDragUpdate}>
      <Box sx={{ ...props.sx, width: 1, overflowX: 'auto', display: 'flex', flexWrap: 'nowrap' }}>
        {Object.entries(columns).map(([key, { title, borderColor }], i) => (
          <BoardColumn
            droppableId={key}
            key={i}
            label={`${title}${stages ? ` (${stages[key].total})` : ''}`}
            borderColor={borderColor}
            isDragging={isDragging || stages[key].loading}
            isTargetDrop={isDragging && destination && destination.droppableId === key}
          >
            {stages && stages[key].data.map((deal, j) => <BoardRow key={deal.id} deal={deal} index={j} />)}
            {stages[key].loading && stages[key].page === 1 && (
              <Box
                sx={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                  height: 200,
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!stages[key].loading && stages[key].total > 100 && stages[key].page < stages[key].lastPage && (
              <Button
                onClick={() => handleLoadMore(key)}
                disabled={stages[key].loading}
                sx={{ width: '100%', borderRadius: 0 }}
                variant="outlined"
                color="secondary"
              >
                {translate('Load More')}
              </Button>
            )}
          </BoardColumn>
        ))}
      </Box>
    </DragDropContext>
  );
};

export default Board;
