import { Avatar, Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
// @mui
import { alpha } from '@mui/material/styles';
import { useState } from 'react';
// components
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { IconButtonAnimate } from '../../../components/animate';
import MenuPopover from '../../../components/MenuPopover';
import useLocales from '../../../hooks/useLocales';
import { selectors as userSelectors } from '../../../models/user/reducers';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'userMenu.yourProfile',
    linkTo: '/user/profile',
  },
  {
    label: 'userMenu.yourWorkspaces',
    linkTo: '/user/workspaces',
  },
  {
    label: 'userMenu.yourInvitations',
    linkTo: '/user/invitations',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const { translate, onChangeLang, currentLang } = useLocales();

  const userInfo = useSelector(userSelectors.me);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src="" alt="Rayan Moran" />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userInfo?.name}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {translate(option.label)}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem
          onClick={() => {
            onChangeLang(currentLang.value === 'ar' ? 'en' : 'ar');
          }}
          sx={{ m: 1 }}
        >
          {currentLang.value === 'ar' ? 'English' : 'اللغة العربية'}
        </MenuItem>
        <MenuItem
          onClick={() => {
            localStorage.removeItem('token');
            localStorage.removeItem('user_role_id');
            localStorage.removeItem('tenant_role');
            localStorage.removeItem('workspaces');
            localStorage.removeItem('tenant_url');
            localStorage.removeItem('tenant_index');
            localStorage.removeItem('tenant_id');
            window.location.reload(true);
          }}
          sx={{ m: 1 }}
        >
          {translate('logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
