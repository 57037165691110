import {
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ContactSearchBox from '../../components/ContactSearchBox';
import { Loader } from '../../components/Loader';
import useLocales from '../../hooks/useLocales';
import { selectors as dealSelectors } from '../../models/deals/reducers';
import { selectors as subscriptionSelectors } from '../../models/subscription/reducers';

const AddDealModal = ({ formik, open, onClose, showSearchbar = true, isBuy = true, title }) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const navigate = useNavigate();

  const createLoading = useSelector(dealSelectors.createLoading);

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');

  const residentialRentOnly = [
    { value: 'land', text: translate('deal.properties.land') },
    { value: 'farm', text: translate('deal.properties.farm') },
    { value: 'istraha', text: translate('deal.properties.istraha') },
    { value: 'tower_apartment', text: translate('deal.properties.tower_apartment') },
    { value: 'building_apartment', text: translate('deal.properties.building_apartment') },
    { value: 'villa_apartment', text: translate('deal.properties.villa_apartment') },
    { value: 'villa_floor', text: translate('deal.properties.villa_floor') },
    { value: 'townhouse', text: translate('deal.properties.townhouse') },
    { value: 'duplex', text: translate('deal.properties.duplex') },
    { value: 'villa', text: translate('deal.properties.villa') },
    { value: 'mansion', text: translate('deal.properties.mansion') },
    { value: 'resort', text: translate('deal.properties.resort') },
  ];

  const residentialSellOnly = [
    { value: 'land', text: translate('deal.properties.land') },
    { value: 'farm', text: translate('deal.properties.farm') },
    { value: 'istraha', text: translate('deal.properties.istraha') },
    { value: 'tower_apartment', text: translate('deal.properties.tower_apartment') },
    { value: 'building_apartment', text: translate('deal.properties.building_apartment') },
    { value: 'villa_apartment', text: translate('deal.properties.villa_apartment') },
    { value: 'villa_floor', text: translate('deal.properties.villa_floor') },
    { value: 'townhouse', text: translate('deal.properties.townhouse') },
    { value: 'duplex', text: translate('deal.properties.duplex') },
    { value: 'villa', text: translate('deal.properties.villa') },
    { value: 'mansion', text: translate('deal.properties.mansion') },
    { value: 'resort', text: translate('deal.properties.resort') },
  ];

  const commercialRentOnly = [
    { value: 'land', text: translate('deal.properties.land') },
    { value: 'farm', text: translate('deal.properties.farm') },
    { value: 'istraha', text: translate('deal.properties.istraha') },
    { value: 'building', text: translate('deal.properties.building') },
    {
      value: 'store',
      text: translate('deal.properties.store'),
    },
    { value: 'showroom', text: translate('deal.properties.showroom') },
    {
      value: 'office',
      text: translate('deal.properties.office'),
    },
    {
      value: 'storage',
      text: translate('deal.properties.storage'),
    },
    { value: 'resort', text: translate('deal.properties.resort') },
    // { value: 'hotel', text: translate('deal.properties.hotel') },
    // { value: 'compound', text: translate('deal.properties.compound') },
  ];

  const commercialSellOnly = [
    { value: 'land', text: translate('deal.properties.land') },
    { value: 'farm', text: translate('deal.properties.farm') },
    { value: 'istraha', text: translate('deal.properties.istraha') },
    { value: 'building', text: translate('deal.properties.building') },
    {
      value: 'store',
      text: translate('deal.properties.store'),
    },
    { value: 'showroom', text: translate('deal.properties.showroom') },
    {
      value: 'office',
      text: translate('deal.properties.office'),
    },
    {
      value: 'storage',
      text: translate('deal.properties.storage'),
    },
    { value: 'resort', text: translate('deal.properties.resort') },

    // { value: 'hotel', text: translate('deal.properties.hotel') },
    // { value: 'compound', text: translate('deal.properties.compound') },
  ];

  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: 1, p: 2 }}>
        <Typography sx={{ p: 2, mb: 4 }} variant="h5">
          {title}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <button type="submit" style={{ display: 'none' }}>
            Submit
          </button>

          {showSearchbar && (
            <>
              <FormLabel sx={{ color: '#212B36' }}>{translate('deal.contactName')}</FormLabel>
              <Box sx={{ display: 'flex', w: 1, mb: 4 }}>
                <ContactSearchBox name="tenant_contact_id" formik={formik} filters={{ is_office: true }} />
              </Box>
            </>
          )}

          <FormControl
            sx={{ p: 1 }}
            error={formik.touched.category && !!formik.errors.category}
            onChange={formik.handleChange}
            fullWidth
          >
            <FormLabel sx={{ color: '#212B36' }}>{translate('deal.category')}</FormLabel>

            <RadioGroup name="category" row sx={{ pl: 1 }} value={formik.values.category}>
              <FormControlLabel label={translate('deal.commercial')} control={<Radio value="commercial" />} />
              <FormControlLabel label={translate('deal.residential')} control={<Radio value="residential" />} />
            </RadioGroup>
          </FormControl>

          <FormControl fullWidth sx={{ p: 1 }}>
            <FormLabel sx={{ color: '#212B36' }}>{translate('deal.purpose')}</FormLabel>
            <RadioGroup
              name="purpose"
              defaultValue="rent"
              row
              sx={{ pl: 1 }}
              value={formik.values.purpose}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                label={translate('deal.rent')}
                onChange={formik.handleChange}
                control={<Radio value="rent" />}
              />
              <FormControlLabel
                label={translate(isBuy ? 'deal.buy' : 'deal.sell')}
                control={<Radio value={isBuy ? 'buy' : 'sell'} />}
                onChange={formik.handleChange}
              />
            </RadioGroup>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2, mb: 2 }} error={formik.touched.type && formik.errors.type}>
            <InputLabel id="deal-type" shrink>
              {translate('deal.realEstateType')}
            </InputLabel>
            <Select
              labelId="deal-type"
              input={<OutlinedInput notched name="type" label={translate('deal.realEstateType')} />}
              value={formik.values.type}
              onChange={formik.handleChange}
              disabled={!formik.values.purpose && !formik.values.category}
            >
              {formik.values.category === 'residential' &&
                formik.values.purpose === 'rent' &&
                residentialRentOnly.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}

              {formik.values.category === 'residential' &&
                (formik.values.purpose === 'sell' || formik.values.purpose === 'buy') &&
                residentialSellOnly.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}

              {formik.values.category === 'commercial' &&
                formik.values.purpose === 'rent' &&
                commercialRentOnly.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}

              {formik.values.category === 'commercial' &&
                (formik.values.purpose === 'sell' || formik.values.purpose === 'buy') &&
                commercialSellOnly.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </form>

        <Divider />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <Button
            disabled={createLoading}
            variant="outlined"
            sx={{ color: 'black', borderColor: '#919EAB66', mr: 2 }}
            onClick={onClose}
          >
            {translate('deal.cancelButton')}
          </Button>

          <Button
            disabled={!formik.isValid || createLoading}
            variant="contained"
            sx={{ color: 'white' }}
            onClick={formik.submitForm}
          >
            {!createLoading && translate('deal.createButton')}
            {createLoading && <Loader />}
          </Button>
        </Box>
      </Box>

      {!showSearchbar && activeMaktbSubscription?.is_trial && (
        <Box
          style={{
            backgroundColor: theme.palette.grey[300],
            paddingTop: 15,
            paddingBottom: 15,
            textAlign: 'center',
            color: 'black',
          }}
        >
          <Typography variant="h7" paragraph>
            {translate('addListingsModal.trialMessage')}
          </Typography>
          <Button
            variant="contained"
            sx={{ color: 'white', pt: 1, pb: 1 }}
            onClick={() => {
              navigate(`/cart/maktb`);
            }}
          >
            {translate('navbar.upgradeButton')}
          </Button>
        </Box>
      )}
    </Dialog>
  );
};
export default AddDealModal;
