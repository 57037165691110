import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';

import useLocales from '../hooks/useLocales';

const ClickToCopy = ({ text, label = null, skeleton = false, ...props }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { translate } = useLocales();

  const clickToCopyMessage = translate('clickToCopy');
  const copiedMessage = `${translate('copied')}: ${text}`;

  const [tooltipTitle, setTooltipTitle] = useState(clickToCopyMessage);

  const handleCopy = () => {
    if (!navigator.clipboard) {
      return;
    }

    navigator.clipboard.writeText(text).then(() => {
      setTooltipTitle(copiedMessage);
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipTitle(clickToCopyMessage);
        setTooltipOpen(false);
      }, 1000);
    });
  };

  const handleTooltipClose = () => {
    if (tooltipTitle !== copiedMessage) {
      setTooltipOpen(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        visibility: skeleton ? 'hidden' : 'visible',
      }}
    >
      <Tooltip title={tooltipTitle} open={tooltipOpen} onClose={handleTooltipClose}>
        <IconButton
          onClick={handleCopy}
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={handleTooltipClose}
          sx={{ padding: 0 }}
        >
          <ContentCopyIcon {...props} />
        </IconButton>
      </Tooltip>
      {label && (
        <Typography
          sx={{
            borderBottom: '1px dashed',
            marginLeft: 1,
          }}
        >
          {label}
        </Typography>
      )}
    </Box>
  );
};

export default ClickToCopy;
