// @mui
import { Box, Button, Card, CardContent, Checkbox, Container, FormControlLabel, Grid, Typography } from '@mui/material';
// hooks
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// components
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import { selectors as subscriptionSelectors } from '../../../models/subscription/reducers';
import tPropertyRequestsOptsServices from '../../../models/t-property-requests-opts/services';
import * as userActions from '../../../models/user/actions';
import * as utilActions from '../../../models/utils/actions';
import * as workspaceActions from '../../../models/workspace/actions';
import { selectors as workspaceSelectors } from '../../../models/workspace/reducers';

// ----------------------------------------------------------------------

export default function Requests() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const workspaceInformationData = useSelector(workspaceSelectors.workspaceInformationData);
  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);

  const activeSiyahaSubscription = activeSubscriptions?.find((sub) => sub.solution === 'siyaha');
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');

  const commercialTypes = [
    { id: 'building', name_en: 'building', name_ar: 'مبنى' },
    { id: 'office', name_en: 'office', name_ar: 'مكتب' },
    { id: 'store', name_en: 'store', name_ar: 'محل' },
    { id: 'storage', name_en: 'warehouse', name_ar: 'مستودع' },
    { id: 'land', name_en: 'land', name_ar: 'أرض' },
    { id: 'farm', name_en: 'farm', name_ar: 'مزرعة' },
    { id: 'istraha', name_en: 'istraha', name_ar: 'استراحة' },
  ];

  const residentialTypes = [
    { id: 'apartment', name_en: 'apartment', name_ar: 'شقة' },
    { id: 'villa', name_en: 'villa', name_ar: 'فيلا' },
    { id: 'land', name_en: 'land', name_ar: 'أرض' },
    { id: 'building_apartment', name_en: 'building_apartment', name_ar: 'شقة في مبنى' },
    { id: 'villa_apartment', name_en: 'villa_apartment', name_ar: 'شقة في فيلا' },
    { id: 'duplex', name_en: 'duplex', name_ar: 'دوبلكس' },
    { id: 'townhouse', name_en: 'townhouse', name_ar: 'تاونهاوس' },
    { id: 'mansion', name_en: 'mansion', name_ar: 'قصر' },
    { id: 'villa_floor', name_en: 'villa_floor', name_ar: 'دور في فيلا' },
    { id: 'farm', name_en: 'farm', name_ar: 'مزرعة' },
    { id: 'istraha', name_en: 'istraha', name_ar: 'استراحة' },
    { id: 'tower_apartment', name_en: 'tower_apartment', name_ar: 'برج شقة في برج' },
  ];

  useEffect(() => {
    dispatch(utilActions.getCountriesRequest());
    dispatch(workspaceActions.getWorkspaceInformationRequest());
    dispatch(userActions.getUserInformationRequest());
  }, []);

  const callback = (type) => {
    if (type === 'success') {
      toast(translate('setting.propertyRequest.successUpdate'), { type: 'success' });
      dispatch(workspaceActions.getWorkspaceInformationRequest());
      dispatch(userActions.getUserWorkspacesRequest());
      dispatch(userActions.getUserInformationRequest());
    } else {
      toast('Error!', { type: 'error' });
    }
  };

  // check if one of the objects has the id === 'commercial' or 'residential':
  const isCommercialSelected = workspaceInformationData?.t_property_request_opts?.allowed_categories?.some(
    (c) => c.id === 'commercial'
  );
  const isResidentialSelected = workspaceInformationData?.t_property_request_opts?.allowed_categories?.some(
    (c) => c.id === 'residential'
  );

  // check if one of the objects has the id === 'rent' or 'sell' or 'invest' or 'buy':
  const isRentSelected = workspaceInformationData?.t_property_request_opts?.allowed_purposes?.some(
    (c) => c.id === 'rent'
  );
  const isSellSelected = workspaceInformationData?.t_property_request_opts?.allowed_purposes?.some(
    (c) => c.id === 'sell'
  );
  const isInvestSelected = workspaceInformationData?.t_property_request_opts?.allowed_purposes?.some(
    (c) => c.id === 'invest'
  );
  const isBuySelected = workspaceInformationData?.t_property_request_opts?.allowed_purposes?.some(
    (c) => c.id === 'buy'
  );

  const formik = useFormik({
    initialValues: {
      is_property_request_active:
        !!workspaceInformationData?.t_property_request_opts?.is_property_request_active || false,
      is_commercial_selected: !!isCommercialSelected || false,
      is_residential_selected: !!isResidentialSelected || false,
      is_rent_selected: !!isRentSelected || false,
      is_sell_selected: !!isSellSelected || false,
      is_invest_selected: !!isInvestSelected || false,
      is_buy_selected: !!isBuySelected || false,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      tPropertyRequestsOptsServices
        .setOptions(values)
        .then(() => {
          callback('success');
        })
        .catch(() => {
          callback('error');
        });
    },
  });

  const handleSave = () => {
    formik.handleSubmit();
  };

  return (
    <Page title={translate('setting.propertyRequest.title')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('setting.propertyRequest.title')}
            </Typography>
          </Box>
          {!activeMaktbSubscription?.is_trial && (
            <Button variant="contained" sx={{ color: 'white', pt: 1, pb: 1 }} onClick={() => handleSave()}>
              {translate('setting.propertyRequest.saveButton')}
            </Button>
          )}
        </Box>

        <Card>
          <CardContent>
            <Typography variant="h6" paragraph>
              {translate('setting.propertyRequest.activationTitle')}
            </Typography>
            {/* <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControlLabel
                  label={translate('setting.propertyRequest.activationListingDescription')}
                  control={
                    <Checkbox name="is_property_listing_active" checked={formik.values.is_property_listing_active} />
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid> */}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControlLabel
                  label={translate('setting.propertyRequest.activationDescription')}
                  control={
                    <Checkbox
                      name="is_property_request_active"
                      checked={formik.values.is_property_request_active}
                      disabled={activeMaktbSubscription?.is_trial}
                    />
                  }
                  onChange={formik.handleChange}
                />
                {activeMaktbSubscription?.is_trial && (
                  <span
                    style={{
                      backgroundColor: '#ddd',
                      marginLeft: 7,
                      marginRight: 7,
                      paddingRight: 7,
                      paddingLeft: 7,
                      paddingTop: 3,
                      paddingBottom: 3,
                      borderRadius: 5,
                    }}
                  >
                    {translate('paidPlan')}
                  </span>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {!activeMaktbSubscription?.is_trial && (
          <Card sx={{ mt: 3 }}>
            <CardContent>
              <Typography variant="h6" paragraph>
                {translate('setting.propertyRequest.configurations')}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" paragraph>
                    {translate('setting.propertyRequest.category')}
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        label={translate('setting.propertyRequest.commercial')}
                        control={
                          <Checkbox name="is_commercial_selected" checked={formik.values.is_commercial_selected} />
                        }
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        label={translate('setting.propertyRequest.residential')}
                        control={
                          <Checkbox name="is_residential_selected" checked={formik.values.is_residential_selected} />
                        }
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* <Grid item xs={12}>
                <Typography variant="h6" paragraph>
                  {translate('setting.propertyRequest.allowedPropertyTypes')}
                </Typography>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    options={propertyTypes}
                    getOptionLabel={(d) => (languageCode === 'ar' ? d.name_ar : d.name_en)}
                    value={formik.values.selectedPropertyTypes}
                    onChange={handlePropertyTypesChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={translate('setting.propertyRequest.propertyTypes')}
                        name="property_types"
                        helperText={translate('setting.propertyRequest.propertyTypesHelper')}
                      />
                    )}
                    renderOption={(props, option, state) => (
                      <span {...props} key={option.id}>
                        {languageCode === 'ar' ? option.name_ar : option.name_en}
                      </span>
                    )}
                    placeholder="Districts"
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const name = languageCode === 'ar' ? option.name_ar : option.name_en;

                        return (
                          <Chip
                            key={index}
                            sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                            label={name}
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                  />
                </Grid>
              </Grid> */}

                <Grid item xs={12}>
                  <Typography variant="h6" paragraph>
                    {translate('setting.propertyRequest.purposes')}
                  </Typography>

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        label={translate('setting.propertyRequest.rent')}
                        control={<Checkbox name="is_rent_selected" checked={formik.values.is_rent_selected} />}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        label={translate('setting.propertyRequest.buy')}
                        control={<Checkbox name="is_buy_selected" checked={formik.values.is_buy_selected} />}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* <Grid item xs={12}>
                <Typography variant="h6" paragraph>
                  {translate('setting.propertyRequest.allowedLocations')}
                </Typography>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth error={formik.touched.country_id && formik.errors.country_id}>
                      <InputLabel id="country-label" shrink>
                        {translate('Country')}
                      </InputLabel>
                      <Select
                        name="country_id"
                        labelId="country-select"
                        label={translate('country')}
                        onChange={formik.handleChange}
                        value={formik.values.country_id}
                        input={<OutlinedInput notched name="country_id" label={translate('Country')} />}
                        disabled={!!formik.values.city_id}
                      >
                        {!!countries &&
                          countries.map((c) => (
                            <MenuItem key={c.id} value={c.id}>
                              {languageCode === 'ar' ? c.name_ar : c.name_en}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth error={formik.touched.city_id && formik.errors.city_id}>
                      <InputLabel id="city-label" shrink>
                        {translate('City')}
                      </InputLabel>
                      <Select
                        disabled={formik.values.districts_ids?.length > 0}
                        value={formik.values.city_id}
                        label={translate('City')}
                        onChange={formik.handleChange}
                        labelId="city-label"
                        input={<OutlinedInput notched name="city_id" label={translate('City')} />}
                      >
                        {!!cities &&
                          cities.map((c) => (
                            <MenuItem key={c.id} value={c.id}>
                              {languageCode === 'ar' ? c.name_ar : c.name_en}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      options={districts || []}
                      loading={districtsLoading}
                      groupBy={(district) => (languageCode === 'ar' ? district.city_name_ar : district.city_name_en)}
                      getOptionLabel={(d) => (languageCode === 'ar' ? d.name_ar : d.name_en)}
                      value={formik.values.districts}
                      onChange={handleDistrictsChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={translate('Districts')}
                          name="districts"
                          helperText={translate('setting.propertyRequest.districtsHelper')}
                        />
                      )}
                      renderOption={(props, option, state) => (
                        <span {...props} key={option.id}>
                          {languageCode === 'ar' ? option.name_ar : option.name_en}
                        </span>
                      )}
                      placeholder="Districts"
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const name = languageCode === 'ar' ? option.name_ar : option.name_en;

                          return (
                            <Chip
                              key={index}
                              sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                              label={name}
                              {...getTagProps({ index })}
                            />
                          );
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        )}
      </Container>
    </Page>
  );
}
