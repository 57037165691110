import { Box, Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Page from '../../components/Page';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import * as projectActions from '../../models/projects/actions';
import { selectors as projectSelectors } from '../../models/projects/reducers';
import * as teamActions from '../../models/team/actions';
import { selectors as teamSelectors } from '../../models/team/reducers';
import MemberCardModal from '../team/MemberCardModal';
import ProjectForm from './ProjectForm';

export const updateProjectSchema = Yup.object({
  name_ar: Yup.string().required('Required'),
  name_en: Yup.string().required('Required'),
  description_ar: Yup.string().max(675, 'Description should be up to 675 characters').required('Required'),
  description_en: Yup.string().max(675, 'Description should be up to 675 characters').required('Required'),
  availability_status: Yup.string().oneOf(['available', 'unavailable', 'sold', 'soon']).optional(),
  area: Yup.number().integer('Must be an integer').min(0).optional(),
  number_of_units: Yup.number().integer('Must be an integer').min(0).optional(),
  number_of_villas: Yup.number().integer('Must be an integer').min(0).optional(),
  number_of_buildings: Yup.number().integer('Must be an integer').min(0).optional(),
  number_of_apartments: Yup.number().integer('Must be an integer').min(0).optional(),
  number_of_stores: Yup.number().integer('Must be an integer').min(0).optional(),
  number_of_townhouse: Yup.number().integer('Must be an integer').min(0).optional(),
  cover_image_url: Yup.string().url('Invalid URL').nullable().optional(),
  plumbing_warranty: Yup.number().min(0).optional(),
  electric_warranty: Yup.number().min(0).optional(),
  structural_warranty: Yup.number().min(0).optional(),
  published_on_website: Yup.boolean().optional(),
  published_on_app: Yup.boolean().optional(),
  longitude: Yup.number().optional(),
  latitude: Yup.number().optional(),
  project_pdf_url: Yup.string().url('Invalid URL').optional(),
  youtube_video_url: Yup.string().url('Invalid URL').optional(),
  virtual_tour_url: Yup.string().url('Invalid URL').optional(),
  country_id: Yup.number(),
  city_id: Yup.number(),
  district_id: Yup.number(),
  is_wafi_ad: Yup.boolean().optional(),
  wafi_license_number: Yup.string().optional(),
  sort: Yup.number().integer().optional(),
  advertiser_type: Yup.string().oneOf(['broker', 'advertiser']).optional(),
  rega_ad_number: Yup.string().optional(),
  rega_advertiser_number: Yup.string().optional(),
});

const EditProject = () => {
  const { translate } = useLocales();
  const { themeStretch } = useSettings();
  const { id } = useParams();
  const dispatch = useDispatch();
  const project = useSelector(projectSelectors.project);
  const [loading, setLoading] = useState(false);
  const [showMemberCardModal, setShowMemberCardModal] = useState(false);

  const memberID = localStorage.getItem('member_id');
  const member = useSelector(teamSelectors.member);

  const tenantRole = localStorage.getItem('tenant_role');
  const isDisabled = tenantRole === '6';

  const callback = (type) => {
    if (type === 'success') {
      toast.success(translate('project.successUpdate'));
      setLoading(false);
    } else {
      toast(translate('Error'), { type: 'error' });
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: project?.id,
      name_ar: project?.name_ar || '',
      name_en: project?.name_en || '',
      description_ar: project?.description_ar || '',
      description_en: project?.description_en || '',
      availability_status: project?.availability_status || 'unavailable',
      area: project?.area || 0,
      number_of_units: project?.number_of_units || 0,
      number_of_villas: project?.number_of_villas || 0,
      number_of_buildings: project?.number_of_buildings || 0,
      number_of_apartments: project?.number_of_apartments || 0,
      number_of_stores: project?.number_of_stores || 0,
      number_of_townhouse: project?.number_of_townhouse || 0,
      cover_image_url: project?.cover_image_url || null,
      plumbing_warranty: project?.plumbing_warranty || 0,
      electric_warranty: project?.electric_warranty || 0,
      structural_warranty: project?.structural_warranty || 0,
      published_on_website: !!project?.published_on_website || false,
      country_id: project?.country?.id || '',
      city_id: project?.city?.id || '',
      district_id: project?.district?.id || '',
      longitude: project?.longitude || '',
      latitude: project?.latitude || '',
      project_pdf_url: project?.project_pdf_url || '',
      youtube_video_url: project?.youtube_video_url || '',
      virtual_tour_url: project?.virtual_tour_url || '',
      is_wafi_ad: !!project?.is_wafi_ad || false,
      wafi_license_number: project?.wafi_license_number || '',
      sort: project?.sort || 0,
      advertiser_type: project?.advertiser_type || 'broker',
      rega_ad_number: project?.rega_ad_number || '',
      rega_advertiser_number: project?.rega_advertiser_number || '',
      is_whatsapp_number_enabled: !!project?.is_whatsapp_number_enabled || false,
      is_member_whatsapp_number_enabled: !!project?.is_member_whatsapp_number_enabled || false,
      whatsapp_number: project?.whatsapp_number || '',
      properties: project?.properties || [],
    },
    validationSchema: updateProjectSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      const transformedValues = {
        ...values,
        properties: values.properties.map((property) => parseInt(property.id, 10)),
      };
      dispatch(
        projectActions.editProjectRequest({
          id: project.id,
          callback,
          ...transformedValues,
        })
      );
    },
  });

  useEffect(() => {
    dispatch(projectActions.getProjectRequest(id));
  }, []);

  useEffect(() => {
    dispatch(teamActions.getMemberByIdRequest({ memberID }));
  }, [memberID]);

  const toggleArchive = () => () => {
    dispatch(projectActions.toggleArchiveProjectRequest({ id: project.id, callback }));
  };

  return (
    <Page title={translate('project.information')}>
      <MemberCardModal
        open={showMemberCardModal}
        onClose={() => setShowMemberCardModal(false)}
        onSubmit={() => setShowMemberCardModal(false)}
        member={member}
        memberID={memberID}
        project={project}
      />
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
          <Typography variant="h3" component="h1" sx={{ mb: 2 }}>
            {translate('projects')}
          </Typography>
          <Box sx={{ position: 'center' }}>
            <Button
              variant="contained"
              sx={{ color: 'white', pt: 1, pb: 1, m: 1, mx: 2 }}
              onClick={() => setShowMemberCardModal(true)}
            >
              {translate('member.card')}
            </Button>
            {!isDisabled && (
              <>
                <Button
                  onClick={toggleArchive()}
                  variant="outlined"
                  sx={{
                    color: '#999',
                    borderColor: '#999',
                    marginLeft: 1,
                    '&:hover': {
                      color: '#999',
                      borderColor: '#999',
                      backgroundColor: 'transparent',
                    },
                  }}
                  disabled={loading}
                >
                  {!loading && translate(project?.is_archived ? 'project.unarchiveButton' : 'project.archiveButton')}
                  {loading && <CircularProgress size={27} />}
                </Button>
                <Button
                  variant="contained"
                  sx={{ color: 'white', marginLeft: 2 }}
                  onClick={formik.submitForm}
                  disabled={loading || !formik.dirty}
                >
                  {!loading && translate('project.saveButton')}
                  {loading && <CircularProgress size={27} />}
                </Button>
              </>
            )}
          </Box>
        </Box>
        <Grid container spacing={2}>
          <ProjectForm formik={formik} />
        </Grid>
      </Container>
    </Page>
  );
};

export default EditProject;
