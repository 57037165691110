import { Box, Button, Dialog, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import useLocales from '../../hooks/useLocales';
import { selectors as subscriptionSelectors } from '../../models/subscription/reducers';
import * as teamActions from '../../models/team/actions';
import { TENANT_ROLES } from '../../utils/constants';

const editMemberRoleSchema = Yup.object({
  role: Yup.number()
    .required()
    .oneOf([TENANT_ROLES.companyManager, TENANT_ROLES.companyAssociate, TENANT_ROLES.companyMember]),
});

const MemberChangeRoleModal = ({ open, onClose, onSubmit, member }) => {
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);

  const activeSiyahaSubscription = activeSubscriptions?.find((sub) => sub.solution === 'siyaha');
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');

  const callback = (type) => {
    if (type === 'success') {
      toast(translate('member.successEditMember'), { type: 'success' });
      dispatch(teamActions.getMembersRequest());
    } else {
      toast(translate('member.failureEditMember'), { type: 'error' });
    }
  };

  const formik = useFormik({
    initialValues: {
      role: '',
    },
    isInitialValid: false,
    validationSchema: editMemberRoleSchema,
    onSubmit: (values) => {
      dispatch(teamActions.changeRoleRequest({ callback, member, roleId: values.role }));
      onSubmit(values);
    },
  });
  return (
    <Dialog open={open}>
      <Box sx={{ minHeight: 100, minWidth: 400, p: 2 }}>
        <Typography variant="h6" sx={{ p: 2, color: '#212B36' }}>
          {translate('teams.roleChange')}
        </Typography>

        <FormControl sx={{ width: 1 }}>
          <InputLabel id="role-select-label">{translate('teams.role')}</InputLabel>
          <Select
            name="role"
            label={translate('teams.role')}
            labelId="role-select-label"
            value={formik.values.role}
            onChange={formik.handleChange}
            error={!!formik.errors.role}
            required
          >
            <MenuItem value={TENANT_ROLES.companyManager}>{translate('teams.managerRole')}</MenuItem>
            <MenuItem disabled={activeMaktbSubscription?.is_trial} value={TENANT_ROLES.companyAssociate}>
              {translate('teams.associateRole')}
              {activeMaktbSubscription?.is_trial && (
                <span
                  style={{
                    backgroundColor: '#ddd',
                    margin: '0 7px',
                    padding: '3px 7px',
                    borderRadius: 5,
                  }}
                >
                  {translate('paidPlan')}
                </span>
              )}
            </MenuItem>
            <MenuItem disabled={activeMaktbSubscription?.is_trial} value={TENANT_ROLES.companyMember}>
              {translate('teams.memberRole')}
              {activeMaktbSubscription?.is_trial && (
                <span
                  style={{
                    backgroundColor: '#ddd',
                    margin: '0 7px',
                    padding: '3px 7px',
                    borderRadius: 5,
                  }}
                >
                  {translate('paidPlan')}
                </span>
              )}
            </MenuItem>
          </Select>
        </FormControl>

        <Box sx={{ display: 'flex', mt: 2, justifyContent: 'flex-end' }}>
          <Button autoFocus variant="outlined" sx={{ color: '#212B36', borderColor: '#919eab52' }} onClick={onClose}>
            {translate('cancel')}
          </Button>
          <Button
            disabled={!formik.isValid}
            variant="contained"
            sx={{ color: 'white', ml: 2 }}
            onClick={formik.submitForm}
          >
            {translate('member.edit')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MemberChangeRoleModal;
