import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  debounce,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '../../components/Loader';
import PropertyCard from '../../components/PropertyCard';
import useLocales from '../../hooks/useLocales';
import * as propertiesActions from '../../models/properties/actions';
import { selectors as propertiesSelectors } from '../../models/properties/reducers';

const DealPropertyModal = ({ open, onClose, onSubmit, filteredProperties }) => {
  const { translate } = useLocales();
  const properties = useSelector(propertiesSelectors.properties);
  const search = useSelector(propertiesSelectors.search);
  const loading = useSelector(propertiesSelectors.loading);
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [isFilterApplied, setIsFilterApplied] = useState(true);

  useEffect(() => {
    dispatch(propertiesActions.getPropertiesRequest({ include_archived: 0 }));
  }, []);

  const onSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    if (value) {
      dispatch(
        propertiesActions.searchPropertiesRequest({
          include_archived: 0,
          unit_number: value,
        })
      );
    }
  };

  let items;
  if (searchValue) {
    items = search;
  } else if (isFilterApplied) {
    items = filteredProperties;
  } else {
    items = properties;
  }

  return (
    <Dialog open={open} fullWidth>
      <Box sx={{ minHeight: 100, minWidth: 400, p: 2 }}>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" paragraph sx={{ mb: 0 }}>
              {translate('properties')}
            </Typography>
            <IconButton sx={{ p: 0 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <TextField
            sx={{ mb: 2 }}
            fullWidth
            onChange={debounce(onSearch, 200)}
            label={translate('deal.searchByUnitNumber')}
          />
          {!searchValue && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isFilterApplied}
                  onChange={(e) => setIsFilterApplied(e.target.checked)}
                  color="primary"
                />
              }
              label={translate('deal.filterProperties')}
            />
          )}
          <Typography sx={{ mb: 1, color: 'text.secondary' }}>{translate('deal.matchedPropertiesInfo')}</Typography>
        </Box>
        <Grid container spacing={2} sx={{ maxHeight: 500 }}>
          {loading && <Loader />}
          {!!items &&
            items.map((p) => (
              <Grid item xs={12} key={p.id}>
                <PropertyCard property={p}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box sx={{ mr: 1 }}>
                        <Typography variant="button">
                          {p.purpose === 'sell'
                            ? p.selling_price
                            : p.rent_price_annually ||
                              p.rent_price_semi_annually ||
                              p.rent_price_quarterly ||
                              p.rent_price_monthly}
                        </Typography>
                        <Typography variant="caption" sx={{ mr: 1 }}>
                          {' '}
                          {translate('SAR')}
                        </Typography>
                        <Typography variant="caption">
                          /
                          {p.purpose === 'sell'
                            ? translate('unit.sellingPrice')
                            : (p.rent_price_annually && translate('deal.rentPriceAnnually')) ||
                              (p.rent_price_semi_annually && translate('deal.rentPriceSemiAnnually')) ||
                              (p.rent_price_quarterly && translate('deal.rentPriceQuarterly')) ||
                              (p.rent_price_monthly && translate('deal.rentPriceMonthly'))}
                        </Typography>
                      </Box>
                    </Box>
                    <Button variant="contained" sx={{ color: 'white' }} onClick={() => onSubmit(p)}>
                      {translate('deal.selectProperty')}
                    </Button>
                  </Box>
                </PropertyCard>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Dialog>
  );
};

export default DealPropertyModal;
