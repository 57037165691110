// @mui
import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
// hooks
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import ApplePayIcon from '../../../assets/apple-pay-icon.svg';
import MadaIcon from '../../../assets/mada-icon.svg';
import MastercardIcon from '../../../assets/mastercard-icon.svg';
import VVisaIcon from '../../../assets/vvisa-icon.svg';
// components
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import { selectors as userSelectors } from '../../../models/user/reducers';

// ----------------------------------------------------------------------

export default function Activations() {
  const { themeStretch } = useSettings();

  const [businessType, setBusinessType] = useState('company');

  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    idFile: Yup.mixed().required('ID file is required'),
    crFile: Yup.mixed().required('CR file is required'),
    bankFile: Yup.mixed().required('Bank account file is required'),
  });

  const userInfo = useSelector(userSelectors.me);
  const selectedProduct = localStorage.getItem('selected_product');

  const formik = useFormik({
    onSubmit: (values) => {
      // dispatch(workspaceActions.editWorkspaceInformationRequest({ ...values, callback }));
    },
    initialValues: {
      business_type: '',
    },
    enableReinitialize: true,
  });

  return (
    <Page title={translate('dashboard')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        {/*  */}
        {/* <Typography variant="h4" sx={{ mb: 3 }}>
          {translate('hi')} {userInfo?.name}! {languageCode === 'ar' ? '' : translate('Welcome back')} 👋
        </Typography> */}

        <Typography variant="h4" gutterBottom>
          {translate('pay.activation.title')}
        </Typography>

        {/* create a form that takes the official name of the company, upload the cr, id, and bank certificate  */}
        <Grid container justifyContent={'center'} mt={3}>
          <Grid item xs={12} md={12} mb={3}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div" sx={{ marginBottom: 2 }}>
                  {translate('pay.activation.rates')}
                </Typography>

                <Grid container spacing={2} alignItems="end" sx={{ marginBottom: 1 }}>
                  <Grid display={'flex'} item xs={3}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <img src={VVisaIcon} alt="Visa" style={{ width: 40, height: 20 }} />
                    </Box>
                    <Typography variant="body1">Visa</Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography variant="body1">2.7% + 1 {translate('SAR')}</Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="end" sx={{ marginBottom: 1 }}>
                  <Grid display={'flex'} item xs={3}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <img src={MastercardIcon} alt="Mastercard" style={{ width: 40, height: 20 }} />
                    </Box>
                    <Typography variant="body1">Mastercard</Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography variant="body1">2.7% + 1 {translate('SAR')}</Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 1 }}>
                  <Grid item xs={3} display={'flex'}>
                    <img src={MadaIcon} alt="Mada" style={{ width: 40, height: 20 }} />
                    <Typography variant="body1">Mada</Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography variant="body1">1% + 1 {translate('SAR')}</Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 2 }}>
                  <Grid item xs={3} display={'flex'}>
                    <img src={ApplePayIcon} alt="Apple Pay" style={{ width: 40, height: 20 }} />
                    <Typography variant="body1">Apple Pay</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body1">{translate('pay.activation.applePayRate')}</Typography>
                  </Grid>
                </Grid>

                <Typography variant="caption" sx={{ color: 'grey.500' }}>
                  {translate('pay.activation.vat')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} mb={3}>
            <Card>
              <CardContent>
                <Typography variant="h5" sx={{ marginBottom: 2 }}>
                  {translate('pay.activation.requirements')}
                </Typography>

                <Grid container alignItems="start" sx={{ marginBottom: 1 }}>
                  <Grid item xs={6} sx={{}}>
                    <Typography variant="h6">{translate('pay.activation.individual')}</Typography>
                    <p>{translate('pay.activation.ownerId')}</p>
                    <p>{translate('pay.activation.freelanceCert')}</p>
                    <p>{translate('pay.activation.faalCert')}</p>
                    <p>{translate('pay.activation.motIwaaCert')}</p>
                    <p>{translate('pay.activation.nationalAddress')}</p>
                    <p>{translate('pay.activation.bankCert')}</p>
                  </Grid>

                  <Grid item xs={6} sx={{}}>
                    <Typography variant="h6">{translate('pay.activation.institutionCompany')}</Typography>
                    <p>{translate('pay.activation.ownerId')}</p>
                    <p>{translate('pay.activation.OwnersIds')}</p>
                    <p>{translate('pay.activation.aoc')}</p>
                    <p>{translate('pay.activation.crCert')}</p>
                    <p>{translate('pay.activation.faalCert')}</p>
                    <p>{translate('pay.activation.motIwaaCert')}</p>
                    <p>{translate('pay.activation.nationalAddress')}</p>
                    <p>{translate('pay.activation.bankCert')}</p>
                  </Grid>
                </Grid>
                {/*  */}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={12} mb={3}>
            <Card>
              <CardContent>
                <Typography variant="h5" sx={{ marginBottom: 2 }}>
                  {translate('pay.activation.process')}
                </Typography>

                <Grid container alignItems="start" sx={{ marginBottom: 1 }}>
                  <Grid item xs={6} sx={{}}>
                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                      {translate('pay.activation.contactMessage')}
                    </Typography>
                    <p>pay@nuzul.app</p>
                  </Grid>
                </Grid>
                {/*  */}
              </CardContent>
            </Card>
          </Grid>

          {/* <Grid item xs={12} md={12}>
            <Card>
              <CardContent>
                <form onSubmit={formik.handleSubmit}>
                  <Typography variant="h4" paragraph>
                    {translate('pay.activation.upload Documents')}
                  </Typography>

                  <FormLabel sx={{ color: '#212B36' }}>Type</FormLabel>
                  <FormControl fullWidth sx={{ p: 1 }}>
                    <RadioGroup
                      name="business_type"
                      defaultValue="company"
                      row
                      sx={{ pl: 1 }}
                      value={formik.values.business_type}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        label={translate('Company')}
                        onChange={formik.handleChange}
                        control={<Radio value="company" />}
                      />
                      <FormControlLabel
                        label={translate('Institution')}
                        onChange={formik.handleChange}
                        control={<Radio value="institution" />}
                      />
                      <FormControlLabel
                        label={translate('Individual')}
                        onChange={formik.handleChange}
                        control={<Radio value="individual" />}
                      />
                    </RadioGroup>
                  </FormControl>

                  <Typography variant="h6">Upload Your Documents (only pdf format accepted)</Typography>

                  <FormControl fullWidth sx={{ p: 1 }}>
                    <FormLabel sx={{ color: '#212B36' }}>Owner id</FormLabel>
                    <input
                      id={'owner-id'}
                      name={'owner_id'}
                      type="file"
                      accept="application/pdf"
                      onChange={(event) => {
                        formik.setFieldValue('owner_id', event.currentTarget.files[0]);
                      }}
                    />
                  </FormControl>

                  {formik.values.business_type === 'company' && (
                    <FormControl fullWidth sx={{ p: 1 }}>
                      <FormLabel sx={{ color: '#212B36' }}>Bored members ids</FormLabel>
                      <input
                        id={'bored-members-ids'}
                        name={'bored_members_ids'}
                        type="file"
                        accept="application/pdf"
                        onChange={(event) => {
                          formik.setFieldValue('owner_id', event.currentTarget.files[0]);
                        }}
                      />
                    </FormControl>
                  )}

                  {formik.values.business_type === 'company' && (
                    <FormControl fullWidth sx={{ p: 1 }}>
                      <FormLabel sx={{ color: '#212B36' }}>Articles of Incorporation</FormLabel>
                      <input
                        id={'articles-of-incorporation'}
                        name={'articles_of_incorporation'}
                        type="file"
                        accept="application/pdf"
                        onChange={(event) => {
                          formik.setFieldValue('articles_of_incorporation', event.currentTarget.files[0]);
                        }}
                      />
                    </FormControl>
                  )}

                  <FormControl fullWidth sx={{ p: 1 }}>
                    <FormLabel sx={{ color: '#212B36' }}>Commercial Registration</FormLabel>
                    <input
                      id={'commercial-registration'}
                      name={'commercial_registration'}
                      type="file"
                      accept="application/pdf"
                      onChange={(event) => {
                        formik.setFieldValue('commercial_registration', event.currentTarget.files[0]);
                      }}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ p: 1 }}>
                    <FormLabel sx={{ color: '#212B36' }}>National Address</FormLabel>
                    <input
                      id={'national-address'}
                      name={'national_address'}
                      type="file"
                      accept="application/pdf"
                      onChange={(event) => {
                        formik.setFieldValue('national_address', event.currentTarget.files[0]);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ p: 1 }}>
                    <FormLabel sx={{ color: '#212B36' }}>Bank certificate</FormLabel>
                    <input
                      id={'national-address'}
                      name={'national_address'}
                      type="file"
                      accept="application/pdf"
                      onChange={(event) => {
                        formik.setFieldValue('national_address', event.currentTarget.files[0]);
                      }}
                    />
                  </FormControl>
                  <Grid container alignItems={'center'} justifyContent={'end'} mt={5}>
                    <Button
                      disabled={!formik.dirty}
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ color: 'white' }}
                    >
                      submit
                    </Button>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
