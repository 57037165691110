import { createAction } from '@reduxjs/toolkit';

export const createNoteRequest = createAction('CREATE_NOTE_REQUEST');
export const createNoteSuccess = createAction('CREATE_NOTE_SUCCESS');
export const createNoteFailure = createAction('CREATE_NOTE_FAILURE');

export const getNotesRequest = createAction('GET_NOTES_REQUEST');
export const getNotesSuccess = createAction('GET_NOTES_SUCCESS');
export const getNotesFailure = createAction('GET_NOTES_FAILURE');

export const editNoteRequest = createAction('EDIT_NOTE_REQUEST');
export const editNoteSuccess = createAction('EDIT_NOTE_SUCCESS');
export const editNoteFailure = createAction('EDIT_NOTE_FAILURE');

export const deleteNoteRequest = createAction('DELETE_NOTE_REQUEST');
export const deleteNoteSuccess = createAction('DELETE_NOTE_SUCCESS');
export const deleteNoteFailure = createAction('DELETE_NOTE_FAILURE');
