import { ENV, HOST_API, PORT_API } from '../../config';
import axios from '../../utils/axios';

const tenantUrl = localStorage.getItem('tenant_url');

class NotificationsServices {
  getUrl() {
    return HOST_API;
  }

  getTenantUrl() {
    const protocol = ENV === 'local' ? 'http' : 'https';
    const url = `${protocol}://${tenantUrl}${PORT_API}`;
    return url;
  }

  getNotifications = async ({ page = 1 }) => {
    try {
      return axios.get(`${this.getTenantUrl()}/api/notifications?page=${page}`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  markAsRead = async ({ id }) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/notifications/${id}/mark-as-read`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  markAsUnread = async ({ id }) => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/notifications/${id}/mark-as-unread`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  deleteNotification = async ({ id }) => {
    try {
      return axios.delete(`${this.getTenantUrl()}/api/notifications/${id}`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  markAllAsRead = async () => {
    try {
      return axios.put(`${this.getTenantUrl()}/api/notifications/mark-all-as-read`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  deleteAllNotifications = async () => {
    try {
      return axios.delete(`${this.getTenantUrl()}/api/notifications/`);
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };
}

export default new NotificationsServices();
