// @mui
import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// hooks
import { useNavigate } from 'react-router-dom';

// components
import Page from '../../components/Page';
import QuickSearchToolbar from '../../components/QuickSearchToolbar';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import { selectors as notificationsSelectors } from '../../models/notifications/reducers';
import { hasPropertyRequestNotification } from '../../models/notifications/utils';
import TRequests from '../../models/t-property-requests/services';

// ----------------------------------------------------------------------

export const requestsColumns = (translate, languageCode) => [
  {
    field: 'id',
    headerName: '#',
    maxWidth: 100,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'contact.name',
    headerName: translate('request.contactName'),
    maxWidth: 380,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => params.row.contact.name,
  },
  {
    field: 'purpose',
    headerName: translate('unit.purpose'),
    maxWidth: 180,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: ({ row }) => translate(`unit.${row.purpose}`),
  },
  {
    field: 'property_type',
    headerName: translate('unit.purpose'),
    maxWidth: 180,
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: ({ row }) => translate(`unit.${row.property_type}`),
  },
  {
    field: 'status',
    headerName: translate('request.status'),
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: ({ row }) => translate(`request.${row.status}`),
  },
  {
    field: 'city',
    headerName: translate('unit.city'),
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: ({ row }) => row.city?.[languageCode === 'ar' ? 'name_ar' : 'name_en'],
  },
  {
    field: 'district',
    headerName: translate('unit.district'),
    flex: true,
    headerClassName: 'datagrid-header',
    headerAlign: 'center',
    align: 'center',
    valueGetter: ({ row }) =>
      row.districts?.map((district) => district[languageCode === 'ar' ? 'name_ar' : 'name_en']).join(' - '),
  },
];

export default function RequestsView() {
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const navigate = useNavigate();
  const notifications = useSelector(notificationsSelectors.notifications);

  const [tServices, setTServices] = useState([]);

  const columns = requestsColumns(translate, languageCode);

  const handleRow = (params) => {
    window.open(`/maktb/crm/requests/${params.row.id}`, '_blank');
  };

  useEffect(() => {
    TRequests.getPropertyRequests().then((res) => {
      setTServices(res.data.data);
    });
  }, []);

  const isUnseen = (id) => hasPropertyRequestNotification(id, notifications || []);

  return (
    <Page title={translate('requests')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('requests')}
            </Typography>
          </Box>

          {/* <Button variant="contained" sx={{ color: 'white', pt: 1, pb: 1 }} onClick={() => navigate('/requests/add')}>
            {translate('request.addService')}
          </Button> */}
        </Box>

        <Card className="no-animation">
          <CardContent sx={{ p: 0 }}>
            <Box>
              <DataGrid
                onRowClick={handleRow}
                getRowClassName={(params) => `clickable ${isUnseen(params.row.id) ? 'unread' : ''}`}
                loading={false}
                columns={columns}
                rows={tServices || []}
                disableColumnFilter
                disableColumnSelector
                components={{
                  Toolbar: QuickSearchToolbar,
                }}
                hideFooterSelectedRowCount
                disableSelectionOnClick
                rowsPerPageOptions={[10]}
                pageSize={25}
                autoHeight
                sx={{
                  '& .datagrid-header': {
                    backgroundColor: '#F4F6F8',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderRadius: 0,
                  },
                  border: 'none',
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
